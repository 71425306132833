import React from 'react'
import 'fontsource-roboto'
import 'react-quill/dist/quill.snow.css'
import './css/App.css'
import firebase from './services/firebase'
import {BrowserRouter as Router} from 'react-router-dom'
import {AppStateProvider} from './services/AppStateContext'
import View from './components/View'
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#8B84D7'
    }
  },
  // this is to reduce size on mobile devices for TakeSurvey page assessment heading
  typography: {
    h4: {
      '@media (max-width:600px)': {
        fontSize: '1.5rem'
      }
    }
  }
})

console.log('the theme', theme)

firebase.initialize()

function App() {

  return (
    <Router>
      <AppStateProvider>
        <ThemeProvider theme={theme}>
          <div className="App" style={{display: 'flex'}}>
            <View />
          </div>
        </ThemeProvider>
      </AppStateProvider>
    </Router>
  )
}

export default App
