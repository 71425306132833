import 'date-fns'
import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button, Typography} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import SubscribersList from './SubscribersList'
import {useDropzone} from 'react-dropzone'
import importer from '../services/importer'
import phoneParser from '../services/phoneParser'
import Organization from '../services/organization'

export default function ImportSubscribers (props) {
  const [state, setState] = React.useState({subscribers: []})
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [warning, setWarning] = React.useState(null)

  React.useEffect(() => {
    // if (props.phone) {
    //   const {phone = '', email = ''} = props
    //   // console.log('InviteSubscriber.init', {phone})
    //   setState({phone, email})
    // }
  }, [])

  function isValidEmail (email) {
    // Email address matcher.
    var matcher = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    return { valid: matcher.test(email), email }
  }

  function filterDuplicates (sub) {
    const type = props.communicationMethod === 'sms' ? 'phone' : 'email'
    
    return !state.subscribers.find(s => s[type] === sub[type])
  }

  function validateSubscribers (sub) {
    const type = props.communicationMethod === 'sms' ? 'phone' : 'email'

    let res = false
    
    if (type === 'phone') {
      res = phoneParser.parse(sub.phone, props.countryCode)
    } else {
      res = isValidEmail(sub.email)
    }

    // console.log('validateSubscribers', type, res)
    
    const params = {valid: res.valid}

    if (type === 'phone') {
      params.phone = res.phone || sub.phone
      params.countryCode = res.countryCode || null
    } else {
      params.email = res.email
    }

    return params
  }

  const onImport = function (data) {
    // console.log('ImportSubscribers.onImport', data)
    setLoading(true)
    importer.users({type: 'csv', content: data.blob})
      .then(res => {
        let hasInvalidCount = 0
        let newSubscribers = res.data
          .filter(filterDuplicates)
          .map(sub => {
            const result = validateSubscribers(sub)

            if (!result.valid) {
              hasInvalidCount++;
            }

            return result
          })

        hasInvalidCount = newSubscribers.filter(sub => !sub.valid).length

        const commChannel = props.communicationMethod === 'sms' ? 'phone numbers' : 'email addresses'

        if (!newSubscribers.length) {
          return setError({message: `No subscribers found with ${commChannel}.`})
        }

        if (newSubscribers.length && newSubscribers.length === hasInvalidCount) {
          return setError({message: `All subscribers in the file have invalid ${commChannel}.`})
        }

        if (hasInvalidCount) {
          setWarning({message: `Some of the subscribers in this file have invalid ${commChannel}, only valid subscribers will be imported.`})
        }

        const subscribers = [].concat(state.subscribers, newSubscribers)
        // console.log('ImportSubscribers.onImport', newSubscribers)

        setLoading(false)
        setState({...state, subscribers})
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const onDrop = function (files) {
    // console.log('file dropped', files)
    
    if (files && files.length) {
      let file = files[0]

      const reader = new FileReader()

      reader.onload = function (e) {
        return onImport({
          name: file.name,
          blob: e.target.result,
          type: file.type,
          size: file.size
        })
      }

      reader.readAsBinaryString(files[0])
    }
  }

  const onRemove = function (i) {
    setError(null)
    let subscribers = [...state.subscribers]

    subscribers.splice(i, 1)

    setState({...state, subscribers})
  }

  const onAdd = function () {
    const {subscribers} = state

    props.onClose({success: true, subscribers: subscribers.filter(sub => sub.valid)})
  }
  
  const isInvalidForm = function () {
    return !(state.subscribers.length)
  }

  const onClose = function () {
    props.onClose({})
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Dialog open={props.open} aria-labelledby="Invite Subscriber(s)">
      <DialogTitle>Invite Subscriber(s)</DialogTitle>
      <DialogContent>

        {
          props.communicationMethod === 'sms' && (
            <DialogContentText>
                Drag and drop a csv file with the subscribers you wish to invite. Once submitted, we will send a invite SMS message to confirm.
            </DialogContentText>
          )
        }

        {
          props.communicationMethod === 'email' && (
            <DialogContentText>
                Drag and drop a csv file with the subscribers you wish to invite. Once submitted, the subscriber will automatically start receiving emails.
            </DialogContentText>
          )
        }

        {props.communicationMethod === 'sms' && <DialogContentText>The Phone numbers must be valid numbers in <strong>{Organization.supportedCountries[props.countryCode]}</strong>.</DialogContentText>}

        <br />
        <br />

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </div>

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }

        {
          warning && (
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              {warning.message}
            </Alert>
          )
        }

        <br />

        <Typography variant={'h5'}>Subscribers ({state.subscribers.length})</Typography>
        <SubscribersList subscribers={state.subscribers} onRemove={onRemove} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>Cancel</Button>
        <Button onClick={onAdd} color="primary" disabled={isInvalidForm() || loading}>Import</Button>
      </DialogActions>
    </Dialog>
  )
}