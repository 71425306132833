import React, {useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      width: '100%',
      padding: '1em'
    }
  },
}))

export default function ImageUploader (props) {
  const [renderFile, setRenderFile] = React.useState(null)

  const onDrop = function (files) {
    if (props.onSelect) {
      props.onSelect(files)
      setFileForRendering(files[0])
    }
  }

  const readFile = function (blob) {
    return new Promise((resolve) => {
      const reader = new FileReader()

      reader.onload = function (e) {
        resolve({src: e.target.result})
      }

      reader.readAsDataURL(blob)
    })
  }

  const setFileForRendering = function (data) {
    if (data) {
      if (data.src) {
        setRenderFile(data)
      } else {
        readFile(data).then(file => setRenderFile(file))
      }
    }
  }

  React.useEffect(() => {
    if (props.selected !== renderFile) {
      setFileForRendering(props.selected)
    }
  }, [props.selected])

  const classes = useStyles()
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept
  } = useDropzone({onDrop, accept: 'image/*'})

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ])

  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          {
            !renderFile && (
              <span>
                <CloudUploadIcon />
                {
                  isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag and drop an image here, or click to select an image</p>
                }
              </span>
            )
          }
          {
            renderFile && (
              <img alt="uploaded for message" style={{maxWidth: '400px', maxHeight: '400px'}} src={renderFile.src} />
            )
          }
        </div>
      </Paper>
    </div>
  )
}