import React from 'react'
import {useParams} from 'react-router-dom'
import Dashboard from './Dashboard'
import Organization from './Organization'
import Users from './Users'
import Participant from './Participant'
import AcceptInvite from './AcceptInvite'
import MakeSurvey from './Surveys/MakeSurvey'
import MakeProgram from './MakeProgram'
import EditProgram from './EditProgram'
import Surveys from './views/Surveys'
import Report from './Report'
import { AppBar, Button, Toolbar, Typography} from '@material-ui/core'
import { Container } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import auth from '../services/auth'
import {Switch, Route, Redirect} from 'react-router-dom'
import LeftNav from './LeftNav'
import useAppState from '../services/useAppState'
import AcceptParticipation from './AcceptParticipation'
import Unsubscribe from './Unsubscribe'
import Subscribe from './Subscribe'
import EditSurvey from './Surveys/EditSurvey'
import TakeSurvey from './views/TakeSurvey'
import SurveyReport from './Surveys/SurveyReport'
import AdhocMessages from './views/ProgramAdhocMessages'
import PublicProgramSubscribe from './views/PublicProgramSubscribe'
import EmailVerification from './views/EmailVerification'
import Login from './Authenticator/login'

const useStyles = makeStyles((theme) => ({
  view: {
    marginTop: '5rem',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  viewPublic: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0//theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  dialog: {
    minWidth: '350px'
  }
}))

function PrivateRoute({ children, ...rest }) {
  let auth = rest.auth

  return (
    <Route
      {...rest}
      render={({location}) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location}
            }}
          />
        )
      }
    />
  )
}

function Wrapper({ children, ...rest }) {
  const {organizationId} = useParams()
  const {organization, setOrganization} = useAppState()
  const [initLoading, setInitLoading] = React.useState(true)

  React.useEffect(() => {
    if (!organization && organizationId) {
      setOrganization(organizationId)
        .then(() => {
          // setLoggedIn(res)
          setInitLoading(false)
          // console.log('onLogin.setOrg.then', loggedIn)
          // console.log('AuthTokenId', auth.getIdToken())
        })
        .catch(err => {
          console.error('failed to set organization', organizationId)
          setInitLoading(false)
        })
    } else {
      setInitLoading(false)
    }
  }, [])

  // console.log('Wrapper', organizationId, useParams(), organization)

  if (initLoading) {
    return null
  }

  if (!organization) {
    return <Redirect
      to={{
        pathname: '/organizations'
      }}
    />
  }

  return (
    <div>{children}</div>
  )
}

export default function View () {
  const [initLoading, setInitLoading] = React.useState(true)
  const [loggedIn, setLoggedIn] = React.useState(false)
  const classes = useStyles()
  const {organization, setOrganization, unSetOrganization} = useAppState()
  const {organizationId} = useParams()

  // console.log('View.orgId', organizationId, useParams())
  
  const logout = function () {
    auth.logout()
    setLoggedIn(false)
    unSetOrganization()
  }

  const onAuthChange = function (res) {
    console.log('View.onLogin', res)
    if (res) {
      if (!auth.isRole('owner')) {
        setOrganization(auth.get('organizationId'))
          .then(() => {
            setLoggedIn(res)
            setInitLoading(false)
            // console.log('onLogin.setOrg.then', loggedIn)
            // console.log('AuthTokenId', auth.getIdToken())
          })
      } else {
        setLoggedIn(res)
        // console.log('onLogin.then', loggedIn)
        setInitLoading(false)
      }
    } else {
      setLoggedIn(res)
      setInitLoading(false)
    }
    // console.log('View.getTokenId', auth.getIdToken())
  }

  React.useEffect(() => {
    auth.initialize(isLoggedIn => onAuthChange(isLoggedIn))
  }, [])

  if (initLoading) {
    return null
  }

  if (!loggedIn) {
    return (
      <Container className={classes.viewPublic}>
        <Switch>
          <Route path="/emailVerification/:token">
            <EmailVerification/>
          </Route>
          <Route path="/programSubscribe/:id">
            <PublicProgramSubscribe />
          </Route>
          <Route path="/survey/:id">
            <TakeSurvey />
          </Route>
          <Route path="/participate/:id">
            <AcceptParticipation />
          </Route>
          <Route path="/subscribe/:id">
            <Subscribe />
          </Route>
          <Route path="/unsubscribe/:id">
            <Unsubscribe />
          </Route>
          <Route path="/invitation/:id">
            <AcceptInvite />
          </Route>
          <Route path="/login">
            <Login auth={loggedIn} onDone={onAuthChange} />
          </Route>
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      </Container>
    )
    // return <Login auth={loggedIn} />
  }

  return (
    <React.Fragment>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            SMS Project
          </Typography>
          {loggedIn && <Button onClick={logout} color="inherit">Logout</Button>}
        </Toolbar>
      </AppBar>

      <LeftNav />
      
      <Container className={classes.view}>
          {
            auth.isRole('owner') && (
              <Switch>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/adhocMessages/:organizationId/:id">
                  <Wrapper>
                    <AdhocMessages />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/surveys">
                  <Wrapper>
                    <Surveys />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/survey/:organizationId/new">
                  <Wrapper>
                    <MakeSurvey />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/survey/:organizationId/:id">
                  <Wrapper>
                    <EditSurvey />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/report/:organizationId/:id">
                  <Wrapper>
                    <Report />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/surveyReport/:organizationId/:id">
                  <Wrapper>
                    <SurveyReport />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/users/:organizationId">
                  <Wrapper>
                    <Users />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/participants/:organizationId">
                  <Wrapper>
                    <Participant />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/program/:organizationId/new/">
                  <Wrapper>
                    <MakeProgram />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/program/:organizationId/:id">
                  <Wrapper>
                    <EditProgram />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} path="/dashboard/:organizationId">
                  <Wrapper>
                    <Dashboard />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} path="/dashboard">
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} path="/organizations/">
                  <Organization />
                </PrivateRoute>
                <Route render={() => <Redirect to="/organizations" />} />
              </Switch>
            )
          }

          {
            !auth.isRole('owner') && (
              <Switch>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/adhocMessages/:id">
                  <Wrapper>
                    <AdhocMessages />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/surveys">
                  <Wrapper>
                    <Surveys />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/survey/new">
                  <Wrapper>
                    <MakeSurvey />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/survey/:id">
                  <Wrapper>
                    <EditSurvey />
                  </Wrapper>
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/users/">
                  <Users />
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/users/:id">
                  <Users />
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/participants/">
                  <Participant />
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/program/new">
                  <MakeProgram />
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/program/:id">
                  <EditProgram />
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/report/:id">
                  <Report />
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} condition={({isMet: Boolean(organization)})} path="/surveyReport/:id">
                  <SurveyReport />
                </PrivateRoute>
                <PrivateRoute auth={loggedIn} path="/dashboard">
                  <Dashboard />
                </PrivateRoute>
                <Route render={() => <Redirect to="/dashboard" />} />
              </Switch>
            )
          }
      </Container>
    </React.Fragment>    
  )
}