import firebase from './firebase'
import axios from 'axios'
import Auth from './auth'

const API_URL = process.env.REACT_APP_API
let selected = null
let listeners = {}
let self = {}

function updateSelected (snap) {
  selected = snap.val()
}

// list of all Twilio supported countries
// https://help.twilio.com/articles/223133767-International-support-for-Alphanumeric-Sender-ID
self.supportedCountries = {
  "AF": "Afghanistan",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BW": "Botswana",
  "BR": "Brazil",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CO": "Colombia",
  "KM": "Comoros",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "HR": "Croatia",
  "CU": "Cuba",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MK": "Macedonia",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "AN": "Netherlands Antilles",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RO": "Romania",
  "RW": "Rwanda",
  "WS": "Samoa",
  "SM": "San Marino",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TG": "Togo",
  "TO": "Tonga",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
}

self.create = function (params) {
  const {name, smsSupport: sms, emailSupport: email, countryCode} = params
  const org = firebase.database().ref(`/organizations/`).push()

  return org.set({name, id: org.key, countryCode, communication_methods: {sms, email}})
    .then(() => {
      return {name, id: org.key}
    })
}

self.finalize = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        const {id} = params

        axios.post(`${API_URL}/initializeOrganization`, {id}, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.enable = function (id) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/updateOrganization`, {id, disable: false}, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.disable = function (id) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/updateOrganization`, {id, disable: true}, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.query = function (params) {
  const {id, name} = params
  let orgs = firebase.database().ref(`/organizations/${id ? id : ''}`)

  if (name) {
    orgs = orgs.orderByChild('name').startAt(name).endAt(`${name}\uf8ff`)
  }

  return orgs.once('value')
    .then(snap => {
      let result = id ? snap.val() : Object.values(snap.val() || {})

      const now = (new Date())
      const month = now.getUTCMonth()
      const year = now.getUTCFullYear()

      return Promise.all(
        (id ? [result] : result)
          .map(org => {
            return firebase.database().ref(`/stats/${org.id}/${year}_${month}/totals`).once('value')
              .then(s => {
                const stats = s.val()
                return {...org, stats: stats || {sent: 0, success: 0, fail: 0}}
              })
          })
      )
      .then(results => {
        if (id) {
          return results[0]
        }
        return results
      })


    })
}

self.set = function (id) {
  return self.query({id})
    .then(orgs => {
      let org = orgs

      if (!org.communication_methods) {
        org.communication_methods = {sms: true}
      }

      org.communication_methods = Object.keys(org.communication_methods).filter(method => org.communication_methods[method])

      selected = org
      listeners.update = firebase.database().ref(`/organizations/${id}`)
      listeners.update.on('child_changed', updateSelected)
      return selected
    })
}

self.delete = function (id) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/deleteOrganization`, {id}, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.get = function (key) {
  return key ? selected && selected[key] : selected
}

self.unset = function () {
  return new Promise((resolve, reject) => {
    try {
      if (!selected && !Object.keys(listeners).length) {return}

      listeners.update.off('child_changed', updateSelected)
      delete listeners.update
      selected = null

      resolve()
    } catch (e) {
      reject(e)
    }
  })
}

export default self