import firebase from '../../services/firebase'
import auth from '../../services/auth'
import { getMultiFactorResolver, getAuth } from 'firebase/auth'
import { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import {makeStyles} from '@material-ui/core/styles'

import EmailVerifier from './verifyEmail'
import MultiFactorAuthentication from './multifactor'
import ResetPassword from './resetPassword'
import logo from '../../logo.png'

const useStyles = makeStyles((theme) => ({
  view: {
    marginTop: '5rem',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  viewPublic: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0//theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  dialog: {
    minWidth: '350px'
  }
}))

const Login = ({onDone}) => {
  const classes = useStyles()
  const fbauth = getAuth(firebase.getInstance())

  const steps = ['login', 'verifyEmail', 'mfa']
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [step, setStep] = useState(0)
  const [mfaResolver, setMfaResolver] = useState(null)
  const [error, setError] = useState(null)
  const [view, setView] = useState('login')
  const [user, setUser] = useState(null)
  const [isEnrolled, setIsEnrolled] = useState(false)

  const login = () => {
    return auth.login(email, password)
      .then((confirmationResult) => {
        // console.log('signInWithEmailAndPassword.success', confirmationResult, fbauth.currentUser)

        // next step
        setStep(step + 1)

      })
      .catch(err => {
        // console.log('signInWithEmailAndPassword.fail', err, err.code)

        // expected for MFA
        if (err.code === 'auth/multi-factor-auth-required') {
          // console.log('signInWithEmailAndPassword.err.mfa', fbauth.currentUser)
          setIsEnrolled(true)
          setMfaResolver(getMultiFactorResolver(fbauth, err))
          setStep(step + 2)
        }
      })
  }

  const onEmailVerificationDone = (isVerified) => {
    // console.log('onEmailVerificationDone', isVerified)
    if (isVerified) {
      // move to next step
      setStep(step + 1)
    } else {
      // logout as user should get email or they don't want to proceed
      // console.log('onEmailVerificationDone.isNotVerified')
      auth.logout().then(() => {
        // console.log('onEmailVerificationDone.logout.then')
        
        setStep(1)
        // reload window - easier to clear state
        window.location.reload(false);
      })
    }
  }
  
  const onMultiFactorDone = async (isVerified) => {
    // either enrolled, or completed verification
    if (isVerified) {
      await auth.loadSession(fbauth.currentUser)
      if (onDone) {
        onDone(isVerified)
      }
    } else {
      auth.logout().then(() => {        
        // reload window - easier to clear state
        window.location.reload(false);
      })
    }
  }

  return (
    <div>
      <div id="recaptcha-verifier"></div>
      {
        steps[step] === 'login' ? (
          <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication">
            <DialogTitle>Login</DialogTitle>
            <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} />
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
                required
              />

              <TextField
                autoFocus
                margin="dense"
                label="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                fullWidth
                required
              />

              {
                error && (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {error.message}
                  </Alert>
                )
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setView('reset')} color="secondary">Forgot My Password</Button>
              <Button id="login-button" onClick={login} color="primary" disabled={!password || !email}>Login</Button>
            </DialogActions>
          </Dialog>
        ) : null
      }

      {
        steps[step] === 'verifyEmail' ? (
          <EmailVerifier user={fbauth.currentUser} onDone={onEmailVerificationDone} />
        ) : null
      }

      {
        steps[step] === 'mfa' ? (
          <div>
            <MultiFactorAuthentication auth={fbauth} onDone={onMultiFactorDone} resolver={mfaResolver} isEnrolled={isEnrolled} />
          </div> 
        ) : null
      }

      {
        view === 'reset' ? (
          <div>
            <ResetPassword />
          </div>
        ) : null
      }
    </div>
  )

}

export default Login