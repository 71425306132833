import React, { useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import AdhocMessenger from '../../services/adhocMessenger'
import {Alert, AlertTitle} from '@material-ui/lab'
import SendIcon from '@material-ui/icons/Send'
import { makeStyles } from '@material-ui/core/styles';
import RichText from '../RichText'
import RecipientSelector from './RecipientSelector'
import RecipientStatusList from './RecipientsStatusList';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const CountDown = function (props) {
  const [count, setCount] = React.useState(5)

  useEffect(() => {
    setTimeout(function () {
      if (count > 0) {
        let _count = count - 1
        setCount(_count)
      }
    }, 1000)
  }, [count])

  return (<span>{count || 'Sending'}</span>)
}

const SendButton = function (props) {
  const [clicked, setClicked] = React.useState(false)
  const [timer, setTimer] = React.useState(null)
  const classes = useStyles();

  const delayClick = function () {
    if (props.formValidator) {
      const isValid = props.formValidator()

      if (!isValid) {
        return
      }
    }

    if (clicked) {
      clearTimeout(timer)
      setTimer(null)
      setClicked(false)
      return
    }
    setClicked(true)
    setTimer(setTimeout(() => {
      props.onClick()
      setClicked(false)
    }, 5000))
  }

  const onClose = function () {
    clearTimeout(timer)
    setTimer(null)
    setClicked(false)
    props.onClose()
  }

  return (
    <React.Fragment>
      <Button onClick={onClose} color="secondary" disabled={props.sending}>Cancel</Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={clicked ? null : <SendIcon />}
        disabled={props.sending || false}
        onClick={delayClick}
      >
        {clicked ? <CountDown /> : 'Send'}
      </Button>
    </React.Fragment>
  )
}

const constructDate = (_date = new Date()) => {
  //2022-11-16T23:59:00.000
  let year = _date.getFullYear()
  let month = _date.getMonth() + 1
  let day = _date.getDate()
  let hr = _date.getHours()
  let mm = _date.getMinutes()

  if (hr < 10) {
    hr = `0${hr}`
  }

  if (mm < 10) {
    mm = `0${mm}`
  }

  if (day < 10) {
    day = `0${day}`
  }

  return `${year}-${month}-${day}T${hr}:${mm}`
}

export default function SendMessageModal (props) {
  const [state, setState] = React.useState({
    id: null,
    name: '',
    subject: '',
    message: '',
    publishDate: null,
    recipients: [],
    recipientSelection: '*',
    readablePublishDate: null
  })
  const [form, setForm] = React.useState(
    props.communicationMethod === 'email' ? {
      name: {dirty: false, valid: false},
      message: {dirty: false, valid: false},
      recipients: {dirty: false, valid: true},
      subject: {dirty: false, valid: false}
    } : {
      name: {dirty: false, valid: false},
      message: {dirty: false, valid: false},
      recipients: {dirty: false, valid: true},
    }
  )
  const [uiPublishDate, setUiPublishDate] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [saving, setSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (props.message) {
      setState({
        id: props.message.id,
        name: props.message.name,
        status: props.message.status,
        subject: props.message.subject,
        message: props.message.message,
        recipientsStatus: props.message.recipientsStatus,
        publishDate: props.message.publishDate,
        readablePublishDate: props.message.publishDate ? new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'long'}).format(new Date(props.message.publishDate)) : null
      })
      
      if (props.message.publishDate) {
        setUiPublishDate(constructDate((new Date(props.message.publishDate))))
      }
    }
    setLoading(false)
  }, [props.programId, props.organizationId, props.message, props.communicationMethod])

  const onFormChange = function (e) {
    const {target: {name, value}} = e

    setState({...state, [name]: value})

    setFieldValidate(name, value)
    setDirtyState(name)
  }

  const setFieldValidate = function (field, value, recipientsValue) {
    let newForm = {...form}
    let valid = false

    switch(field) {
      case 'name': {
        valid = typeof value === 'string' && value.length > 2
        break
      }
      case 'message': {
        valid = typeof value === 'string' && value.length > 10
        break
      }
      case 'recipients': {
        valid = ['*', 'active', 'completed'].includes(value) || (Array.isArray(recipientsValue) && recipientsValue.length > 0)
        break
      }
      case 'subject': {
        valid = typeof value === 'string' && value.length > 5
        break
      }
      default: {
        valid = false
      }
    }

    newForm[field].valid = valid

    setForm(newForm)
  }

  const setDirtyState = function (field) {
    let newForm = {...form}

    newForm[field].dirty = true
    setForm(newForm)
  }

  const isFormValid = function () {
    return Object.keys(form).filter(field => !form[field].valid).length === 0
  }

  const formValidator = function () {
    Object.keys(form).forEach(field => setDirtyState(field))

    return isFormValid()
  }

  const onConfirm = function () {
    const {recipientSelection, recipients, id, ...rest} = state
    setError(null)
    setSaving(true)

    let params = {...rest, organizationId: props.organizationId, programId: props.programId, communication_method: props.communicationMethod}

    if (recipientSelection !== 'custom') {
      params.recipients = [recipientSelection]
    } else {
      params.recipients = recipients.map(sub => sub.id)
    }

    AdhocMessenger
      .send(params)
      .then(() => {
        setSaving(false)
        props.onClose({success: true, name: state.name})
      })
      .catch(err => {
        setSaving(false)
        setError({message: 'Failed to send adhoc message, please try again. If the issue persists, please contact an administrator.'})
        console.log('SendAdhocMessage.onConfirm.failed', err)
      })
  }

  const onClose = () => {
    props.onClose({})
  }

  const onSelectRecipients = (newSelection) => {
    setState({...state, recipients: newSelection})
    setFieldValidate('recipients', 'custom', newSelection)
    setDirtyState('recipients')
  }

  const onChangeScheduleOption = (e) => {
    const opt = e.target.value

    if (opt === 'future') {
      let date = new Date()
      let publishDate = date.getTime()
      let readablePublishDate = new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'long'}).format(new Date(publishDate))

      setState({...state, publishDate, readablePublishDate})
      setUiPublishDate(constructDate(date))
    } else {
      setState({...state, publishDate: null, readablePublishDate: null})
      setUiPublishDate(null)
    }
  }

  const onPublishDateChange = (e, b, c) => {
    const str = e.target.value
    const date = new Date(str)
    const publishDate = date.getTime()
    const readablePublishDate = new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'long'}).format(new Date(publishDate))

    setState({...state, publishDate, readablePublishDate})
    setUiPublishDate(constructDate(date))
  }

  const onRecipientTypeSelection = (e) => {
    const {target: {name, value}} = e
    
    let recipients = state.recipients

    if (value !== 'custom') {
      recipients = []
    }

    setFieldValidate('recipients', value)
    setDirtyState('recipients')

    setState({...state, recipients, [name]: value})
  }

  const onRichTextChange = (newBody, rawText) => {
    setState({...state, message: newBody})

    setFieldValidate('message', rawText)
    setDirtyState('message')
  }

  if (loading) {
    return null
  }

  return (
    <Dialog open={props.open} maxWidth="xl" aria-labelledby="Send adhoc message">
      <DialogTitle>
        {
          state.id ? 'Message Details': 'Create your message'
        }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* TODO: make this messaging nicer - will be sent, should have been sent, was sent, etc. */}
          {
            state.id ? (
              <Alert severity={state.status === 'queue' ? "info" : 'success'}>
                {
                  `Message ${state.status === 'queue' ? 'will be sent' : 'was sent'} to subscribers of ${props.programName} on ${state.readablePublishDate}`
                }
              </Alert>
            ) : null
          }

          {
            !state.id ? (
              `Enter the details below to create and send the Message to all active subscribers of ${props.programName}.`
            ) : null
          }
        </DialogContentText>

        <form noValidate autoComplete="off">
          <TextField
            autoFocus
            name="name"
            margin="dense"
            label="Name"
            type="textarea"
            value={state.name}
            onChange={onFormChange}
            error={!form.name.valid && form.name.dirty}
            helperText={!form.name.valid && form.name.dirty ? <FormHelperText error={!form.name.valid}>is a required field, please enter at least 2 characters for name.</FormHelperText> : "Enter a unique name for the message"}
            fullWidth
            required
            disabled={!!(state.id || saving)}
          />

          {
            !state.id ? (
              <FormControl component="fieldset">
                <FormLabel component="legend">Schedule Message</FormLabel>
                <RadioGroup defaultValue="now" aria-label="Schedule message" name="schedule" onChange={onChangeScheduleOption}>
                  <FormControlLabel value="now" control={<Radio />} label="Now" disabled={!!(state.id || saving)} />
                  <FormControlLabel value="future" control={<Radio />} label="Future" disabled={!!(state.id || saving)} />
                </RadioGroup>
              </FormControl>
            ) : null
          }

          {
            state.publishDate ? (
              <FormControl fullWidth style={{marginTop: 15, marginBottom: 15}}>
                <TextField
                  id="datetime-local"
                  label="Publish Date/Time"
                  type="datetime-local"
                  defaultValue={uiPublishDate}
                  onChange={onPublishDateChange}
                  disabled={!!(state.id || saving)}
                  // className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            ) : null
          }

          {
            !state.id ?
            (
              <FormControl fullWidth>
                <InputLabel id="recipientSelection">Recipients</InputLabel>
                <Select
                  labelId="recipientSelection"
                  name="recipientSelection"
                  value={state.recipientSelection}
                  disabled={saving}
                  onChange={onRecipientTypeSelection}
                >
                  <MenuItem value="*"> All Subscribers </MenuItem>
                  <MenuItem value={'active'}>Active Subscribers</MenuItem>
                  <MenuItem value={'completed'}>Subscribers who completed the program</MenuItem>
                  <MenuItem value={'custom'}>Custom</MenuItem>
                </Select>
              </FormControl>
            ) : null
          }

          {
            state.recipientSelection === 'custom' && !state.id ? (
              <div>
                <RecipientSelector onSelect={onSelectRecipients} selected={state.recipients} programId={props.programId} searchKey={props.communicationMethod === 'sms' ? 'phone' : 'email'} />
              </div>
            ) : null
          }

          {
            state.id ?
            (
              <div style={{marginTop: 15, marginBottom: 15}}>
                <Typography>Recipients</Typography>
                <RecipientStatusList messageStatus={state.status} recipients={state.recipientsStatus} />
              </div>
            ) : null
          }

          {
            props.communicationMethod === 'email' ? (
              <div>
                <TextField
                  autoFocus
                  name="subject"
                  margin="dense"
                  label="Subject"
                  type="textarea"
                  value={state.subject}
                  required
                  onChange={onFormChange}
                  helperText={!form.subject.valid && form.subject.dirty ? <FormHelperText error={!form.subject.valid}>is a required field and must be 5 characters or more.</FormHelperText> : "Enter the subject of the message"}
                  fullWidth
                  disabled={!!(state.id || saving)}
                />

                <br />
                <br />
                
                <Typography>Body</Typography>

                <RichText body={state.message} disabled={!!state.id} onChange={onRichTextChange} />

                {
                  !form.message.valid && form.message.dirty ? (
                    <FormHelperText error={!form.message.valid}>is a required field, and must be atleast 10 characters.</FormHelperText>
                  ) : (
                    <FormHelperText>Enter the body of the message</FormHelperText>
                  )
                }
              </div>
            ) : null
          }

          {
            props.communicationMethod !== 'email' && (
              <TextField
                autoFocus
                name="message"
                margin="dense"
                label="Message"
                type="textarea"
                value={state.message}
                required
                multiline
                onChange={onFormChange}
                error={!form.message.valid && form.message.dirty}
                helperText={!form.message.valid && form.message.dirty ? <FormHelperText error={!form.message.valid}>is a required field, and must be atleast 10 characters.</FormHelperText> : "Enter the body of the message"}
                fullWidth
                disabled={!!(state.id || saving)}
              />
            )
          }

          {
            error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error.message}
              </Alert>
            )
          }
        </form>
      </DialogContent>
      <DialogActions>
        {state.id ? <Button onClick={onClose} color="secondary">Close</Button> : null}
        {state.id ? null : <SendButton sending={saving} onClick={onConfirm} formValidator={formValidator} onClose={onClose} />}
      </DialogActions>
    </Dialog>
  )
}