import { useState, Fragment } from 'react'
import { InputBase, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button, TextField, Typography, Switch, FormControl, FormControlLabel, FormHelperText } from "@material-ui/core";
import Org from '../services/organization'
import {Alert, AlertTitle} from '@material-ui/lab'
import CountryCodePicker from './CountryCodePicker';

export default function MakeOrganization (props) {
  const [name, setName] = useState('')
  const [smsSupport, setSmsSupport] = useState(false)
  const [emailSupport, setEmailSupport] = useState(false)
  const [countryCode, setCountryCode] = useState({ "country": "Canada", "code": "CA", "dialingCode": "+1" })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onCreate = function () {
    setLoading(true)
    return Org.create({name, smsSupport, emailSupport, countryCode: countryCode.code})
      .then((org) => {
        return Org.finalize(org)
      })
      .then(() => {
        setLoading(false)
        props.onClose({success: true, name})
      })
      .catch((e) => {
        console.error('MakeOrganization.onCreate.failed', e)
        setLoading(false)
        setError({message: 'Failed to create organization, please try again. If the issue persists, please contact an administrator.', details: e})
      })
  }

  const onClose = function () {
    props.onClose({})
  }

  const handleChange = function (e) {
    switch (e.target.name) {
      case 'smsSupport':
        setSmsSupport(e.target.checked)
        break
      case 'emailSupport':
        setEmailSupport(e.target.checked)
        break
      default:
        return null
    }

    if (!e.target.checked && e.target.name === 'smsSupport') {
      setCountryCode({ "country": "Canada", "code": "CA", "dialingCode": "+1" })
    }
  }

  const onChangeCountryCode = (cc) => {
    setCountryCode(cc)
  }

  return (
    <Dialog open={props.open} aria-labelledby="Create Organization">
      <DialogTitle>Create Organization</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the details below to create an Organization. Once an origanization is created, you will be able to assign users to it.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Organization Name"
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
          helperText="Name must be at least 2 characters long"
          fullWidth
        />

        <br />
        <br />

        <Typography variant={'h5'}>
          Programs
        </Typography>
        <br />
        <DialogContentText>
          Select the program types this organization will need. By selecting a text program a phone number will be assigned for broadcasting messages.
        </DialogContentText>

        <FormControlLabel
          style={{width: '-webkit-fill-available', justifyContent: 'space-between', margin: '0'}}
          fullWidth="true"
          control={<Switch
            checked={smsSupport}
            onChange={handleChange}
            name="smsSupport"
            inputProps={{ 'aria-label': 'Text program checkbox' }}
          />}
          label="Text Program"
          labelPlacement="start"
        />

        <FormControlLabel
          style={{width: '-webkit-fill-available', justifyContent: 'space-between', margin: '0'}}
          fullWidth="true"
          control={<Switch
            checked={emailSupport}
            onChange={handleChange}
            name="emailSupport"
            inputProps={{ 'aria-label': 'Email program checkbox' }}
          />}
          label="Email Program"
          labelPlacement="start"
        />

        <FormHelperText>You must enable at least one Program type</FormHelperText>

        <br />

        {
          smsSupport && (
            <CountryCodePicker
              inputLabel="Country Selection"
              helperText="Select the country this organization will be broadcasting messages to"
              onChange={onChangeCountryCode}
              selected={countryCode}
            />
          )
        }

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>Cancel</Button>
        <Button onClick={onCreate} color="primary" disabled={(name.length < 2 || (!smsSupport && !emailSupport)) || loading}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}