import React from 'react'
import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmDeleteProgram (props) {
  const [open] = React.useState(true);

  const decline = () => {
    props.onClose(false)
  }

  const agree = () => {
    props.onClose(true)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={decline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            !props.disabled && (`Are you sure you want to delete this program?`)
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>Deleting this program is irreversible.</strong>
          </DialogContentText>
          <DialogContentText>
            No new subscribers can join, existing subscribers will stop receiving their messages, and you cannot invite new subscribers. The keyword will be removed from this program so it is available to assign to a new program.
          </DialogContentText>
        </DialogContent>
          {
            !props.disabled && (
              <DialogActions>
                <Button onClick={decline} color="primary">
                  Cancel
                </Button>
                <Button onClick={agree} color="primary" autoFocus>
                  Confirm Delete
                </Button>
              </DialogActions>
            )
          }
      </Dialog>
    </div>
  );
}