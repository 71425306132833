import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import ProgramForm from './ProgramForm'
import {useParams, useHistory} from 'react-router-dom'
import Program from '../services/program'
import Notify from './Notify'
import Auth from '../services/auth'
import useAppState from '../services/useAppState'

export default function EditProgram () {
  const {id} = useParams()
  const [notify, setNotify] = React.useState({show: false})
  const [program, setProgram] = React.useState(null)
  const [messages, setMessages] = React.useState([])
  const [subscribers, setSubscribers] = React.useState([])
  const [replies, setReplies] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const history = useHistory()
  const {organization} = useAppState()

  const onUpdate = function (res) {
    // TODO: fix this to show proper error message if any of the reqs fail
    // console.log('onUpdate', res)
    setNotify({show: true, variant: 'success', message: `Successfully updated Program.`})
  }

  const onClose = function () {
    const append = Auth.isRole('owner') ?  organization.id : ''
    history.push(`/dashboard/${append}`)
  }

  const onNotifyClose = function () {
    setNotify({show: false})
  }

  React.useEffect(() => {
    let filters = {
      message: !Auth.hasFeature('message'),
      replies: !Auth.hasFeature('replies'),
      subscriber: !Auth.hasFeature('subscriber')
    }
    Program.set(id, filters)
      .then(data => {
        // console.log('EditPrograms.init', data)
        setProgram(data.program)
        setMessages(data.messages)
        setSubscribers(data.subscribers)
        setReplies(data.replies || [])
        setLoading(false)
        // setPrograms(programs)
        // setCount(programs.length) // temp
      })
  }, [id])

  if (loading) {
    return null
  }

  if (!organization) {
    return null
  }

  const countryCode = organization.countryCode || 'CA'

  return (
    <div>
      <Grid container spacing={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>{program.name} Program</Typography>
            <ProgramForm organizationId={organization.id} communicationMethods={organization.communication_methods} countryCode={countryCode} onUpdate={onUpdate} program={program} messages={messages} replies={replies} contacts={subscribers} onClose={onClose} />
          </Grid>
        </Grid>
      </Grid>
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
    </div>
  )
}