import React from 'react'
import { Typography, Paper, TableContainer, makeStyles, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Button } from "@material-ui/core";
import useAppState from '../services/useAppState'
import {useParams} from 'react-router-dom'
import Program from '../services/program'
import {DatePicker} from '@material-ui/pickers'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  optionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem'
  }
});

const SimpleCard = function (props) {
  const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.header}
        </Typography>
        <Typography variant="h5" component="h2">
          {/* be{bull}nev{bull}o{bull}lent */}
          {props.body}
        </Typography>
        <Typography variant="body2" component="p" color="textSecondary">
          {props.footer}
        </Typography>
        {/* <Typography variant="body2" component="p">
          {props.footer}
        </Typography> */}
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}

const SubscriberStatsTable = function (props) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Table of Daily Subscriber Stats">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Subscribed</TableCell>
            <TableCell>Unsubscribed</TableCell>
            <TableCell>Completed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (Object.keys(props.stats || {}))
              .filter(k => k !== 'totals')
              .map((k, i) => {
                const date = props.date
                date.setDate(k)
                const row = props.stats[k]

                return (
                  <TableRow key={`${k}_${i}`}>
                    <TableCell>{date.toLocaleDateString('en-US', {month: 'long', year: 'numeric', day: 'numeric'})}</TableCell>
                    <TableCell>{row.subscribed || 0}</TableCell>
                    <TableCell>{((row.unsubscribed || 0) - (row.completed || 0) || 0)}</TableCell>
                    <TableCell>{row.completed || 0}</TableCell>
                  </TableRow>
                )
              })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const prettifyHeadings = function (h) {
  switch (h) {
    case 'date':
      return 'Date'
    case 'subscribed':
      return 'New Subscribers'
    case 'unsubscribed':
      return 'Unsubscribers'
    case 'completed':
      return 'Completed Program'
    default:
      return h
  }
}

const generateReportFile = function (data) {
  const keys = ['date', 'subscribed', 'unsubscribed', 'completed']
  const headings = keys.map(prettifyHeadings).join(', ')

  let csv = Object.keys(data)
    .map(dateStr => {
      let {completed = 0, subscribed = 0, unsubscribed = 0} = data[dateStr].totals
      let year = dateStr.split('_')[0]
      let month = parseInt(dateStr.split('_')[1]) + 1 // because Javascript month starts at 0 - 11

      if (month < 10) {
        month = '0' + month
      }

      // exclude completed from unsubscriber count
      // when subscriber completes program, they are counter increases for unsubscriber and completed
      unsubscribed = (unsubscribed || 0) - (completed || 0)

      return `${month}-${year}, ${subscribed}, ${unsubscribed}, ${completed}`.replace(/(\r\n|\n|\r)/gm, '')
    })

    csv.unshift(headings)
    csv = csv.join('\r\n')

    return csv
}

function generateOctetStream (data) {
  return `data:application/octet-stream,${encodeURIComponent(data)}`
}

function triggerDownload (filename, url, newWindow) {
  return new Promise((resolve) => {
    const anchor = document.createElement('a');

    anchor.setAttribute('href', url);
    anchor.setAttribute('download', filename);
    anchor.style.display = 'none';
    anchor.textContent = filename;

    if (newWindow) {
      anchor.setAttribute('target', '_blank');
    }

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    resolve();

  });
}

export default function Report () {
  const classes = useStyles();
  const {organization} = useAppState()
  const {id} = useParams()
  const [program, setProgram] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [allTimeStatsLoading, setAllTimeStatsLoading] = React.useState(false)

  const formatStats = (statsData) => {
    let stats = statsData || {}

    if (!stats.totals) {
      stats.totals = {}
    }

    if (!stats.totals.subscribed) {
      stats.totals.subscribed = 0
    }

    if (!stats.totals.unsubscribed) {
      stats.totals.unsubscribed = 0
    }

    if (!stats.totals.completed) {
      stats.totals.completed = 0
    }

    stats.totals.unsubscribed = (stats.totals.unsubscribed - stats.totals.completed) || 0

    return stats
  }
  
  React.useEffect(() => {
    // console.log('Reports.init', organization)
    Program.get({id})
      .then(data => {
        Program.stats(organization.id, id, selectedDate.getFullYear(), selectedDate.getMonth())
          .then(stats => {
            data.stats = formatStats(stats)

            setProgram(data)
            setLoading(false)
          })
      })
  }, [id, organization.id, selectedDate])

  const onDateChange = (d) => {
    // console.log('onDateChange', d)
    setSelectedDate(d)
    setLoading(true)
    
    Program.stats(organization.id, id, d.getFullYear(), d.getMonth())
      .then(stats => {
        let data = program
        data.stats = formatStats(stats)

        setProgram(data)
        setLoading(false)
      })
  }

  const downloadAllTimeReport = () => {
    setAllTimeStatsLoading(true)
    Program.allTimeStats(organization.id, id)
      .then(data => {
        const report = generateReportFile(data)

        triggerDownload(`${program.name}_report.csv`, generateOctetStream(report))

        // console.log('allTimeStats.report', report)
        setAllTimeStatsLoading(false)
      })
      .catch(err => {
        console.error('downloadAllTimeReport.failed', err)
        setAllTimeStatsLoading(false)
      })
  }

  if (!organization) {
    return null
  }

  // if (!program) {
  //   return null
  // }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {program.name} Report
      </Typography>

      <div className={classes.optionsWrapper}>
        <Typography component="h6" variant="h6" gutterBottom>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              openTo="year"
              views={['year', 'month']}
              label="Year and Month"
              helperText="Start from year selection"
              value={selectedDate}
              onChange={onDateChange}
            />
          </MuiPickersUtilsProvider>
        </Typography>

        <Button onClick={downloadAllTimeReport} color="primary" disabled={allTimeStatsLoading}>
          Download All Time Report
        </Button>
      </div>
      
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <SimpleCard
          header={'Subscribers'}
          body={
            loading ? <CircularProgress /> :
            (program.stats && program.stats.totals && program.stats.totals.subscribed) || 0
          }
          footer={'Total number of subscribers to this program'}
        />

        <SimpleCard
          header={'Completions'}
          body={
            loading ? <CircularProgress /> :
            (program.stats && program.stats.totals && program.stats.totals.completed) || 0
          }
          footer={'Total number of subscribers who completed this program'}
        />

        <SimpleCard
          header={'Unsubscribed'}
          body={
            loading ? <CircularProgress /> :
            (program.stats && program.stats.totals && program.stats.totals.unsubscribed) || 0
          }
          footer={'Total number of subscribers who have unsubscribed'}
        />
      </div>

      <br />

      {loading ? null : <SubscriberStatsTable date={selectedDate} stats={program.stats} />}

    </React.Fragment>
  )
}