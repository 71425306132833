import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Fab, IconButton, Typography } from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles'
import AdhocMessenger from '../../services/adhocMessenger'
import Program from '../../services/program'
import MakeIcon from '@material-ui/icons/Add'
import useAppState from '../../services/useAppState'
// import {Link} from 'react-router-dom'
import ScheduleIcon from '@material-ui/icons/Schedule'
import PreviewIcon from '@material-ui/icons/More'
import DoneIcon from '@material-ui/icons/CheckCircle'
import SendMessageModal from './SendMessageModal'
import Notify from '../Notify'

const useStyles = makeStyles((theme) => (
  {
    statusActive: {
      color: '#4caf50'
    },
    statusInactive: {
      color: '#616161'
    },
    statusDisabled: {
      color: '#d32f2f'
    },
    newProgramFab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    }
  }
))

export default function MessagesTable (props) {
  const classes = useStyles()
  const [messages, setMessages] = React.useState([])
  const [programs, setPrograms] = React.useState({})
  const [showSendMessageModal, setShowSendMessageModal] = React.useState(false)
  const [showViewMessageModal, setShowViewMessageModal] = React.useState(false)
  const {organization} = useAppState()
  const [loading, setLoading] = React.useState(true)
  const [notify, setNotify] = React.useState({show: false})
  const [selected, setSelected] = React.useState(null) // Selection of message from table for viewing details

  const headers = [
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'Status',
      key: 'status'
    },
    {
      label: 'Publish Date',
      key: 'publishDate'
    }
  ]

  const loadMessages = () => {
    // need to query the program to get the name
    Promise.all([AdhocMessenger.get(props.programId), Program.get({id: props.programId})])
      .then(results => {
        const _messages = results[0]
        const _program = results[1]

        setPrograms({[props.programId]: _program})
        setMessages(_messages)

        setLoading(false)
      })
      .catch(err => {
        console.error('MessagesTable.initLoad.failed', err)
      })
  }

  React.useEffect(loadMessages, [organization.id, props.programId])

  const statusIcon = (status) => {
    switch (status) {
      case 'done':
        return <DoneIcon />
      case 'queue':
        return <ScheduleIcon />
      default:
        return null
    }
  }

  const format = (key, data) => {
    switch (key) {
      case 'publishDate':
        return new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'long'}).format(new Date(data[key]))
      case 'status':
        return statusIcon(data[key])
      case 'programId':
        return programs[data[key]] ? programs[data[key]].name : data[key]
      default:
        return data[key]
    }
  }

  // const generateLink = function (id, path) {
  //   const orgId = props.organizationId
  //   const query = ''
  //   return `/${path || 'survey'}/${orgId ? `${orgId}/${id}` : id}${query}`
  // }

  const onCloseSendMessageModal = function (res) {
    console.log('onCloseSendMessageModal', res)
    if (res.success) {
      setNotify({show: true, variant: 'success', message: `Successfully sent "${res.name}" Message.`})
      loadMessages()
    }
    setShowSendMessageModal(false)
  }

  const onNotifyClose = function () {
    setNotify({show: false})
  }

  const showMessageDetails = function (message) {
    setSelected({message})
    setShowViewMessageModal(true)
    console.log('showMessageDetails', selected, showViewMessageModal)
  }

  const onCloseViewMessageModal = function () {
    setShowViewMessageModal(false)
    setSelected(null)
  }

  if (loading) {
    return null 
    // TODO: show loading screen here
  }

  return (
    <React.Fragment>
      {
        showSendMessageModal ? 
          <SendMessageModal
            communicationMethod={programs[props.programId].communication_method}
            programId={props.programId}
            programName={programs[props.programId].name}
            organizationId={organization.id}
            open={showSendMessageModal}
            onClose={onCloseSendMessageModal} />
        : null
      }
      {
        showViewMessageModal ? 
          <SendMessageModal
            message={selected.message}
            communicationMethod={programs[props.programId].communication_method}
            programName={programs[props.programId].name}
            programId={props.programId}
            organizationId={organization.id}
            open={showViewMessageModal}
            onClose={onCloseViewMessageModal}
          />
        : null
      }
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}

      <Typography variant="h2">Instant Messaging</Typography>
      <Typography variant="h5">{programs[props.programId].name}</Typography>

      <br />
      <br />

      <TableContainer component={Paper} style={{borderTopLeftRadius: '0', borderTopRightRadius: '0', boxShadow: '1px 2px 2px 0px rgb(0 0 0 / 20%), 0px 4px 1px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'}}>
        <Table aria-label="Table of instant messages in queue or sent">
          <TableHead>
            <TableRow>
              {headers.map(header => <TableCell key={header.key}>{header.label}</TableCell>)}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              messages
                .map(row => (
                  <TableRow key={row.id}>
                    {headers.map(header => (
                      <TableCell key={`${row.id}_${header.key}`}>{format(header.key, row)}</TableCell>
                    ))}
                    <TableCell>
                      <IconButton aria-label="View Details" disabled={row.isDefault} onClick={() => showMessageDetails(row)}>
                        <PreviewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
          className={classes.newProgramFab}
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          onClick={() => setShowSendMessageModal(true)}
        >
        <MakeIcon />
        Send New Message
      </Fab>
    </React.Fragment>
  )
}