let self = {}

self.labelsByCode = {
  pss: 'Perceived Stress',
  edbd: 'Ectodermal Dysplasias-Burden of Disease',
	phq9: 'Assessment for Depression',
	gad7: 'Assessment for Anxiety',
	dsm5: 'Assessment for PTSD',
	brs: 'Assessment for Resilience',
	whofwbi98: 'Assessment for Wellness',
	aceq: 'Adverse Childhood Experience',
	mhl: 'Mental Health Literacy',
	scared: 'Screen for Child Anxiety Related Disorders',
	phqa: 'Assessment for Depression',
	mbihss: 'Assessment for Burnout in Healthcare Professionals',
	mbihssmp: 'Assessment for Burnout in Medical Professionals',
	mbies: 'Assessment for Burnout in Educators',
	mbigs: 'Assessment for Burnout in General Population',
	mbigss: 'Assessment for Burnout in Students'
}

self.defaultWelcomeEmailBody = `
<p>As part of your Survey enrollment provided by ResilienceNHope, please complete the following Survey.</p>

<p>Throughout the course of the program, assessments will need to be completed upon initial enrollment, 1 month, 3 months, 6 months, 9 months, and 12 months to help us learn about the program and help us improve our services.</p>

<p>At the end of the survey, we will offer you an interpretation of what your scores mean and suggestions about what you could do to improve your mental health on an ongoing basis.</p>

<p>Your responses are anonymous and confidential, we will only report results on a group basis.</p>
`

self.defaultReminderEmailBody = `
<p>This is a reminder to complete your survey assessment as part of the program provided in partnership with ResilienceNHope. By completing the assessment, you will confirm your enrollment in the program.</p>

<p>The information you provide in these ongoing assessments will help us learn about the impact of this program and help us to improve our services.</p>
`

self.defaultWelcomeSmsBody = `As part of your enrollment in the program, please complete the following assessment to confirm your participation.`

self.defaultReminderSmsBody = `This is a reminder to complete the survey assessment that was provided previously.`

const scoreScaredSection = function (answers, questions) {
	const scores = {
    total: 0,
		panic_disorder: 0,
		generalized_anxiety_disorder: 0,
		separation_anxiety_disorder: 0,
		social_anxiety_disorder: 0,
		significant_school_avoidance: 0,
	}
	// each type is scored by sum of answers for selective questions (by questionId) listed below:
	const types = {
		panic_disorder: [
			'0b3c4484-9435-43f7-9594-c80d7b6277ad',
			'0970f74f-2c23-4ceb-86bb-9f0996155b56',
			'bf66c3d2-3713-4a24-bea9-35f3da1aa51f',
			'0472578f-2209-4835-a281-c2f86b373916',
			'bff95c2a-b287-4bfd-a3b8-856e8237ab71',
			'ae11b250-87be-4bc7-8d98-6e60e4878277',
			'94d98218-9366-47a2-8a1b-c563369a5fae',
			'7e0a3c8a-71d3-4327-a332-fe87be2180ac',
			'28948b4b-9e81-4da8-9f76-1bbd52cf7937',
			'aba963c6-d67a-4b1c-b87a-17f55b8d60a5',
			'02abfd02-9648-49e0-8ddb-67b04e330e3f',
			'57978590-b5b6-4e3e-ac12-88b94368aad7',
			'814ae13b-86a1-4eb4-9b52-56f100e9a64f'
		],
		generalized_anxiety_disorder: [
			'e1b9717e-b303-4cd2-b60f-173066112b18',
			'3aed5076-b1bd-4ad0-b4cf-3763a1b466c9',
			'ddec23e0-6351-48d8-8811-995bcbab56d4',
			'f644d72f-c916-442d-8f76-8e3e4431b805',
			'404048a8-e2f4-46a4-bf43-60192ad7bf9e',
			'5a6b1dd6-bbff-4c2b-83d6-15d8f93795db',
			'3ce3df91-afa6-4a47-9a3a-bb9a0c033196',
			'de1c0be4-5750-473c-b906-686c25d6f3b1',
			'b5e29bb6-fdfd-4189-9220-0656578d7a02'
		],
		separation_anxiety_disorder: [
			'997fb4e5-6ddb-44a1-9d33-dd3b4bc90bdc',
			'884faf23-dd18-40ee-95fa-d8a519e7afef',
			'cdeacbe0-b0f8-4462-91a2-e0b503984b89',
			'a182d82e-828b-4f40-9fb1-17e5a10f162a',
			'700ef931-003c-4e78-9e8e-21be94345e69',
			'942b7332-1a72-4d11-aad6-2eaa9178711b',
			'85eea28c-bcb3-45f0-b7e2-62a89bd1a418',
			'd29f1033-3480-4c32-aead-94e31861df89'
		],
		social_anxiety_disorder: [
			'a8456686-7ea9-47aa-9072-54aa91e6312e',
			'190f3445-f9c9-4c90-9add-9ce0605a3f6f',
			'b13589da-8820-4061-a6e9-79b426f73945',
			'2b6c91ac-ce61-4ea6-9e1d-c1a21608377d',
			'13300cb3-8b43-46a8-af9e-d0a2bc97a276',
			'6f70a2cc-4421-4a8d-9235-6354b48a123e',
			'47056d7b-f348-4314-949b-451ce1e74b4b'
		],
		significant_school_avoidance: [
			'a4343fac-eff1-40d5-b61c-57eaea75c90f',
			'132c93c2-64e2-4016-81e7-7f7a04e143bb',
			'07223906-3919-44c4-afa0-72e263572d61',
			'bb775a02-b119-42c1-a81d-12960afbaeed'
		]
	}

	// iterate over all question/answers & check against types add to score if applicable
	for (const questionId in answers) {
    const answerId = answers[questionId]
    const question = questions.find(q => q.id === questionId)

    const typeKeys = Object.keys(types)

    typeKeys.forEach(typeKey => {
    	if (types[typeKey].includes(questionId)) {
    		if (question.options) {
		      const option = question.options.find(opt => opt.id === answerId)

		      if (option && option.score) {
            scores.total += option.score
		        scores[typeKey] += option.score
		      }
		    }
    	}
    })
  }

  // return the tallies for different scores
  return scores
}

const scoreMBISpecialized = function (section, answers, questions) {
  const scores = {
    ee_total: 0,
    ee_average: 0,
    dp_total: 0,
    dp_average: 0,
    pa_total: 0,
    pa_average: 0
  }

  // the mapping of answerMap is: questionId: questionIndex for faster lookups from answers
  const answerMap = {
    'ee': {
      'mbihss': {
        'cbff798e-60d5-4dda-9783-a4618eb7c616': 0,
        '54e4910d-208f-430c-b87d-df00b47164ba': 1,
        '03004267-aa40-41d4-84c4-2c5fd934ccbc': 2,
        '5f696402-7ef6-4219-a471-93ec26805861': 5,
        'd8fe04bd-fd9c-42d8-b586-355fc1b3df2d': 7,
        '3fe781d1-be6e-44d0-8934-e8e15ae2b627': 12,
        '8fcfccdf-dd0a-4445-98c8-4079f80e2793': 13,
        '47f6cfe1-f4a2-4015-a05c-8ab4c1dda39a': 15,
        'c3e2ea97-2759-485d-baee-b47b7ad07f09': 19
      },
      'mbihssmp': {
        'c0b43d40-0a62-4c0c-aa9d-9cd232e4fac0': 0,
        '3f7c3533-ead5-4293-bca6-119e787496d0': 1,
        '67140f92-d294-4468-810f-62699d2cbbb8': 2,
        '081b5fca-5fec-46d9-946d-f76fcf56e1bf': 5,
        '38579c23-e77f-4eee-b502-3a969ea21dbe': 7,
        '09e4bb39-6811-41bb-85fc-7dfa8991ff3e': 12,
        'dfb3c62b-1ebf-4194-946e-118c7338aa0f': 13,
        '872d7f3c-8aa2-4e58-853f-ded83e8bf2ed': 15,
        'fcbca058-47ae-48dc-8f1c-7cb2ef672fa0': 19
      },
      'mbies': {
        "84168790-ed97-426b-b077-da8d6da38808": 0,
        "bdc44208-0265-4492-932b-f2881d72d9ef": 1,
        "47944c8c-e770-465e-9d6c-eee3c12a5907": 2,
        "491d4e48-510c-44ce-bdfb-ffe2ba30183d": 5,
        "29b43434-d898-448a-825b-0cd2036663ee": 7,
        "b22ea11c-03b9-4e14-add3-48f1f647986f": 13,
        "e4413e6a-f082-474b-ad6d-9eb8eeb2ee1e": 12,
        "173c18de-5eb7-47bd-9e22-2f6508f48186": 15,
        "a877f7ca-12ad-41f9-b05b-9c4bc95b8c60": 19
      }
    },
    'dp': {
      'mbihss': {
        'e28d5e7e-1bc6-442c-84f6-f24d31644521': 4,
        'ebb8a355-2ddf-428e-a839-a976fe560e07': 9,
        '636fd039-0dbd-443e-8531-3e793cf1389f': 10,
        '6220f8b9-603c-44d9-85c2-4544e8cc80ae': 14,
        'ac2bd864-14f8-40b6-8655-95bc326000f2': 21
      },
      'mbihssmp': {
        '5830b56e-b550-4eda-a8c9-20f94312ff07': 4,
        '1e1ad0f4-75ff-4dec-bcea-dcd13e66dcbc': 9,
        'de3431ed-65d6-46b4-98a6-5c869d5773e3': 10,
        '872d7f3c-8aa2-4e58-853f-ded83e8bf2ed': 14,
        '1a025471-8466-4065-8cc4-78061f911bdd': 21
      },
      'mbies': {
        "7b0cf6db-f162-4ff3-9fc7-426beb0c8614": 4,
        "034c2606-ff87-4b4a-98dc-80dedd4bc524": 9,
        "692935e7-6d35-4683-badb-f2784fe25da8": 10,
        "5a5394be-2dd5-41e7-a5a6-b72d1f11b642": 14,
        "1418e693-30fe-4fbf-83fc-cd6505c332b9": 21
      }
    },
    'pa': {
      'mbihss': {
        'e28d5e7e-1bc6-442c-84f6-f24d31644521': 3,
        'd8fe04bd-fd9c-42d8-b586-355fc1b3df2d': 6,
        'ebb8a355-2ddf-428e-a839-a976fe560e07': 8,
        '3fe781d1-be6e-44d0-8934-e8e15ae2b627': 11,
        '53d98ef8-9ea5-46d6-8a34-2d8b4c3878ba': 16,
        'd520fe9e-55df-4661-9e3a-f0cc5840e11e': 17,
        '0b1cd0a2-146e-455d-810e-f3cafaab9768': 18,
        '82d2a702-b381-438f-a0e5-2493a0ed93d5': 20
      },
      'mbihssmp': {
        '3d28370b-c8c7-45e8-9baa-6bcf14f293c9': 3,
        '40e304aa-421f-45c2-9a5c-7a6926afa604': 6,
        '9f0fa7df-469c-4bf8-9b1b-ce6d11391073': 8,
        '52a6bfd3-c16c-4e31-9dd4-879ea5e0fa2c': 11,
        '09dd67e6-da98-420d-9fef-9d1c9f75a95a': 16,
        'b7e0beae-7ac6-40d0-aa10-ec1fccf28a81': 17,
        '040f90d0-bab0-4329-8ea3-7901f396d88b': 18,
        'dcf48231-b86e-4bf2-bfa5-fec2d9d54dc8': 20
      },
      'mbies': {
        "afc6caad-0a01-4d22-a081-c2a910dfc125": 3,
        "54e21b15-704c-4872-87c0-7ac7824706cc": 6,
        "1d10cfaa-cd52-4560-878a-84b61c80d567": 8,
        "be4bd03c-e891-48c5-b477-6e8ccfc7afa4": 11,
        "fbc5a744-71bc-4604-9b0a-7e8976a233c2": 16,
        "d3554630-8626-41b4-9d69-9ec827775e40": 17,
        "ed03a9f9-8d9c-452d-9089-ee4447afed58": 18,
        "7a650e24-8701-4dd9-8353-0fca1496dab3": 20
      }
    }
  }

  for (const subscale in answerMap) {
  	const questionIds = answerMap[subscale][section]
    let answerCount = 0 // used to calculate average
    
    for (const qId in questionIds) {
    	const qIndex = questionIds[qId]

    	const answerId = answers[qId]
      if (answerId) {
      	let question = questions[qIndex]
        answerCount++
        
        if (question.id !== qId) {
        	question = questions.find((q) => q.id === qId)
        }
        
        const option = question.options.find(opt => opt.id === answerId)
        
        // no need to add 0, since 0 + 1 = 1
        if (option && option.score) {
        	scores[`${subscale}_total`] += option.score
        }
      }
    }
    
    if (scores[`${subscale}_total`] > 0) {
	    scores[`${subscale}_average`] = Math.round(scores[`${subscale}_total`] / answerCount)
    } else {
      scores[`${subscale}_average`] = 0
    }
    
  }

  return scores
}

const scoreMbiGeneralized = function (section, answers, questions) {
  const scores = {
    ex_total: 0,
    ex_average: 0,
    cy_total: 0,
    cy_average: 0,
    pe_total: 0,
    pe_average: 0
  }

  const answerMap = {
    'cy': {
      'mbigs': {
        "05e32f6e-e390-43c9-a38b-a91c352fc4ea": 14,
        "105c854b-3114-4b32-adb1-ca6a25252300": 13,
        "1f8b34cf-1d0d-441e-93d2-ab522c658886": 12,
        "73658d1b-21ca-46d5-9e83-1a3fe9d17c98": 7,
        "b3d64ba5-f389-4739-a362-bd0f8ab37c44": 8
      },
      'mbigss': {
        "06bd8fa5-1bd0-44ab-b3a2-94a8faf82648": 14,
        "39a80e11-05c8-4a34-bb3d-5461e7529411": 8,
        "a0c0dd3c-0560-4f31-9722-e22d06b27485": 12,
        "adcc8c31-1c24-4220-bb58-686b0cbab457": 13,
        "aff1434c-aa78-4ffd-81d2-9e5c11bd70e7": 7
      }
    },
    'ex': {
      'mbigs': {
        "2446a1c0-d684-4276-b704-a990e8480291": 5,
        "4ab8e1e1-950a-4535-9e67-7c77fab6fe89": 3,
        "9009d5b7-6475-4f52-806c-f3b83e4cdb80": 2,
        "ce7ae0a7-4899-4968-8d6e-51a20afd4e11": 1,
        "fc51a7f3-830c-4ff3-8a44-7b199c705678": 0
      },
      'mbigss': {
        "17f80761-ba4f-4a01-8269-6afcae5ba6c7": 1,
        "1b769362-df11-4a29-8681-5bfed046556a": 0,
        "3afbc99d-1dda-4367-b20f-fceec9086d1d": 3,
        "5b91ca98-20ac-4c88-b6a6-651497aea7f2": 2,
        "cc015b0a-a652-4504-99c7-78763ba695f2": 5
      }
    },
    'pe': {
      'mbigs': {
        "5e07fd27-0ee2-49ff-8e69-e928ff66f08c": 10,
        "8e8e0166-e8d7-4f92-84e0-19ce43c1693e": 15,
        "b94178ae-ef55-4f95-b551-2ffc6871b7f8": 9,
        "d92bd2b7-3e82-418f-8e9a-debfb8095a00": 4,
        "ef81b3ec-b60b-402d-b964-5d5afabfd4cf": 6,
        "f7daa13a-39f1-4263-9a77-836765e91e12": 11
      },
      'mbigss': {
        "00d5cc65-da5d-42f8-96ef-ad584a87e607": 9,
        "1fda709f-ecbf-4369-b7d3-ca0533c3ef0f": 4,
        "8066e39d-b0c4-4973-9ae9-65c2d55a214b": 6,
        "cbbcc4d8-37e4-48b6-8da7-e8d11d376a56": 11,
        "e9b840f8-9fd2-4238-a6aa-8e57cd21ae09": 10,
        "f3905036-3df1-492c-b411-e68165857f4f": 15
      }
    }
  }

  for (const subscale in answerMap) {
  	const questionIds = answerMap[subscale][section]
    let answerCount = 0 // used to calculate average
    
    for (const qId in questionIds) {
    	const qIndex = questionIds[qId]
    	const answerId = answers[qId]
      if (answerId) {
      	let question = questions[qIndex]
        answerCount++
        
        if (question.id !== qId) {
        	question = questions.find((q) => q.id === qId)
        }
        
        const option = question.options.find(opt => opt.id === answerId)
        
        // no need to add 0, since 0 + 1 = 1
        if (option && option.score) {
        	scores[`${subscale}_total`] += option.score
        }
      }
    }
    
    if (scores[`${subscale}_total`] > 0) {
	    scores[`${subscale}_average`] = Math.round(scores[`${subscale}_total`] / answerCount)
    } else {
      scores[`${subscale}_average`] = 0
    }
    
  }

  return scores
}

const scoreMBISection = function (section, answers, questions) {
  switch (section) {
    case 'mbihss':
    case 'mbihssmp':
    case 'mbies':
      return scoreMBISpecialized(section, answers, questions)
    case 'mbigs':
    case 'mbigss':
      return scoreMbiGeneralized(section, answers, questions)
    default:
      return null
  }
}

self.score = function (assessmentCode, answers, questions) {
  let result = {score: 0}

	// scared has multiple scores, so it is written as an object for each type
	switch (assessmentCode) {
		case 'scared':
			result = scoreScaredSection(answers, questions)
			break
		case 'mbihss':
		case 'mbihssmp':
		case 'mbies':
		case 'mbigs':
		case 'mbigss':
			result = scoreMBISection(assessmentCode, answers, questions)
			break
		default:
			questions.forEach(question => {
				const answerId = answers[question.id]
				question.options.forEach((opt) => {
					if (opt.id === answerId) {
						result.score += opt.score
					}
				})
			})
			break
	}

  return result
}

module.exports = self