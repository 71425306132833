import React from 'react'
import {Link, OpenInNew} from '@material-ui/icons'
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import {Typography} from '@material-ui/core'
import Notify from './Notify'

export default function PublicProgramLink (props) {
  const {programId} = props
  const [notify, setNotify] = React.useState({show: false})

  if (!programId) {
    return <Typography>Your share link will be available after creation of Program.</Typography>
  }
  const origin = window.location.origin
  const publicLink = `${origin}/programSubscribe/${programId}`
  
  const openInNewWindow = () => {
    const win = window.open(publicLink, '_blank')
    win.focus()
  }

  const onNotifyClose = function () {
    setNotify({show: false})
  }

  const copyToClipboard = () => {
    navigator.permissions.query({name: "clipboard-write"})
      .then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard.writeText(publicLink)
            .then(() => {
              setNotify({show: true, variant: 'success', message: `Successfully copied link to clipboard!`})
            })
            .catch(err => {
              setNotify({show: true, variant: 'error', message: `Failed to copy link to clipboard, check browser permissions.`})
            })
        }
      })
      .catch(err => {
        setNotify({show: true, variant: 'error', message: `Failed to copy link to clipboard, check browser permissions.`})
      })
  }

  return (
    <div>
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
      <Typography variant={'body1'}>Share Link {/*<OpenInNew fontSize='small' onClick={openInNewWindow} />*/} <AssignmentIcon fontSize='small' onClick={copyToClipboard} /> </Typography>
      {publicLink}
    </div>
  )
}