import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, NativeSelect, FormHelperText, Select, MenuItem, Input } from '@material-ui/core'
import Auth from '../../services/auth'
import {makeStyles} from '@material-ui/core/styles'
import Surveys from '../../services/survey'


const useStyles = makeStyles({
  root: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  selected: {
    backgroundColor: 'rgb(56, 52, 150)'
  }
})

export default function MakeSurvey (props) {
  const classes = useStyles()
  const [surveys, setSurveys] = useState([])

  useEffect(() => {
    Surveys.get({organizationId: props.organizationId})
      .then(result => {
        const filteredSurveys = result.filter(sur => sur.published).filter(sur => !sur.programId || sur.id === props.surveyId)
        setSurveys(filteredSurveys)
      })
      .catch(err => {
        console.log('SurveySelector.getSurveys.error', err)
      })
  }, [props.organizationId])

  const onSelect = (e) => {
    console.log('SurveySelector.onSelect', e.target.value)
    props.onChange(e.target.value)
  }
  
  return (
    <FormControl fullWidth margin='dense'>
      <InputLabel id="survey-selector-label">Survey</InputLabel>
      <Select
        labelId="survey-selector-label"
        id="survey-selector"
        value={props.surveyId || ''}
        onChange={onSelect}
        required
        disabled={props.disabled}
        input={<Input />}
      >
        <MenuItem value="">Select a survey</MenuItem>
        {surveys.map(survey => <MenuItem key={survey.id} value={survey.id}>{survey.name}</MenuItem>)}
      </Select>
      <FormHelperText>Select the survey you wish subscribers to participate in</FormHelperText>
    </FormControl>
    // <FormControl margin='dense'>
    //   <InputLabel>Time of Day</InputLabel>
    //   <Select
    //     name='interval'
    //     value={state.interval}
    //     onChange={(e) => setState({...state, [e.target.name]: e.target.value})}
    //     required
    //   >
    //     <MenuItem value={'0 9 * * *'}>Every Morning</MenuItem>
    //     <MenuItem value={'0 17 * * *'}>Every Evening</MenuItem>
    //     <MenuItem value={'0 9,17 * * *'}>Every Morning and Evening</MenuItem>
    //     <MenuItem value={'0 8-18/2 * * *'}>Every 2 Hours from 8am through 6pm</MenuItem>
    //   </Select>
    //   <FormHelperText>When should we send out messages to your subscribers</FormHelperText>
    // </FormControl>
    // <FormControl>
    //   <InputLabel variant="standard" htmlFor="uncontrolled-native">
    //     Survey
    //   </InputLabel>
    //   <NativeSelect defaultValue={''} value={props.surveyId} inputProps={{ name: 'surveyId' }} onChange={onSelect} required disabled={props.disabled}>
    //     <MenuItem value="">Select a survey</MenuItem>
    //     {
    //       surveys.map(survey => <option value={survey.id}>{survey.name}</option>)
    //     }
    //   </NativeSelect>
    //   <FormHelperText>Select the survey you wish subscribers to participate in</FormHelperText>
    // </FormControl>
  )
}