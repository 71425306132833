import React from 'react'
import {Draggable} from 'react-beautiful-dnd'

import {
  IconButton,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch
} from '@material-ui/core'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import DeleteIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)'
  }
})

const DraggableListItem = ({item, index, disabled, activation, deletable, onDelete, onToggleActivation}) => {
  const classes = useStyles()

  const toggleActiveState = (item) => {
    if (onToggleActivation) {
      onToggleActivation(item)
    }
  }

  return (
    <Draggable draggableId={item.id} index={index}>
      {
        (provided, snapshot) => {
            return (
            <ListItem ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={snapshot.isDragging ? classes.draggingListItem : ''}>
              <ListItemAvatar>
                {
                  deletable && (
                    <IconButton onClick={() => {onDelete(item)}}>
                      <DeleteIcon />
                    </IconButton>
                  )
                }
                {
                  activation && (
                    <Switch
                      disabled={disabled}
                      checked={item.active}
                      onChange={() => {toggleActiveState(item)}}
                    />
                  )
                }
              </ListItemAvatar>
              <ListItemText primary={item.primary} secondary={item.secondary} />
              <DragHandleIcon />
              {/* <ListItemSecondaryAction>
                <Avatar style={{margin: '1em'}} />
              </ListItemSecondaryAction> */}
            </ListItem>
          )
        }
      }
    </Draggable>
  )
}

export default DraggableListItem