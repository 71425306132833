import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Chip,
} from "@material-ui/core";
import {Alert} from '@material-ui/lab'
import {CheckCircle, RemoveCircle, Warning, Error} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function RecipientStatusList (props) {
  const {recipients = {}} = props
  const classes = useStyles();
  const recipientList = Object.values(recipients)

  const getStatusIcon = (recipient) => {
    console.log('getStatusIcon', recipient)
    switch (recipient.status) {
      case 'not_exist':
        return <Error />
      case 'no_messaging':
        return <RemoveCircle />
      case 'sent':
        return <CheckCircle />
      default:
        return <Warning />
    }
  }

  if (!recipientList.length) {
    if (props.messageStatus === 'queue') {
      return (<Alert severity="info">Recipients status list will populate once the message has been sent.</Alert>)
    }
    return (
      <Alert severity="warning">No recipients received this message. There may have been no recipients to receive this message.</Alert>
    )
  }

  return (
    <Paper component="ul" className={classes.root}>
      {
        recipientList
          .map((data) => {
            return (
              <li key={data.destination}>
                <Chip
                  icon={getStatusIcon(data)}
                  label={data.destination}
                  className={classes.chip}
                />
              </li>
            );
          })
      }
    </Paper>
  );
}