import { RecaptchaVerifier } from 'firebase/auth'
import EnrollMfa from './enrollMultiFactor'
import AuthMfa from './verifyMultiFactor'

const MultiFactorAuthentication = ({auth, onDone, resolver, isEnrolled}) => {
  let verifier

  const onEnrollDone = (isVerified) => {
    // console.log('onEnrollDone', isVerified)
    if (onDone) {
      onDone(isVerified)
    }
  }
  
  const onMfaDone = (isVerified) => {
    // console.log('onMfaDone', isVerified)
    if (onDone) {
      onDone(isVerified)
    }
  }
  
  // console.log('MultiFactor.auth', auth)
  // console.log('MultiFactor.auth.currentUser', auth.currentUser)

  // console.log('enrolledFactors', enrolledFactors)

  window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-verifier', {
    size: 'invisible',
    callback: (response) => {
      // console.log('recaptha.callback', response)
    },
    'callback-expire': (e) => {
      // console.log('callback-expire', e) 
    }
  }, auth)

  // console.log('window.recaptchaVerifier', window.recaptchaVerifier)
  verifier = window.recaptchaVerifier

  if (!isEnrolled) {
    // start enrollment process
    return <EnrollMfa verifier={verifier} auth={auth} onDone={onEnrollDone} />
  } else {
    // start mfa authentication process
    return <AuthMfa verifier={verifier} onDone={onMfaDone} auth={auth} resolver={resolver} />
  }
}

export default MultiFactorAuthentication