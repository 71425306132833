import * as React from 'react'
import DraggableListItem from './DraggableListItem'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const DraggableList = React.memo(({ disabled, items, onDragEnd, activation, deletable, onDelete, onToggleActivation }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {
          provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {
                items.map((item, index) => <DraggableListItem disabled={disabled} activation={activation} deletable={deletable} onDelete={onDelete} onToggleActivation={onToggleActivation} item={item} index={index} key={item.id} /> )
              }
              {provided.placeholder}
            </div>
          )
        }
      </Droppable>
    </DragDropContext>
  )
})

export default DraggableList