import React from 'react'
import {makeStyles, Paper, IconButton, InputBase, Divider, FormHelperText, FormControl} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Email(props) {
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState(null)
  const classes = useStyles();

  // Email address matcher.
  var matcher = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  /**
   * Loosely validate an email address.
   *
   * @param {string} string
   * @return {boolean}
   */
  function isEmail(string) {
    return matcher.test(string);
  }

  const add = function () {
    const isValid = isEmail(email)

    // if that fails, then return error
    if (!isValid) {
      return setError({message: 'Invalid email, please enter a valid email address.'})
    }

    setError(null)

    if (props.onAdd) {
      props.onAdd({email, valid: true})
    }

    setEmail('')
  }

  return (
    <React.Fragment>
      <FormControl error={!!error}>
        <Paper component="form" className={classes.root}>
          {/* <TextField
            autoFocus
            name="email"
            margin="dense"
            label="Email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          /> */}
          <InputBase
            className={classes.input}
            placeholder="Email Address"
            // startAdornment={<InputAdornment position="start">+</InputAdornment>}
            inputProps={{ 'aria-label': 'Email Address'}}
            value={email}
            type="email"
            onChange={e => setEmail(e.target.value)}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton onClick={add} color="primary" className={classes.iconButton} aria-label="directions">
            <AddIcon />
          </IconButton>
        </Paper>
        <FormHelperText>e.x. name@domain.com</FormHelperText>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </React.Fragment>
  )
}