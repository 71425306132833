import React from 'react'
import {ListItem, List, ListItemSecondaryAction, ListItemText, IconButton, makeStyles} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe'
import SubscribeIcon from '@material-ui/icons/Email'
import auth from '../services/auth'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '36ch',
    overflow: 'auto',
    backgroundColor: theme.palette.background.primary,
  }
}))

export default function SubscribersList (props) {
  const classes = useStyles()
  const {subscribers = [], disableActions} = props

  const onRemove = function (index) {
    if (props.onRemove) {
      props.onRemove(index)
    }
  }

  const unsubscribe = function (index) {
    console.log('unsubscribe', index)
    if (props.onUnsubscribe) {
      props.onUnsubscribe(index)
    }
  }

  const subscribe = function (index) {
    console.log('subscribe', index)
    if (props.onSubscribe) {
      props.onSubscribe(index)
    }
  }

  const toggleSubscription = function (index, subscribed) {
    if (subscribed) {
      return unsubscribe(index)
    }

    return subscribe(index)
  }

  const renderSecondaryLabel = function (sub) {
    if (sub.completed) {
      return 'COMPLETED'
    }
    if (sub.registered) {
      return sub.subscribed ? 'SUBSCRIBED' : 'UNSUBSCRIBED'
    }

    return !sub.valid ? 'INVALID' : (sub.countryCode || '')
  }

  console.log('subscribers', subscribers)

  return (
    <List className={classes.root}>
      {
        subscribers
          .map((sub, index) => (
            <React.Fragment key={`${sub.phone || sub.email}_${index}`}>
              <ListItem alignItems='flex-start'>
                <ListItemText
                  primary={sub.phone || sub.email}
                  secondary={
                  renderSecondaryLabel(sub)
                  } />
                <ListItemSecondaryAction>
                  {
                    !sub.registered ? (
                      <IconButton onClick={() => onRemove(index)} edge="end" aria-label="delete" disabled={disableActions}>
                        <DeleteIcon />
                      </IconButton>
                    ) : null }
                  {
                    sub.registered && auth.hasFeature('delete') ? (
                      <IconButton onClick={() => toggleSubscription(index, sub.subscribed)} edge="end" aria-label={sub.subscribed ? "unsubscribe" : "subscribe"} disabled={disableActions}>
                        {
                          sub.subscribed ? <UnsubscribeIcon /> : <SubscribeIcon />
                        }
                      </IconButton>
                    ) : null
                  }
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          ))
      }
    </List>
  )
}