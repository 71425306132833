import React, { Fragment } from 'react'
import { FormControlLabel, Checkbox, Chip, FormHelperText, Grid, Typography } from '@material-ui/core'
import Auth from '../services/auth'

export default function Features (props) {
  const feats = {
    organization: {
      label: 'Organization',
      description: 'Managing organization',
      assignable: ['owner', 'admin']
    },
    program: {
      label: 'Programs',
      description: 'View, Add and Update Programs',
      assignable: ['owner', 'admin', 'user']
    },
    subscriber: {
      label: 'Subscribers',
      description: 'View and Add Subscribers',
      assignable: ['owner', 'admin', 'user']
    },
    message: {
      label: 'Messages',
      description: 'View, Add, Update Messages',
      assignable: ['owner', 'admin', 'user']
    },
    user: {
      label: 'Users',
      description: 'Manage users and admins in Organization',
      assignable: ['owner', 'admin']
    },
    permission: {
      label: 'Permissions',
      description: 'Manage users permissions in Organization',
      assignable: ['owner', 'admin']
    },
    delete: {
      label: 'Deletions',
      description: 'Deletion of Programs and Surveys, and subscription management',
      assignable: ['owner', 'admin']
    },
    survey: {
      label: 'Surveys',
      description: 'Add, Update, and Publish Surveys',
      assignable: ['owner', 'admin', 'user']
    }
  }

  const onChange = function (e) {
    let features = props.features || {}
    // console.log('features.onChange', {...features, [e.target.name]: e.target.checked})
    props.onChange({...features, [e.target.name]: e.target.checked})
  }

  const isOdd = (index) => index % 2 !== 0
  const isEven = (index) => index % 2 === 0

  return (
    <div>
      {
        props.readOnly && Object.keys(feats)
          .filter(k => ((props.features || {})[k] || props.owner))
          .map(key => (<Chip key={key} variant="outlined" size="small" label={feats[key].label} />))
      }
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {
            !props.readOnly && Object.keys(feats || {})
              .filter(key => Auth.hasFeature(key))
              .filter((key, index) => (isEven(index)))
              .map(key => (
                <Fragment>
                  <FormControlLabel
                    key={key}
                    disabled={props.disabled}
                    control={<Checkbox checked={(props.features || {})[key] || props.owner} onChange={onChange} name={key} label={feats[key].label} />}
                    label={feats[key].label}
                  />
                  <FormHelperText>{feats[key].description}</FormHelperText>
                </Fragment>
              ))
          }
        </Grid>
        <Grid item xs={6}>
          {
            !props.readOnly && Object.keys(feats || {})
              .filter(key => Auth.hasFeature(key))
              .filter((key, index) => (isOdd(index)))
              .map(key => (
                <Fragment>
                  <FormControlLabel
                    key={key}
                    disabled={props.disabled}
                    control={<Checkbox checked={(props.features || {})[key] || props.owner} onChange={onChange} name={key} label={feats[key].label} />}
                    label={feats[key].label}
                  />
                  <FormHelperText>{feats[key].description}</FormHelperText>
                </Fragment>
              ))
          }
        </Grid>
      </Grid>
    </div>
  )
}