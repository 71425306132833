import Auth from './auth'
import firebase from './firebase'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API

let self = {}

self.get = function (params) {
  const {organizationId, id} = params
  let query = firebase.database().ref(`/participants/${organizationId}/${id ? id : ''}`)

  return query.once('value')
    .then(snap => {
      let participants = snap.val()

      if (id) {
        participants = [participants]
      } else {
        participants = Object.values(participants || {})
      }

      return participants
    })
}

self.invite = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/importParticipants`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.deleteInvite = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/cancelParticipantInvite`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
  })
}

self.getInvite = function (params) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/getParticipantInvite`, params)
      .then(res => resolve(res.data))
      .catch(e => reject(e.response.data))
  })
}

self.register = function (params) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/registerParticipant`, params)
      .then(res => resolve(res.data))
      .catch(e => reject(e.response.data))
  })
}

export default self