import React from 'react'
import {ListItem, List, ListItemSecondaryAction, ListItemText, IconButton, makeStyles} from '@material-ui/core'
import SuccessIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import DeleteIcon from '@material-ui/icons/Delete'
import WarningIcon from '@material-ui/icons/WarningOutlined'
import LoadingIcon from '@material-ui/icons/RotateRightOutlined'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '36ch',
    overflow: 'auto',
    backgroundColor: theme.palette.background.primary,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '70px',
    justifyContent: 'space-between'
  }
}))

export default function SubscribersList (props) {
  const classes = useStyles()
  const {participants = []} = props

  const onRemove = function (index) {
    if (props.onRemove) {
      props.onRemove(index)
    }
  }

  return (
    <List className={classes.root}>
      {
        participants
          .map((sub, index) => (
            <React.Fragment key={`${sub.phone || sub.email}_${index}`}>
              <ListItem alignItems='flex-start'>
                <ListItemText
                  primary={sub.phone || sub.email}
                  secondary={
                    (sub.status === 'import' ? <span className={classes.status}><LoadingIcon fontSize="small" /> Importing...</span> : null) ||
                    (sub.status === 'done' ? <span className={classes.status}><SuccessIcon fontSize="small" /> Invited</span> : null) ||
                    (sub.status === 'fail' ? <span className={classes.status}><ErrorIcon fontSize="small" /> Failed</span> : null) ||
                    (!sub.valid ? <span className={classes.status}><WarningIcon fontSize="small" /> Invalid</span> : null)
                  }
                />
                <ListItemSecondaryAction>
                  {sub.status !== 'done' && <IconButton onClick={onRemove.bind(null, index)} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>}
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          ))
      }
    </List>
  )
}