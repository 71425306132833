import parsePhoneNumber  from 'libphonenumber-js'

let self = {}

self.parse = function (phone, cc = null) {
  // console.log('phoneParser.parse', phone, cc)
  if (!parsePhoneNumber(phone, cc)) {
    return {valid: false, error:{message: 'Invalid number, phone number must follow E.164 standard'}}
  }
  
  let valid = parsePhoneNumber(phone, cc).isValid()
  const number = parsePhoneNumber(phone, cc).number
  const countryCode = parsePhoneNumber(phone, cc).country

  // console.log('phoneParser.parse.valid', valid, number, countryCode)

  // not sure why parsePhoneNumber doesn't catch this, so we check against the countryCode argument to see if it's valid
  if (countryCode !== cc) {
    valid = false
  }

  // // if that fails, then return error
  if (!valid) {
    return {valid: false, error:{message: 'Invalid number, phone number must follow E.164 standard'}}
  }

  return {
    valid,
    phone: number,
    countryCode,
  }
}

export default self