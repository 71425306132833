import React from 'react'
import { Button, CircularProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {CheckCircle, Error} from '@material-ui/icons'
import Subscriber from '../services/subscriber'
const {adminUseSubscribe, adminUseUnsubscribe} = Subscriber

export default function ChangeSubscriberSubscriptionStatus (props) {
  const {subscriberId, programId, organizationId, request} = props
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState(null)

  const onClose = () => {
    props.onClose(result)
  }

  React.useEffect(() => {
    console.log('ChangeSubscriberSubscriptionStatus.useEffect', loading, result)
    if (loading) {
      return
    }

    if (!result) {
      setLoading(true)
    
      const func = request === 'subscribe' ? adminUseSubscribe : adminUseUnsubscribe

      console.log('ChangeSubscriberSubscriptionStatus.funcCall()', request)
      
      func({id: subscriberId, programId, organizationId})
        .then(() => {
          setResult({subscribed: request === 'subscribe'})
          setLoading(false)
        })
        .catch(err => {
          console.error(`ChangeSubscriberSubscriptionStatus.${request}.failed`, err)
          setResult({error: true})
          setLoading(false)
        })
    }
  }, [result, loading, subscriberId, programId, organizationId, request])

  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            request === 'subscribe' ? 'Updating subscriber status to Subscribed' : 'Updating subscriber status to Unsubscribed'
          }
        </DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
          {
            loading ? (
              <DialogContentText>
                <CircularProgress color="secondary" /> <br />
                Please wait while we update the Subscriber's status.
              </DialogContentText>
            ) : null
          }

          {
            result && !result.error ? (
              <DialogContentText>
                <CheckCircle /> <br />
                Subscriber has successfully been {`${request}d`}.
              </DialogContentText>
            ) : null
          }

          {
            (result && result.error) ? (
              <DialogContentText>
                <Error /> <br />
                Failed to update subscriber's status. Try refreshing the page and retry your request, if the issue persists please contact an administrator.
              </DialogContentText>
            ) : null
          }
        </DialogContent>
          {
            result ? (
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            ) : null
          }
      </Dialog>
    </div>
  );
}