import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button } from "@material-ui/core";
import Survey from '../../services/survey'
import {Alert, AlertTitle} from '@material-ui/lab'
import LoadingIcon from '@material-ui/icons/RotateRightOutlined'

export default function DeleteSurveyModal (props) {
  const [survey, setSurvey] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [saving, setSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    Survey
      .get({id: props.id, organizationId: props.organizationId})
      .then(surveyResult => {
        console.log('surveyResult', surveyResult)
        setSurvey(surveyResult)
        setLoading(false)
      })
  }, [props.id, props.organizationId])

  const onConfirm = function () {
    const {id} = survey

    setError(null)
    setSaving(true)

    Survey.delete({id, organizationId: props.organizationId})
      .then(() => {
        setSaving(false)
        props.onClose({success: true, surveyName: survey.name})
      })
      .catch(err => {
        setSaving(false)
        setError({message: 'Failed to delete survey, please try again. If the issue persists, please contact an administrator.'})
        console.log('DeleteSurvey.onConfirm.failed', err)
      })
  }

  const onClose = function () {
    props.onClose({})
  }

  if (loading) {
    return null
  }

  return (
    <Dialog open={props.open} aria-labelledby="Edit User">
      <DialogTitle>Are you sure you want to delete the survey, {survey.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          By deleting this survey, You will lose all data for this survey permanently.
        </DialogContentText>

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading || saving}>Cancel</Button>
        <Button onClick={onConfirm} color="primary" disabled={loading || saving}>
          {saving ? <LoadingIcon fontSize="small" /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}