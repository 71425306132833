import React, { Fragment } from 'react'
import {
  FormControl,
  FormHelperText,
  Typography,
} from '@material-ui/core'
import RichText from './RichText'
import ImageUploader from './ImageUploader'

export default function PublicFieldsProgramForm (props) {
  const [description, setDescription] = React.useState(props.description || '')

  const onChangeDescription = (newBody) => {
    setDescription(newBody)
    
    if (props.onChangeDescription) {
      props.onChangeDescription(newBody)
    }
  }

  return (
    <Fragment>
      <FormControl>
        <Typography style={{margin: '10px'}} variant='body1'>Program Description</Typography>
        <RichText body={description} onChange={onChangeDescription} disabled={false} required={false} />
      </FormControl>

      <FormControl fullWidth>
        <Typography style={{margin: '10px'}} variant='body1'>Program Display Image</Typography>
        <ImageUploader heading="Image" onSelect={props.onSelectImage} selected={props && ((props.assets && props.assets[0]) || (props.imageSrc ? {src: props.imageSrc} : null))} />
        <FormHelperText>We recommend images at 1280x600px, The maximum width supported is 1232 pixels, height is 300 pixels and overflow is hidden. By enforcing a max-width of 100% (1232 pixels), the image will always show 100% of the wide angle while height remains visible at 300 pixels.</FormHelperText>
      </FormControl>
    </Fragment>
  )
}