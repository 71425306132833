import React from 'react'
import {makeStyles, Paper, IconButton, InputBase, Divider, InputAdornment, FormHelperText, FormControl} from '@material-ui/core'
import PhoneParser from '../services/phoneParser'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function PhoneNumber(props) {
  const [phone, setPhone] = React.useState('')
  const [error, setError] = React.useState(null)
  const classes = useStyles();

  const add = function () {
    const res = PhoneParser.parse(phone, props.countryCode)

    // if that fails, then return error
    if (!res.valid) {
      return setError({message: 'Invalid number, phone number must follow E.164 standard'})
    }

    setError(null)

    if (props.onAdd) {
      return props.onAdd(res)
    }
  }

  return (
    <React.Fragment>
      <FormControl error={!!error}>
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Phone Number"
            startAdornment={<InputAdornment position="start">+</InputAdornment>}
            inputProps={{ 'aria-label': 'Phone Number'}}
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton onClick={add} color="primary" className={classes.iconButton} aria-label="directions">
            <AddIcon />
          </IconButton>
        </Paper>
        <FormHelperText>[country code][phone number] e.x. (14031231234) - E.164 Format</FormHelperText>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </React.Fragment>
  )
}