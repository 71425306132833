import React from 'react'
import { TableFooter, TablePagination, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Switch, Button, CircularProgress } from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles'
import {green, grey} from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import MakeOrganization from './MakeOrganization';
import MakeIcon from '@material-ui/icons/Add'
import Orgs from '../services/organization'
import Auth from '../services/auth'
import Notify from './Notify'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const GreenSwitch = withStyles({
  switchBase: {
    color: grey[300],
    '&$checked': {
      color: green[500]
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    }
  },
  checked: {},
  track: {}
})(Switch)

const ConfirmDeleteOrganization = function (props) {
  const [open] = React.useState(true);

  const decline = () => {
    props.onClose(false)
  }

  const agree = () => {
    props.onClose(true)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={decline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Are you sure you want to delete ${props.organizationName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            By deleting this organization, all programs will be disabled and deleted. Users who were a part of this organization will lose authentication and be deleted. Surveys will be deleted and participants will no longer be able to participate in any survey attached to this organization.
          </DialogContentText>

          {props.loading && (
            <DialogContentText>
              <CircularProgress color="secondary" /> Please wait while we process your request...
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={decline} color="primary" disabled={props.loading}>
            Cancel
          </Button>
          <Button onClick={agree} color="primary" autoFocus disabled={props.loading}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ConfirmStatusChange = function (props) {
  const [open] = React.useState(true);

  const decline = () => {
    props.onClose(false)
  }

  const agree = () => {
    props.onClose(true)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={decline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Are you sure you want to ${props.active ? 'Deactivate' : 'Activate'} this Organization?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              props.active && (
                'By de-activating this organization, all programs will be disabled. No new subscribers can join, existing subscribers will stop receiving their messages, and you cannot invite new subscribers.'
              )
            }
            {
              !props.active && (
                'By activating this organization, all programs will be enabled. New subscribers can join, invites can be sent out, and existing subscribers will start receiving messages again.'
              )
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={decline} color="primary">
            Cancel
          </Button>
          <Button onClick={agree} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function OrganizationsTable (props) {
  const [timer, setTimer] = React.useState(null)
  const [count, setCount] = React.useState(0)
  const [showMakeOrg, setShowMakeOrg] = React.useState(false)
  const [query, setQuery] = React.useState({limit: 10, page: 0, name: ''})
  const [orgs, setOrgs] = React.useState([])
  const [notify, setNotify] = React.useState({show: false})
  const [promptStatusChange, setPromptStatusChange] = React.useState({show: false, org: null})
  const [promptDeleteOrg, setPromptDeleteOrg] = React.useState({show: false, org: null, loading: false})

  const onChangeQuery = function (newQuery) {
    setQuery(newQuery)

    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }
    
    setTimer(setTimeout(function () {
      console.log('query orgs', newQuery)
      Orgs.query(newQuery)
        .then(orgs => {
          setOrgs(orgs)
          setCount(orgs.length) // temp
        })
        .catch(err => {
          console.error('OrganizationsTable.orgs.get.failed', err)
          setOrgs([])
          setCount(0)
        })
    }, 500))
  }

  React.useEffect(() => {
    Orgs.query(query)
      .then(orgs => {
        setOrgs(orgs)
        setCount(orgs.length) // temp
      })
  }, [query])

  const headers = [
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'Status',
      key: 'active'
    },
    {
      label: 'Phone Number',
      key: 'phoneNumber'
    },
    {
      label: 'Country Code',
      key: 'countryCode'
    },
    {
      label: 'Message Count',
      key: 'stats_sent'
    }
  ]

  const makeOrg = function () {
    setShowMakeOrg(true)
  }

  const onMakeOrgClose = function (res) {
    if (res.success) {
      setNotify({show: true, variant: 'success', message: `Successfully created ${res.name} Organization.`})
      onChangeQuery(query)
    }
    setShowMakeOrg(false)
  }

  const onNotifyClose = function () {
    setNotify({show: false})
  }

  const onSelect = function (id) {
    if (props.onSelect) {
      props.onSelect(id)
    }
  }

  const toggleActive = function (org) {
    const {disabled, id} = org

    if (disabled) {
      return Orgs.enable(id)
        .then(() => {
          const _orgs = orgs.map(o => {
            if (o.id === id) {
              o.disabled = false
            }
            return o
          })

          setOrgs(_orgs)
        })
    }

    return Orgs.disable(id)
      .then(() => {
        const _orgs = orgs.map(o => {
          if (o.id === id) {
            o.disabled = true
          }
          return o
        })

        setOrgs(_orgs)
      })
  }

  const onConfirmStatusChange = function (confirm) {
    if (confirm) {
      toggleActive(promptStatusChange.org)
    }

    setPromptStatusChange({show: false, org: null})
  }

  const promptStatusChangeModal = function (org) {
    setPromptStatusChange({show: true, org})
  }

  const promptDeleteOrganizationModal = function (org) {
    setPromptDeleteOrg({show: true, org, loading: false})
  }

  const onDeleteOrganization = function (confirm) {
    if (confirm) {
      console.log('delete.org ', promptDeleteOrg)
      setPromptDeleteOrg({...promptDeleteOrg, loading: true})
      
      Orgs.delete(promptDeleteOrg.org.id)
        .then(() => {
          setNotify({show: true, variant: 'success', message: `Successfully deleted "${promptDeleteOrg.org.name}" Organization.`})

          setPromptDeleteOrg({show: false, org: null, loading: false})

          onChangeQuery(query)
        })
        .catch(err => {
          setPromptDeleteOrg({...promptDeleteOrg, loading: false})
          setNotify({show: true, variant: 'error', message: `Failed to delete "${promptDeleteOrg.org.name}" Organization, please try again. If the issue persists, contact an administrator.`})
        })
    } else {
      setPromptDeleteOrg({show: false, org: null, loading: false})
    }
  }

  return (
    <React.Fragment>
      {promptStatusChange.show && <ConfirmStatusChange onClose={onConfirmStatusChange} active={!promptStatusChange.org.disabled} />}
      {promptDeleteOrg.show && <ConfirmDeleteOrganization onClose={onDeleteOrganization} organizationName={promptDeleteOrg.org.name} loading={promptDeleteOrg.loading} />}
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Organizations
        <IconButton onClick={makeOrg}>
          <MakeIcon />
        </IconButton>
      </Typography>
      {/* <Typography variant="h5">My Programs</Typography> */}
      <TableContainer component={Paper}>
        <Table aria-label="Table of Organizations">
          <TableHead>
            <TableRow>
              <TableCell>
                <TextField label="Search by name" value={query.name} onChange={(e) => onChangeQuery({...query, name: e.target.value})} fullWidth />
              </TableCell>
            </TableRow>
            <TableRow>
              {headers.map(header => <TableCell key={header.key}>{header.label}</TableCell>)}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              orgs
                .slice(query.page * query.limit, ((query.page + 1) * query.limit))
                .map(row => (
                  <TableRow key={row.id}>
                    {
                      headers
                        .map(header => {
                          switch (header.key) {
                            case 'active': {
                              return <TableCell key={`${row.id}_${header.key}`}>
                                <GreenSwitch
                                  checked={!row.disabled}
                                  onChange={() => promptStatusChangeModal(row)}
                                  name="active"
                                  inputProps={{ 'aria-label': 'Organization Active State' }}
                                />
                              </TableCell>
                            }
                            case 'stats_sent':
                              return <TableCell key={`${row.id}_${header.key}`}>{row.stats.sent}</TableCell>
                            case 'name':
                              return <TableCell key={`${row.id}_${header.key}`}>
                                <Button onClick={() => onSelect(row.id)}>
                                  {row.name}
                                </Button>
                              </TableCell>
                            default:
                              return <TableCell key={`${row.id}_${header.key}`}>{row[header.key]}</TableCell>
                          }
                        })
                    }
                    <TableCell>
                      {/* <Switch
                        checked={!row.disabled}
                        onChange={() => toggleActive(row)}
                        name="active"
                        inputProps={{ 'aria-label': 'Organization Active State' }}
                      /> */}
                      {
                        Auth.isRole('owner') ? (
                          <IconButton onClick={() => promptDeleteOrganizationModal(row)} aria-label="Delete Organization">
                            <DeleteIcon />
                          </IconButton>
                        ) : null
                      }
                    </TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[query.limit]}
                colSpan={headers.length}
                count={count}
                rowsPerPage={query.limit}
                page={query.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={(e, page) => setQuery({...query, page})}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <MakeOrganization open={showMakeOrg} onClose={onMakeOrgClose} />
    </React.Fragment>
  )
}