import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import { getAuth } from 'firebase/auth'

let self = {}
let instance = null
let authInstance = null

self.initialize = function () {
  if (!instance) {
    instance = firebase.initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,// "sms-vincent.firebaseapp.com",
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID
    })
  }
}

self.getInstance = () => {
  return instance
}

self.database = function () {
  return instance.database()
}

self.auth = function () {
  return getAuth(instance)
}

export default self