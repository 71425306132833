import Auth from './auth'
import firebase from './firebase'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API

let selected = null
let listeners = {}
let self = {}

function updateProgram (snap) {
  // console.log('program.updated', snap.val())
  selected.program = snap.val()
}

function updateMessages (snap) {
  // console.log('program.messages.updated', snap.val())
  selected.messages = snap.val()
}

function updateReplies (snap) {
  // console.log('program.replies.updated', snap.val())
  selected.replies = snap.val()
}

function updateSubscribers (snap) {
  // console.log('program.subscribers.updated', snap.val())
  selected.subscribers = snap.val()
}

function loadProgram (id, filters) {
  if (!filters) { filters = {} }
  
  let reqs = [
    firebase.database().ref(`/programs/${id}`).once('value')
      .then(snap => ({program: snap.val()}))
  ]

  if (!filters.message) {
    reqs.push(
      firebase.database().ref(`/messages/${id}`).once('value')
        .then(snap => ({messages: Object.values(snap.val() || {})}))
    )
    reqs.push(
      firebase.database().ref(`/replies/${id}`).once('value')
        .then(snap => {
          // console.log('getReplies.then', snap.val())
          const data = snap.val() || {}
          return {replies: Object.keys(data).map(k => ({...data[k], event: k}))}
        })
    )
  }

  if (!filters.subscriber) {
    reqs.push(
      firebase.database().ref(`/subscribers/${id}`).once('value')
        .then(snap => ({subscribers: Object.values(snap.val() || {})}))
    )
  }

  return Promise.all(reqs)
    .then(responses => {
      return Object.assign(...responses)
      // const program = responses[0].val()
      // const messages = responses[1] && responses[1].val() || []
      // const subscribers = responses[2] && responses[2].val() || []

      // return {program, messages: Object.values(messages || {}), subscribers: Object.values(subscribers || {})}
    })
}

self.create = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/createProgram`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
  // const {name} = params
  // const org = firebase.database().ref(`/programs/`).push()

  // return org.set({name, id: org.key})
}

self.update = function (params) {
  console.log('Program.update', params)
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/updateProgram`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.activate = function (id, organizationId) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/updateProgram`, {id, organizationId, active: true}, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.deactivate = function (id, organizationId) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/updateProgram`, {id, organizationId, active: false}, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.importReplies = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/importReplies`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.importMessages = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/importMessages`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.importSubscribers = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/importSubscribers`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.deleteAllMessages = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/deleteAllMessages`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.delete = function (id, organizationId) {
  const params = {id, organizationId}
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/deleteProgram`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.searchSubscribers = function (params) {
  const {programId, limit = 10} = params
  let {searchKey} = params

  if (!searchKey) {
    searchKey = 'phone'
  }
  
  let {query = ''} = params
  // because we write phones validated from twilio, they include +{countryCode}{phoneNumber}, so to simplify search we will auto append +{countryCode}
  // for now this is simple since we only support Canada/U.S. - for future, we'll need to identify the countryCode and append it correctly
  const countryCode = '+1'

  // can search by email or phone, so only prepend countryCode if phone
  if (searchKey === 'phone') {
    query = `${countryCode}${query}`
  }

  return firebase
    .database()
    .ref(`/subscribers/${programId}`)
    .orderByChild(searchKey)
    .startAt(query)
    .limitToFirst(limit)
    .once('value')
    .then(snap => 
      Object.values(snap.val() || {})
        .filter(sub => !sub.noMessaging)
    )
}

self.getPublicProgram = function (id) {
  return new Promise((resolve, reject) => {
    return axios.post(`${API_URL}/getProgram`, {id})
    .then(res => resolve(res.data))
    .catch(err => reject(err.response.data))
  })
}

self.get = function (params) {
  const {organizationId, id} = params
  console.log('Program.get', organizationId, id)
  // console.log('Program.get', params, 'ref:', `/programs/${id ? id : ''}`)
  let query = firebase.database().ref(`/programs/${id ? id : ''}`)

  const now = (new Date())
  const month = now.getUTCMonth()
  const year = now.getUTCFullYear()

  // console.log('Program.get', organizationId, id)

  if (organizationId) {
    // console.log('programs.get - search by organizationId')
    query = query.orderByChild('organizationId').equalTo(organizationId)
  }

  return query.once('value').then(snap => {
    let progs = snap.val()

    console.log('query.once', progs)

    if (!progs) {
      return []
    }

    if (id) {
      if (progs.deleted) {
        return []
      }
      progs = [progs]
    } else {
      progs = Object.values(progs || {}).filter(prog => !prog.deleted)
    }

    // console.log('Program.get.then', progs)

    return Promise.all(
      progs
        .map(prog => {
          // console.log('the path', `/stats/${prog.organizationId}/${year}_${month}/${prog.id}`)
          return firebase.database().ref(`/stats/${prog.organizationId}/${year}_${month}/${prog.id}`).once('value')
            .then(s => {
              const stats = s.val()
              return {...prog, stats: stats || {sent: 0, success: 0, fail: 0}}
            })
        })
    )
      .then(results => {
        if (id) {
          return results[0]
        }
        return results
      })
  })
}

self.allTimeStats = function (orgId, programId) {
  return firebase.database()
    .ref(`/subscriberStats/${orgId}/${programId}`)
    .orderByChild('total')
    .once('value')
    .then(snap => (snap.val() || {}))
}

self.stats = function (orgId, programId, year, month) {
  return firebase.database()
    .ref(`/subscriberStats/${orgId}/${programId}/${year}_${month}`)
    .once('value')
    .then(snap => snap.val())
}

self.set = function (id, filters) {
  return loadProgram(id, filters)
    .then(prog => {
      if (!filters) {filters = {}}
      selected = prog
      listeners.update = firebase.database().ref(`/programs/${id}`).on('child_changed', updateProgram)
      
      if (!filters.message) {
        listeners.updateMessages = firebase.database().ref(`/messages/${id}`).on('child_changed', updateMessages)
        listeners.updateReplies = firebase.database().ref(`/replies/${id}`).on('child_changed', updateReplies)
      }

      if (!filters.subscriber) {
        listeners.updateSubscribers = firebase.database().ref(`/subscribers/${id}`).on('child_changed', updateSubscribers)
      }

      return selected
    })
}

self.unset = function () {
  if (!selected && !Object.keys(listeners).length) {return}

  listeners.update.off('child_changed', updateProgram)
  
  if (listeners.updateMessages) {
    listeners.updateMessages.off('child_changed', updateMessages)
  }

  if (listeners.updateReplies) {
    listeners.updateReplies.off('child_changed', updateReplies)
  }

  if (listeners.updateSubscribers) {
    listeners.updateSubscribers.off('child_changed', updateSubscribers)
  }

  delete listeners.update
  delete listeners.updateMessages
  delete listeners.updateSubscribers
  delete listeners.updateReplies

  selected = null
}

export default self