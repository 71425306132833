import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button, TextField, Select, FormControl } from "@material-ui/core";
import User from '../services/user'
import {Alert, AlertTitle} from '@material-ui/lab'
export default function InviteUser (props) {
  const [state, setState] = React.useState({
    email: '', role: 'user', organizationId: props.organizationId, orgName: props.orgName
  })
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)

  const onCreate = function () {
    setLoading(true)
    // console.log('onCreate', state)
    return User.invite(state)
      .then(() => {
        setLoading(false)
        props.onClose({success: true, invite: state})
      })
      .catch(() => {
        setLoading(false)
        setError({message: 'Failed to invite user, please try again. If the issue persists, please contact an administrator.'})
      })
  }

  const onClose = function () {
    props.onClose({})
  }

  return (
    <Dialog open={props.open} aria-labelledby="Invite User">
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        <DialogContentText>
            Enter the details below to invite a User. Once submitted, an invite email will be sent to the email address specified below.
        </DialogContentText>

        <TextField
          autoFocus
          name="email"
          margin="dense"
          label="Email"
          type="email"
          value={state.email}
          onChange={e => setState({...state, [e.target.name]: e.target.value})}
          helperText="e.x. name@domain.com"
          fullWidth
        />

        <FormControl fullWidth>
          <Select
            native
            value={state.role}
            inputProps={{
              name: 'role',
            }}
            onChange={e => setState({...state, [e.target.name]: e.target.value})}
            fullWidth
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </Select>
        </FormControl>

      {
        error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        )
      }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>Cancel</Button>
        <Button onClick={onCreate} color="primary" disabled={!state.email || loading}>Invite</Button>
      </DialogActions>
    </Dialog>
  )
}