import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Switch, Tooltip, Button, Fab } from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles'

import { labelsByCode } from '../../utils/survey';
import Survey from '../../services/survey'
import Program from '../../services/program'
import MakeIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import PublishIcon from '@material-ui/icons/Publish';
import ReportIcon from '@material-ui/icons/TableChart'
import useAppState from '../../services/useAppState'
import Notify from '../Notify'
import {Link} from 'react-router-dom'
import ScheduleIcon from '@material-ui/icons/Schedule'
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteSurveyModal from './DeleteSurveyModal'
import PublishSurveyModal from './PublishSurveyModal'

const useStyles = makeStyles((theme) => (
  {
    statusActive: {
      color: '#4caf50'
    },
    statusInactive: {
      color: '#616161'
    },
    statusDisabled: {
      color: '#d32f2f'
    },
    newProgramFab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    }
  }
))

export default function SurveysTable (props) {
  const classes = useStyles()
  const [surveys, setSurveys] = React.useState([])
  const [programs, setPrograms] = React.useState({})
  // const [count, setCount] = React.useState(0)
  const {organization} = useAppState()
  // const [query, setQuery] = React.useState({})
  // const [promptStatusChange, setPromptStatusChange] = React.useState({show: false, program: null})
  const [promptPublishModal, setPromptPublishModal] = React.useState({show: false, survey: null})
  const [promptDeleteSurvey, setPromptDeleteSurvey] = React.useState({show: false, survey: null})
  const [notify, setNotify] = React.useState({show: false})
  
  const onNotifyClose = function () {
    setNotify({show: false})
  }

  const promptPublishSurveyModal = function (survey) {
    setPromptPublishModal({show: true, survey})
  }

  const promptDeleteSurveyModal = function (survey) {
    setPromptDeleteSurvey({show: true, survey})
  }

  const headers = [
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'Status',
      key: 'published'
    },
    {
      label: 'Language',
      key: 'language'
    },
    {
      label: 'Assessments',
      key: 'assessment'
    },
    {
      label: 'Program Link',
      key: 'programId'
    }
  ]

  const labelMap = labelsByCode

  const loadSurveys = () => {
    Survey.get({organizationId: organization.id})
      .then(surveys => {
        const programIds = []
        console.log('got surveys', surveys)

        surveys.forEach(survey => {
          // if linked to program, grab id's to query program for name
          if (survey.programId) {
            programIds.push(survey.programId)
          }
        })

        Promise.all(programIds.map(id => Program.get({id})))
          .then(programResults => {
            let programsById = {}
            programResults.forEach(prog => programsById[prog.id] = prog)
            console.log('getPrograms.then', programResults)
            setPrograms(programsById)
          })

        setSurveys(surveys)
        // setCount(programs.length) // temp
      })
  }

  React.useEffect(() => {    
    // setQuery({organizationId: organization.id})
    // console.log('ProgramsTable.init', organization.id)
    loadSurveys()
  }, [organization.id])

  const statusIcon = (status) => {
    switch (status) {
      case 'process':
        return <CircularProgress
          variant="indeterminate"
          disableShrink
          size={20}
          thickness={2}
        />
      case 'queue':
        return <ScheduleIcon />
      default:
        return null
    }
  }

  const format = (key, data) => {
    switch (key) {
      case 'published':
        return data[key] ? 'Published' : 'Draft'
      case 'assessment':
        return data[key].filter(assessment => assessment.active).map(assessment => labelMap[assessment.code]).join(', ')
        // have to filter out subscribed: false
        // return data[key] ? Object.keys(data[key]).filter(id => data[key][id]).length : 0
      case 'stats_sent':
        return (data.stats || {}).sent || 0
      case 'nextRun':
        return new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'long'}).format(new Date(data[key]))
      case 'disabled':
        return Boolean(data.disabled) ? 'Inactive' : 'Active'
      case 'isDefault':
        const isDefault = data.isDefault
        return <Tooltip title={isDefault ? 'Default' : 'Not Default'}>
          {/* <GreenSwitch
            checked={isDefault}
            onChange={() => promptDefaultChangeModal(data)}
            name="active"
            inputProps={{ 'aria-label': 'Default program assignment for Participants' }}
          /> */}
        </Tooltip>
      case 'active': {
        const isDisabled = data.disabled
        const isActive = data.active
        return <Tooltip title={isActive ? 'Active' : 'Inactive'}>
          {/* <GreenSwitch
            checked={isActive && !isDisabled}
            // onChange={() => promptStatusChangeModal(data)}
            name="active"
            inputProps={{ 'aria-label': 'Program Active State' }}
          /> */}
        </Tooltip>

      }
      case 'status':
        return statusIcon(data[key])
      case 'programId':
        return programs[data[key]] ? programs[data[key]].name : data[key]
      default:
        return data[key]
    }
  }

  const generateLink = function (id, path) {
    const orgId = props.organizationId
    const query = ''
    return `/${path || 'survey'}/${orgId ? `${orgId}/${id}` : id}${query}`
  }

  const onCloseDeleteSurvey = function (res) {
    if (res.success) {
      setNotify({show: true, variant: 'success', message: `Successfully deleted Survey, ${res.name}`})
      loadSurveys()
    }

    setPromptDeleteSurvey({show: false, survey: null})
  }

  const onClosePublishSurvey = function (res) {
    if (res.success) {
      setNotify({show: true, variant: 'success', message: `Successfully published the Survey, ${res.name}`})
      loadSurveys()
    }

    setPromptPublishModal({show: false, survey: null})
  }

  return (
    <React.Fragment>
      {promptDeleteSurvey.show && <DeleteSurveyModal open={promptDeleteSurvey.show} onClose={onCloseDeleteSurvey} id={promptDeleteSurvey.survey.id} organizationId={organization.id} />}
      {promptPublishModal.show && <PublishSurveyModal open={promptPublishModal.show} onClose={onClosePublishSurvey} id={promptPublishModal.survey.id} organizationId={organization.id} />}
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
      <TableContainer component={Paper} style={{borderTopLeftRadius: '0', borderTopRightRadius: '0', boxShadow: '1px 2px 2px 0px rgb(0 0 0 / 20%), 0px 4px 1px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'}}>
        <Table aria-label="Table of Surveys">
          <TableHead>
            <TableRow>
              {headers.map(header => <TableCell key={header.key}>{header.label}</TableCell>)}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              surveys
                .map(row => (
                  <TableRow key={row.id}>
                    {headers.map(header => (
                      <TableCell key={`${row.id}_${header.key}`}>{format(header.key, row)}</TableCell>
                    ))}
                    <TableCell>
                      <Link to={generateLink(row.id)}>
                        <IconButton aria-label="Edit Survey">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton aria-label="Publish Survey" disabled={row.published} onClick={() => promptPublishSurveyModal(row)}>
                        <PublishIcon />
                      </IconButton>
                      <IconButton aria-label="Delete Survey" disabled={row.published} onClick={() => promptDeleteSurveyModal(row)}>
                        <DeleteIcon />
                      </IconButton>
                      <Link to={generateLink(row.id, 'surveyReport')}>
                        <IconButton aria-label="Survey Report">
                          <ReportIcon />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Link to={location => generateLink('new')}>
        <Fab
            className={classes.newProgramFab}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="add"
          >
          <MakeIcon />
          New Survey
        </Fab>
      </Link>
    </React.Fragment>
  )
}