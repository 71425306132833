import { useState, useEffect } from 'react'
import { PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth'
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Alert, AlertTitle} from '@material-ui/lab'
import {Send} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  view: {
    marginTop: '5rem',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  viewPublic: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0//theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  dialog: {
    minWidth: '350px'
  }
}))

const AuthMfa = ({auth, verifier, resolver, onDone}) => {
  const classes = useStyles()
  const [verifyId, setVerifyId] = useState(null)
  const [otpCode, setOtpCode] = useState('')
  const [error, setError] = useState(null)

  // console.log('AuthMfa', auth, verifier, resolver, onDone)

  const phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session
  };

  const submit = () => {
    const cred = PhoneAuthProvider.credential(verifyId, otpCode);

    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    resolver.resolveSignIn(multiFactorAssertion)
      .then(userCredential => {
        if (onDone) {
          onDone(true)
        }
      })
      .catch(err => {
        // console.log('authMfa.resolveSignIn.catch', err)
        setError({message: 'Invalid or expired code. Click resend code or cancel and try logging in again.'})
      })
  }
  
  const cancel = () => {
    if (onDone) {
      onDone(false)
    }
  }

  let phoneAuthProvider = new PhoneAuthProvider(auth)

  const resend = () => {
    phoneAuthProvider = new PhoneAuthProvider(auth)

    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verifier)
      .then(function (verificationId) {
          setVerifyId(verificationId)
      })
      .catch(err => {
        // console.log('authMfa.verifyPhoneNumber.catch', err)
        setError({message: 'MultiFactor authentication failed, click cancel and try again.'})
      })
  }
  
  useEffect(() => {
    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verifier)
      .then(function (verificationId) {
          setVerifyId(verificationId)
      })
      .catch(err => {
        console.error('authMfa.verifyPhoneNumber.catch', err)
        if (err.code === 'auth/too-many-requests') {
          setError({message: 'Too many requests, please wait a few minutes and try again'})
        }
      })
  }, [])

  return (
    <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication">
    <DialogTitle>Multi Factor Authentication Required</DialogTitle>
    {/* <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} /> */}
    <DialogContent>
      <p>Please enter the 6 digit one-time passcode that was sent to your phone number.</p>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <TextField
          autoFocus
          name="passcode"
          margin="dense"
          label="Passcode"
          type="text"
          value={otpCode}
          onChange={e => setOtpCode(e.target.value)}
          helperText="You will receive a 6 digit code on your phone."
          required
        />
        <Button
          onClick={resend}
          endIcon={<Send />}
        >
          ReSend Code
        </Button>
      </div>
      
      {
        error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
            {/* Failed to send verification email, please try again later. If the issue persists, please contact an administrator. */}
          </Alert>
        )
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={cancel} color="secondary">Cancel</Button>
      <Button onClick={submit} color="primary">Submit</Button>
    </DialogActions>
  </Dialog>
  )
}

export default AuthMfa