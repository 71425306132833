import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import { Drawer, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import useAppState from '../services/useAppState'
import auth from '../services/auth'
import {useHistory} from 'react-router-dom'
import logo from '../logo.png'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBar: {
    left: drawerWidth
  },
  link: {
    color: 'unset',
    cursor: 'unset',
    textDecoration: 'unset'
  },
  toolbar: {...theme.mixins.toolbar, display: 'flex', alignItems: 'center', justifyContent: 'center'}
}))
export default function LeftNav () {
  const classes = useStyles()
  const {organization} = useAppState()
  const history = useHistory()

  const goTo = function (key) {
    let path = '/'
    const append = auth.isRole('owner') && organization ? `${organization.id}` : ''

    switch (key) {
      case 'organizations':
        path = '/organizations'
        break
      case 'programs':
        path = `/dashboard/${append}`
        break
      case 'users':
        path = `/users/${append}`
        break
      case 'participants':
        path = `/participants/${append}`
        break
      case 'surveys':
        path = `/surveys`
        break
      default:
        path = '/'
        break
    }
    
    history.push(path)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '200px'}} />
      </div>
      <Divider />
      <List>
        {
          organization && (
            <ListItem>
              <ListItemText primary={organization.name} secondary={'organization'} />
            </ListItem>
          )
        }
        {
          auth.isRole('owner') && (
            <ListItem button onClick={() => goTo('organizations')}>
              <ListItemText primary={'Organizations'} />
            </ListItem>
          )
        }
        
        {
          auth.hasFeature('program') ? (
            <ListItem disabled={!organization} button onClick={() => goTo('programs')}>
              <ListItemText primary={'Programs'} />
            </ListItem>
          ) : null
        }

        {
          auth.hasFeature('survey') ? (
            <ListItem disabled={!organization} button onClick={() => goTo('surveys')}>
              <ListItemText primary={'Surveys'} />
            </ListItem>
          ) : null
        }
        
        {
          auth.hasFeature('user') ? (
            <ListItem disabled={!organization} button onClick={() => goTo('users')}>
              <ListItemText primary={'Admins'} />
            </ListItem>
          ) : null
        }

        {/* <ListItem disabled={!organization} button onClick={() => goTo('participants')}>
          <ListItemText primary={'Participants'} />
        </ListItem> */}
      </List>
      <Divider />

      <div style={{textAlign: 'center', position: 'absolute', bottom: 0}}>
        <Typography>
          Building resilience and inspiring hope in individuals and communities globally
        </Typography>
      </div>

    </Drawer>
  )
}