import React from 'react'
import { Button, Typography, Dialog, DialogTitle, DialogContent, TextField, DialogActions, CircularProgress } from '@material-ui/core'
import {useParams, useHistory} from 'react-router-dom'
import User from '../services/user'
import {Alert, AlertTitle} from '@material-ui/lab'

export default function AcceptInvite (props) {
  const {id} = useParams()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [invite, setInvite] = React.useState(null)
  const [state, setState] = React.useState({name: '', password: '', success: false})
  const history = useHistory()

  const submit = function () {
    setError(null)
    // console.log('submit form', {...state, inviteId: id})
    User.register({...state, inviteId: id})
      .then(res => {
        // console.log('AcceptInvite.User.register.then', res)
        setState({...state, success: true})
        setLoading(false)
      })
      .catch(err => {
        console.error('submit.err', err)
        setError({message: 'Failed to register, please try again. If the issue persists, please contact an administrator.'})
        setLoading(false)
      })
  }

  const goToLogin = function () {
    return history.replace('/')
  }
  
  React.useEffect(() => {
    User.getInvite({id})
      .then(invite => {
        // console.log('got invite', invite)
        setInvite(invite)
        setLoading(false)
      })
      .catch(err => {
        // console.log('getInvite.failed', err)
        setLoading(false)
      })
  }, [id])

  // console.log('AcceptInvite')

  if (loading) {
    return (
      <Dialog open={true} aria-labelledby="Accept Invitation">
        <DialogTitle>Loading Invite</DialogTitle>
        <DialogContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (state.success) {
    return (
      <Dialog open={true} aria-labelledby="Accept Invitation">
        <DialogTitle>You're registered!</DialogTitle>
        <DialogContent>
          You are now registered, you can now login with your new credentials.
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={onClose} color="secondary">Cancel</Button> */}
          <Button onClick={goToLogin} color="primary">Go To Login</Button>
        </DialogActions>
      </Dialog>
    )
  }

  if (!id || !invite) {
    return (
      <Dialog open={true} aria-labelledby="Accept Invitation">
        <DialogTitle>Corrupt or Expired Invite</DialogTitle>
        <DialogContent>
          The invite is no longer valid, please have an invite re-sent. If the issue persists, report to an administrator.
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div>
      <Dialog open={true} aria-labelledby="Accept Invitation">
        <DialogTitle>You've been invited to {invite.orgName}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Complete the form below to register your account.</Typography>

          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            value={invite.email}
            fullWidth
            disabled
          />

          <TextField
            autoFocus
            name="name"
            margin="dense"
            label="Name"
            type="text"
            value={state.name}
            onChange={e => setState({...state, [e.target.name]: e.target.value})}
            fullWidth
          />

          <TextField
            autoFocus
            name="password"
            margin="dense"
            label="password"
            type="password"
            value={state.password}
            onChange={e => setState({...state, [e.target.name]: e.target.value})}
            fullWidth
          />

          {
            error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error.message}
              </Alert>
            )
          }
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={onClose} color="secondary">Cancel</Button> */}
          <Button onClick={submit} color="primary">Register</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}