import React from 'react'
import ParticipantsTable from './ParticipantsTable'
import { Grid } from '@material-ui/core'
import Auth from '../services/auth'
import useAppState from '../services/useAppState'

export default function Participant () {
  const {organization} = useAppState()

  return (
    <div>
      <Grid spacing={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ParticipantsTable organizationId={Auth.isRole('owner') ? organization.id : ''} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}