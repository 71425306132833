import React from 'react'
import {IconButton, Divider, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar, Avatar, makeStyles} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ScheduleIcon from '@material-ui/icons/Schedule'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '36ch',
    overflow: 'auto',
    backgroundColor: theme.palette.background.primary,
  },
  inline: {
    display: 'inline',
  },
}))

export default function EmailMessagesList (props) {
  const classes = useStyles()

  const parseTime = function (date) {
    // const d = new Date(date)
    // const hours = d.getHours()
    // const minutes = d.getMinutes()

    return date// `${hours}:${minutes}`
  }

  const select = function (msg) {
    // console.log('MessageList.select', msg, props)
    if (props.onSelect) {
      props.onSelect(msg)
    }
  }

  const onRemove = function (msg) {
    if (props.onRemove) {
      props.onRemove(msg)
    }
  }

  return (
    <List className={classes.root}>
      {
        props.messages
          .sort((a, b) => a.index - b.index)
          .map(message => {
          let time = message.time

          if (time) {
            time = parseTime(time)
          }
          
          return (
            <React.Fragment key={`${message.id || message.index || message.event}`}>
              <ListItem alignItems='flex-start' onClick={() => select(message)} button>
                <ListItemAvatar>
                  <Avatar>
                    {message.index}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={message.subject}
                  secondary={<span style={{display: 'flex'}}>
                    {/* <span style={{marginRight: '1em'}}>{message.type === 'message' ? <MessageIcon fontSize="small" /> : <ReplyIcon fontSize="small" />}</span> */}
                    {/* <span style={{marginLeft: '1em', marginRight: '1em'}}>{message.type === 'reply' && (
                      (message.event === 'subscribe' ? <SubscribeIcon fontSize="small" /> : <UnsubscribeIcon fontSize="small" />)
                    )}</span> */}
                    {message.body}
                    <span style={{marginRight: '1em'}}>
                      {
                        time && <span style={{display: 'flex'}}><ScheduleIcon fontSize="small" />&nbsp;{time}</span>
                      }</span>
                  </span>}
                >
                </ListItemText>
                <ListItemSecondaryAction>
                  {
                    props.removable && (
                      <IconButton edge="end" aria-label="delete" onClick={() => onRemove(message)} disabled={props.readOnly}>
                        <DeleteIcon />
                      </IconButton>
                    )
                  }
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant='inset' component='li' />
            </React.Fragment>
          )
        })
      }
    </List>
  )
}