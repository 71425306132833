import 'date-fns'
import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button, Typography} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import {useDropzone} from 'react-dropzone'
import importer from '../services/importer'
import MessagesList from './MessagesList'
import EmailMessagesList from './EmailMessagesList'

export default function ImportMessages (props) {
  const [state, setState] = React.useState({messages: []})
  const [error, setError] = React.useState(null)
  const [warning, setWarning] = React.useState(null)
  // console.log('ImportMessages', props)

  const isValidMessage = function (msg) {
    switch (props.communicationMethod) {
      case 'sms':
        return msg.body && msg.index && parseInt(msg.index)
      case 'email':
        return msg.body && msg.subject && msg.index && parseInt(msg.index)
      default:
        return null
    }
  }

  const onImport = function (data) {
    // console.log('ImportMessages.onImport', data)
    setError(null)
    importer.messages({type: 'csv', content: data.blob})
      .then(res => {
        let hasInvalidCount = 0
        let newMessages = res.data
          .filter(msg => isValidMessage(msg)) // index and body required
          .map((msg, i) => {
            return {
              valid: msg.valid,
              subject: msg.subject,
              body: props.communicationMethod === 'email' ? `<p>${msg.body}</p>` : msg.body,
              time: msg.time,
              index: parseInt(msg.index),
              type: msg.type || 'message'
            }
          })

        // console.log('newMessages', newMessages)

        if (!newMessages.length) {
          return setError({message: 'No messages found in file with body and index.'})
        }

        if (newMessages.length && newMessages.length === hasInvalidCount) {
          return setError({message: 'All messages in the file are invalid.'})
        }

        if (hasInvalidCount) {
          setWarning({message: 'Some of the messages in this file have invalid fields, only valid messages will be imported.'})
        }

        const messages = [].concat([], newMessages)
        // console.log('ImportMessages.onImport', messages)

        setState({...state, messages})
      })
      .catch(err => {
        console.log('Importer.failed', err)
      })
  }

  const onDrop = function (files) {
    // console.log('file dropped', files)
    
    if (files && files.length) {
      let file = files[0]

      const reader = new FileReader()

      reader.onload = function (e) {
        return onImport({
          name: file.name,
          blob: e.target.result,
          type: file.type,
          size: file.size
        })
      }

      reader.readAsText(files[0])
      // reader.readAsBinaryString(files[0])
    }
  }

  const onRemove = function (msg) {
    setError(null)
    let messages = [...state.messages]
    const index = messages.findIndex(m => m.index === msg.index)

    if (index >= 0) {
      messages.splice(index, 1)

      setState({...state, messages})
    }
  }

  const onAdd = function () {
    const {messages} = state

    props.onClose({success: true, messages})
  }
  
  const isInvalidForm = function () {
    return !(state.messages.length)
  }

  const onClose = function () {
    props.onClose({})
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Dialog open={props.open} aria-labelledby="Import Message(s)">
      <DialogTitle>Import Message(s)</DialogTitle>
      <DialogContent>
        <DialogContentText>
            Drag and drop a csv file with the messages you wish to add to the program. All existing messages will be removed and replaced with the ones in the CSV file.
        </DialogContentText>

        <br />
        <br />

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </div>

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }

        {
          warning && (
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              {warning.message}
            </Alert>
          )
        }

        <br />

        <Typography variant={'h5'}>Messages ({state.messages.length})</Typography>
        {
          props.communicationMethod === 'sms' && <MessagesList messages={state.messages} onRemove={onRemove} removable={true} />
        }
        {
          props.communicationMethod === 'email' && <EmailMessagesList messages={state.messages} onRemove={onRemove} removable={true} />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={onAdd} color="primary" disabled={isInvalidForm()}>Import</Button>
      </DialogActions>
    </Dialog>
  )
}