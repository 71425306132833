import { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Typography } from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import {makeStyles} from '@material-ui/core/styles'

import logo from '../../logo.png'
import auth from '../../services/auth'

const useStyles = makeStyles((theme) => ({
  view: {
    marginTop: '5rem',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  viewPublic: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0//theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  dialog: {
    minWidth: '350px'
  }
}))

export default function ResetPassword() {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [message, setMessage] = useState(null)

  const reset = function () {
    auth.resetPassword(email)
      .then(() => {
        setMessage({message: 'Password reset email has been sent, please follow the instructions in the email.'})
      })
  }

  const exit = function () {
    // reload window - easier to clear state
    window.location.reload(false);
  }

  return (
    <div>
      <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication">
        <DialogTitle>Reset My Password</DialogTitle>
        <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} />
        <DialogContent>
          <Typography>Enter the email address and hit submit to reset your password.</Typography>
          <TextField
            autoFocus
            margin="dense"
            label="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
            required
          />

          {
            message && (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                {message.message}
              </Alert>
            )
          }

          {
            error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error.message}
              </Alert>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => exit()} color="secondary">Back to Login</Button>
          <Button onClick={reset} color="primary" disabled={!email}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}