import React, { useEffect } from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import useAppState from '../../services/useAppState'
import {makeStyles} from '@material-ui/core/styles'
import Notify from '../Notify'
import SurveyForm from './SurveyForm'
import DraggableList from '../DraggableList/DraggableList'

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 15,
    minWidth: 350,
    listStyleType: 'none'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 550
  }
})

const reorder = function (list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)

  return result
}

export default function QuestionsList (props) {
  const classes = useStyles()
  
  const onDragEnd = ({destination, source}) => {
    if (!destination) {return}

    const newItems = reorder(props.questions, source.index, destination.index)

    props.onChange(newItems)
  }

  const onDelete = (item) => {
    console.log('QuestionsList.delete', item)
    let questions = [...props.questions]
      .filter(q => q.id !== item.id)

    props.onChange(questions)
  }

  const generateSecondaryLabel = (fieldType, requiredField) => {
    const typeMap = {
      'paragraph': 'Text area input',
      'shortanswer': 'Text input',
      'dropdown': 'Drop Down',
      'multichoice': 'Multiple Choice',
      'linearscale': 'Linear Scale'
    }

    const required = requiredField ? 'Mandatory' : 'Optional'
    const label = typeMap[fieldType]

    return `${required} ${label}`
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.flexPaper}>
        <DraggableList
          deletable={true}
          onDelete={onDelete}
          items={
            (props.questions || [])
              .map(({id, question, type, required}) => ({id, primary: question, secondary: generateSecondaryLabel(type, required), required}))
            }
          onDragEnd={onDragEnd}
        />
      </Paper>
    </div>
  )
}