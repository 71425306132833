import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button } from "@material-ui/core";
import User from '../services/user'
import {Alert, AlertTitle} from '@material-ui/lab'

export default function DeleteUser (props) {
  const [user, setUser] = React.useState({
    email: '', role: 'user', organizationId: null
  })
  const [loading, setLoading] = React.useState(true)
  const [saving, setSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    User.get({id: props.id})
      .then(user => {
        setUser(user)
        setLoading(false)
      })
  }, [props.id])

  const onConfirm = function () {
    const {id} = user

    setError(null)
    setSaving(true)

    User.delete(id)
      .then(() => {
        setSaving(false)
        props.onClose({success: true, email: user.email})
      })
      .catch(err => {
        setSaving(false)
        setError({message: 'Failed to delete user, please try again. If the issue persists, please contact an administrator.'})
        console.log('DeleteUser.onConfirm.failed', err)
      })
  }

  const onClose = function () {
    props.onClose({})
  }

  if (loading) {
    return null
  }

  return (
    <Dialog open={props.open} aria-labelledby="Edit User">
      <DialogTitle>Are you sure you want to delete the user, {user.email}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          By deleting this user, they will no longer have access to the application and will no longer be able to login with their account.
        </DialogContentText>

        <DialogContentText>
          If you wish to give this user access in the future, they must be re-invited as a user to the application.
        </DialogContentText>

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading || saving}>Cancel</Button>
        <Button onClick={onConfirm} color="primary" disabled={loading || saving}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}