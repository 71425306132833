import Papa from 'papaparse'

const OPTIONS = {
  header: true,
  transformHeader: str => str.toLowerCase()
}

let self = {}

self.participants = function (data) {
  return jsonifyFile(data)
}

self.users = function (data) {
  return jsonifyFile(data)
}

self.messages = function (data) {
  return jsonifyFile(data)
}

const jsonifyFile = function (data) {
  return new Promise((resolve, reject) => {
    try {
      const {content, type, options = OPTIONS} = data

      services[`${type}ToJson`](content, options)
        .then(resolve)
    } catch (e) {
      reject(e)
    }
  })
}

const services = {
  csvToJson: function (csv, options) {
    return new Promise((resolve, reject) => {
      try {
        const parsed = Papa.parse(csv, options)

        console.log('importer.csv.parsed', parsed)

        resolve(parsed)
      } catch (e) {
        reject(e)
      }
    })
  }
}

export default self