import 'date-fns'
import React from 'react'
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Typography
} from "@material-ui/core";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import RichText from './RichText'
import ImageUploader from './ImageUploader'

export default function MakeMessage (props) {
  const [state, setState] = React.useState({body: '', timeSensitive: false, time: null, format: 'text'})
  const editMode = !!props.message

  React.useEffect(() => {
    if (props.message) {
      const {body = '', subject = '', prepend = '', assets = null, image = null, imageSrc = null, excludePrepend = false, index = 1, id = null, event, type = 'message', format = 'text'} = props.message
      let {time = null} = props.message
      const timeSensitive = Boolean(time)

      if (timeSensitive) {
        let dateTime = new Date()
        const hours = time.split(':')[0]
        const minutes = time.split(':')[1]
        dateTime.setHours(hours)
        dateTime.setMinutes(minutes)
        time = dateTime
      }

      setState({body, subject, assets, image, imageSrc, time, index, id, type, timeSensitive, event, prepend, excludePrepend, format})
    }
  }, [props.message])

  const onAdd = function () {
    const {id = null, assets = null, image = null, imageSrc = null, body, subject, time, timeSensitive, index, type, event, prepend, excludePrepend, format = 'text'} = state
    let message = {id, body, subject, assets, image, imageSrc, time, index, type, event, prepend, excludePrepend, format}
    
    if (!timeSensitive && time) {
      message.time = null
    }

    if (timeSensitive) {
      let dateTime = new Date(time)
      let hours = dateTime.getHours()
      let minutes = dateTime.getMinutes()

      message.time = `${hours}:${minutes}`
    }

    props.onClose({success: true, message})
  }
  
  const isInvalidForm = function () {
    if (state.reply) {
      return false
    }

    if (!state.body) {
      return true
    }

    if (props.communicationMethod === 'email' && !state.subject) {
      return true
    }

    if (state.timeSensitive && !state.time) {
      return true
    }
  }

  const onClose = function () {
    props.onClose({})
  }

  const onSelectImage = function (assets) {
    let image = null
    
    if (assets && assets[0]) {
      const fileNamePieces = assets[0].name.split('.')
      const extension = fileNamePieces[fileNamePieces.length - 1]
      image = {
        fileSize: assets[0].size,
        extension
      }
    }

    setState({...state, assets, image})
  }

  // const toggleTimePicker = function (e) {
  //   let time = null
  //   let timeSensitive = e.target.checked
    
  //   if (timeSensitive) {
  //     time = new Date()
  //   }

  //   setState({...state, timeSensitive, time})
  // }

  const events = [
    {
      value: 'subscribe',
      label: 'On Subscribe'
    },
    {
      value: 'invite',
      label: 'On Invite'
    },
    {
      value: 'unsubscribe',
      label: 'On Unsubscribe'
    }
  ]

  return (
    <Dialog open={props.open} aria-labelledby="Create your message">
      {state.type === 'message' && <DialogTitle>Create your message</DialogTitle>}
      {state.type === 'reply' && <DialogTitle>Create your reply message</DialogTitle>}
      <DialogContent>
        <DialogContentText>
            {state.type === 'message' && 'Enter the details below to create a Message. Message will be inserted at the next available index.'}
            {state.type === 'reply' && 'Replies come with required pre-pended messages, you can append your custom message by filling in the body field below.'}
        </DialogContentText>

        {
          state.format === 'html' && (
            <div>
              <TextField
                autoFocus
                name="subject"
                margin="dense"
                label="Subject"
                type="textarea"
                value={state.subject}
                onChange={e => setState({...state, [e.target.name]: e.target.value})}
                helperText="Enter the subject of the message"
                fullWidth
                required={props.communicationMethod === 'email'}
              />

              <br />
              <br />
              
              <Typography>Body</Typography>

              <RichText body={state.body} onChange={(newBody) => { setState({...state, body: newBody})}} />

              <br />

              <Typography>Image</Typography>
              <ImageUploader heading="Image" onSelect={onSelectImage} selected={state && ((state.assets && state.assets[0]) || (state.imageSrc ? {src: state.imageSrc} : null))} />
            </div>
          )
        }

        {
          state.format !== 'html' && (
            <TextField
              autoFocus
              name="body"
              margin="dense"
              label="Body"
              type="textarea"
              value={state.body}
              onChange={e => setState({...state, [e.target.name]: e.target.value})}
              helperText="Enter the body of the message"
              fullWidth
            />
          )
        }

        {/* {
          state.type === 'message' && (
            <React.Fragment>
              <FormControlLabel
                control={<Switch name="timeSensitive" checked={state.timeSensitive} onChange={toggleTimePicker} />}
              />
              <FormHelperText>Toggle on if message needs to be sent at a specific time of day</FormHelperText>
            </React.Fragment>
          )
        } */}

        {
          state.type === 'reply' && (
            <TextField
              select
              label="Trigger Event"
              margin="dense"
              value={state.event}
              onChange={e => setState({...state, event: e.target.value})}
              helperText="What should trigger this reply to be sent out"
              disabled
              required
            >
              {
                events
                  .filter(e => (props.replies || []).filter(r => r.event === e.value && e.value !== state.event).length === 0)
                  .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
              }
            </TextField>
          )
        }

        <br />

        {
          state.type === 'reply' && (
            <FormControlLabel
              control={<Checkbox checked={state.excludePrepend} onChange={(e) => setState({...state, excludePrepend: e.target.checked})} name="excludePrepend" />}
              label="Exclude prepended message"
            />
          )
        }

        {
          state.timeSensitive && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {/* <KeyboardTimePicker margin="dense" label="Time of day" value={state.time} onChange={time => setState({...state, time})} /> */}
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={state.time}
                onChange={time => setState({...state, time})}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </MuiPickersUtilsProvider>
          )
        }

        {
          state.prepend && (
            <React.Fragment>
              <br /><br />
              <Typography color="textSecondary" gutterBottom>
                Preview
              </Typography>
              <Card style={{boxShadow: 'none', backgroundColor: '#ececec'}}>
                <CardContent>
                  <Typography variant="body2" component="p">
                    {!state.excludePrepend && state.prepend} {state.body}
                  </Typography>
                </CardContent>
              </Card>
            </React.Fragment>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={onAdd} color="primary" disabled={isInvalidForm()}>{editMode ? 'Save' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  )
}