import React from 'react'
import { Button, Typography, Dialog, DialogTitle, DialogContent, TextField, DialogActions, CircularProgress, FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import {useParams} from 'react-router-dom'
import Participant from '../services/participant'
import {Alert, AlertTitle} from '@material-ui/lab'

export default function AcceptParticipation (props) {
  const {id} = useParams()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [invite, setInvite] = React.useState(null)
  const [organization, setOrganization] = React.useState(null)
  const [state, setState] = React.useState({surveyLink: null, success: false, agreeToResearch: false, agreeToTerms: false})

  // console.log('AcceptParticipation.init', id, useParams())

  const submit = function () {
    setError(null)
    console.log('submit form', {...state, token: id})
    Participant.register({...state, token: id})
      .then(res => {
        // console.log('AcceptInvite.User.register.then', res)
        setState({...state, success: true, surveyLink: res.surveyLink})
        setLoading(false)
      })
      .catch(err => {
        console.error('submit.err', err)
        setError({message: 'Failed to register, please try again. If the issue persists, please contact an administrator.'})
        setLoading(false)
      })
  }

  const onChange = function (e) {
    setError(null)
    setState({...state, [e.target.name]: e.target.checked})
  }
  
  React.useEffect(() => {
    Participant.getInvite({token: id})
      .then(res => {
        // console.log('got invite', res)
        setInvite(res.invite)
        setOrganization(res.organization)
        setLoading(false)
      })
      .catch(err => {
        console.log('getInvite.failed', err)
        setLoading(false)
      })
  }, [id])

  // console.log('AcceptInvite')

  if (loading) {
    return (
      <Dialog open={true} aria-labelledby="Accept Invitation">
        <DialogTitle>Loading Invite</DialogTitle>
        <DialogContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (state.success) {
    return (
      <Dialog open={true} aria-labelledby="Accept Invitation">
        <DialogTitle>You're registered!</DialogTitle>
        <DialogContent>
          You are now registered, you can now participate in the survey by clicking the button below.
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={onClose} color="secondary">Cancel</Button> */}
          <Button href={state.surveyLink} color="primary">Go To Survey</Button>
        </DialogActions>
      </Dialog>
    )
  }

  if (!id || !invite) {
    return (
      <Dialog open={true} aria-labelledby="Accept Invitation">
        <DialogTitle>Corrupt or Expired Invite</DialogTitle>
        <DialogContent>
          The invite is no longer valid, please have an invite re-sent. If the issue persists, report to an administrator.
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div>
      <Dialog open={true} aria-labelledby="Accept Invitation">
        <DialogTitle>You've been invited to {organization.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Complete the form below to register your account.</Typography>

          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            value={invite.email}
            fullWidth
            disabled
          />

          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={state.agreeToTerms} onChange={onChange} name="agreeToTerms" />}
                label="I agree to the Terms and Conditions."
              />
              <FormControlLabel
                control={<Checkbox checked={state.agreeToResearch} onChange={onChange} name="agreeToResearch" />}
                label="I agree for my results to be used in a research capacity. Learn more about what your results will be used for here."
              />
            </FormGroup>
            {/* <FormHelperText>Be careful</FormHelperText> */}
          </FormControl>

          {
            error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error.message}
              </Alert>
            )
          }
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={onClose} color="secondary">Cancel</Button> */}
          <Button onClick={submit} color="primary" disabled={!state.agreeToResearch || !state.agreeToTerms}>Register</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}