import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Typography, Dialog, DialogTitle, DialogContent, TextField, DialogActions, CircularProgress, Container, Paper } from '@material-ui/core'
import {CheckCircle, Error} from '@material-ui/icons'
import {Alert, AlertTitle} from '@material-ui/lab'
import {makeStyles} from '@material-ui/core/styles'
import {useParams} from 'react-router-dom'
import Program from '../../services/program'
import Subscriber from '../../services/subscriber'
import logo from '../../logo.png'

const useStyles = makeStyles({
  root: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  selected: {
    backgroundColor: 'rgb(56, 52, 150)'
  },
  heading: {
    padding: '24px',
    flexGrow: 1,
    marginTop: '1rem',
  },
  page: {
    padding: '24px',
    flexGrow: 1,
    marginTop: '1rem',
    backgroundColor: '#fafafa'
  }
})

export default function PublicProgramSubscribe () {
  const classes = useStyles()
  const {id} = useParams()
  const [program, setProgram] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [email, setEmail] = React.useState('')
  const [submitting, setSubmitting] = React.useState(false)
  const [completed, setCompleted] = React.useState(false)
  const [programNotFound, setProgramNotFound] = React.useState(false)
  const [result, setResult] = React.useState()

  useEffect(() => {
    if (!program && loading) {
      Program.getPublicProgram(id)
        .then(res => {
          console.log('getPublicProgram.then', res)
          setProgram(res.program)
          setLoading(false)
        })
        .catch(err => {
          console.log('getPUblicProgram.failed', err)
          setProgramNotFound(true)
          setLoading(false)
        })
    }
  }, [id, loading, program])

  const subscribe = async () => {
    // call verifyEmail
    setSubmitting(true)
    Subscriber.subscribeToPublicProgram({email, programId: id})
      .then(res => {
        setSubmitting(false)
        console.log('subscribe.then', res)
        setResult(res)
        setCompleted(true)
      })
      .catch(err => {
        setSubmitting(false)
        let message = 'Failed to subscribe, please try again or contact an administrator'
        if (err && err.errors && err.errors[0]) {
          switch (err.errors[0].code) {
            case 'error_invalid_missing_field': {
              message = 'Email is a required field, please input a valid email address'
              break
            }
            case 'error_already_subscribed':
              message = 'You have already subscribed to this program.'
              break
            default: {
              message = 'Failed to subscribe, please try again or contact an administrator'
              break
            }
          }
        }
        setError({message})
        console.log('subscribe.error', err)
      })
  }

  if (loading) {
    return (
      <Dialog open={true} aria-labelledby="Loading Program">
        <DialogTitle>Loading Program</DialogTitle>
        <DialogContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (programNotFound) {
    return <div>
      <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication" fullWidth>
        <DialogTitle></DialogTitle>
        <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} />
        <DialogContent style={{textAlign: 'center'}}>
          <br />
          <Typography variant={'h5'}>Program not found!</Typography>
          <br />
          <Typography>Program does not exist or may no longer be available.</Typography>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  }

  return (
    <div>
      {
        completed ? (
          <div>
            <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication" fullWidth>
              <DialogTitle>Subscribe to {program.name}</DialogTitle>
              <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} />
              <DialogContent style={{textAlign: 'center'}}>
                <CheckCircle color={'primary'} />
                <br />
                {
                  result.status === 'verify_email' ?
                  (
                    <React.Fragment>
                      <Typography variant={'h6'}>Please verify your email</Typography>
                      <Typography>You should receive a verification email shortly, please click the link in the email to confirm and subscribe.</Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography variant={'h6'}>Success! You have been subscribed to {program.name}</Typography>
                      <Typography>You should start receiving program messages at the assigned interval. You can now safely close this window.</Typography>
                    </React.Fragment>
                  )
                }
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
        ) : null
      }
      <Container classes={{root: classes.heading}}>
        <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '200px'}} />
        <Typography variant={'h6'} style={{fontWeight: 'bold'}} color='primary'>Building resilience and inspiring hope in individuals and communities globally.</Typography>
      </Container>
      <Container classes={{root: classes.page}}>
        <Grid spacing={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h3'}>About {program.name}</Typography>

              <Paper elevation={0} style={{height: '300px', marginTop: '1rem', overflow: 'hidden', background: 'none'}}>
                {
                  program.imageSrc ? (
                    <img alt="About the program" src={program.imageSrc} style={{maxWidth: '100%'}} />
                  ) : null
                }
              </Paper>

              {
                program.description ? (
                  <div dangerouslySetInnerHTML={{__html: program.description}}></div>
                ) : null
              }

              <Typography>
                To learn more about the program and it's sponsors, visit our <a href="https://www.resiliencenhope.org/programs" target="_blank">Programs Page</a>.
              </Typography>

              <div style={{maxWidth: 'calc(60%)', marginTop: '2rem'}}>
                <div style={{width: '100%'}}>
                  <Typography style={{fontWeight: 'bold'}} variant='body1'>
                    To subscribe, please confirm your email address below:
                  </Typography>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Email Address (e.x. joe@gmail.com)"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    fullWidth
                    required
                  />
                  <Button style={{float: 'right', display: 'block'}} onClick={subscribe} color="primary" disabled={submitting}>Subscribe</Button>
                  <div style={{display: 'block', margin: '15px'}}>
                  {
                    error && (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error.message}
                      </Alert>
                    )
                  }
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}