import axios from 'axios'
import Auth from './auth'

const API_URL = process.env.REACT_APP_API

let self = {}

self.subscribeToPublicProgram = function (params) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/publicSubscribe`, params)
      .then(res => resolve(res.data))
      .catch(e => reject(e.response.data))
  })
}

self.verifyEmailAddress = function (params) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/confirmEmailVerification`, params)
      .then(res => resolve(res.data))
      .catch(e => reject(e.response.data))
  })
}

self.unsubscribe = function (params) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/unsubscribe`, params)
      .then(res => resolve(res.data))
      .catch(e => reject(e.response.data))
  })
}

self.adminUseUnsubscribe = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/adminUnsubscribe`, params, {headers: { Authorization: `Bearer ${token}`}})
            .then(res => resolve(res.data))
            .catch(reject)
      })
      .catch(reject)
  })
}

self.adminUseSubscribe = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/adminSubscribe`, params, {headers: { Authorization: `Bearer ${token}`}})
            .then(res => resolve(res.data))
            .catch(reject)
      })
      .catch(reject)
  })
}

self.subscribe = function (params) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/subscribe`, params)
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

export default self