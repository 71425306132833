import { useContext } from 'react'
import { AppStateContext } from './AppStateContext'
import Organization from './organization'

const useAppState = () => {
  const [state, setState] = useContext(AppStateContext)

  function setOrganization(id) {
    return Organization.set(id)
      .then(() => {
        setState(state => ({...state, organization: Organization.get()}))
      })
  }

  function unSetOrganization() {
    Organization.unset()
      .then(() => {
        setState(state => ({...state, organization: null}))
      })
  }

  return {
    setOrganization,
    unSetOrganization,
    organization: state.organization
  }
};

export default useAppState