import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import useAppState from '../../services/useAppState'
import {makeStyles} from '@material-ui/core/styles'
import Notify from '../Notify'
import SurveyForm from './SurveyForm'
import {useHistory} from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  selected: {
    backgroundColor: 'rgb(56, 52, 150)'
  }
})

export default function MakeSurvey () {
  const classes = useStyles()
  const history = useHistory()
  const {organization} = useAppState()
  const [notify, setNotify] = React.useState({variant: null, message: null, show: false})

  const onNotifyClose = () => {
    console.log('close notify')
    setNotify({variant: null, message: null, show: false})
  }

  const exitMakeSurvey = () => {
    history.push('/surveys')
  }

  return (
    <div>
      <Grid spacing={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>Create a Survey</Typography>
            
            <SurveyForm organizationId={organization.id} onDone={exitMakeSurvey} />
          </Grid>
        </Grid>
      </Grid>
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
    </div>
  )
}