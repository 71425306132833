import 'date-fns'
import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button, Typography} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import PhoneNumber from './PhoneNumber'
import Email from './Email'
import SubscribersList from './SubscribersList'
import Organization from '../services/organization'

export default function InviteSubscriber (props) {
  const {communicationMethod, countryCode} = props
  const [state, setState] = React.useState({subscribers: [], phone: ''})
  const [error, setError] = React.useState(null)

  const subExists = function (sub, type) {
    const subscribers = state.subscribers
    const identifier = {
      sms: 'phone',
      email: 'email'
    }[type]

    return subscribers.filter(findSub => sub[identifier] === findSub[identifier])
  }

  const addSub = function (res) {
    let subscribers = state.subscribers

    setError(null)

    const exist = subExists(res, communicationMethod)

    if (exist.length) {
      return setError({message: `A subscriber with this ${communicationMethod === 'sms' ? 'number' : 'email'} already exists in the invite list`})
    }

    subscribers.push(res)

    setState({...state, subscribers})
  }

  const onRemove = function (i) {
    setError(null)
    let subscribers = [...state.subscribers]

    subscribers.splice(i, 1)

    setState({...state, subscribers})
  }

  const onAdd = function () {
    const {subscribers} = state

    props.onClose({success: true, subscribers})
  }
  
  const isInvalidForm = function () {
    return !(state.subscribers.length)
  }

  const onClose = function () {
    props.onClose({})
  }

  return (
    <Dialog open={props.open} aria-labelledby="Invite Subscriber(s)">
      <DialogTitle>Invite Subscriber(s)</DialogTitle>
      <DialogContent>
        {
          communicationMethod === 'sms' && (
            <DialogContentText>
                Enter the details below for the subscriber you wish to invite. Once submitted, we will send a invite SMS message to confirm.
            </DialogContentText>
          )
        }

        {
          communicationMethod === 'email' && (
            <DialogContentText>
                Enter the details below for the subscriber you wish to invite. Once submitted, the subscriber will automatically start receiving emails.
            </DialogContentText>
          )
        }

        {communicationMethod === 'sms' && <DialogContentText>The Phone number must be a valid number in <strong>{Organization.supportedCountries[countryCode]}</strong>.</DialogContentText>}

        {communicationMethod === 'sms' && <PhoneNumber countryCode={countryCode} onAdd={addSub} />}

        {communicationMethod === 'email' && <Email onAdd={addSub} />}

        <br />
        <br />

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }

        <br />

        <Typography variant={'h5'}>Subscribers ({state.subscribers.length})</Typography>
        <SubscribersList subscribers={state.subscribers} onRemove={onRemove} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={onAdd} color="primary" disabled={isInvalidForm()}>Invite</Button>
      </DialogActions>
    </Dialog>
  )
}