import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button } from "@material-ui/core";
import Survey from '../../services/survey'
import {Alert, AlertTitle} from '@material-ui/lab'

export default function PublishSurveyModal (props) {
  const [survey, setSurvey] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [saving, setSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    Survey
      .get({id: props.id, organizationId: props.organizationId})
      .then(surveyResult => {
        console.log('surveyResult', surveyResult)
        setSurvey(surveyResult)
        setLoading(false)
      })
  }, [props.id, props.organizationId])

  const onConfirm = function () {
    const {id} = survey

    setError(null)
    setSaving(true)

    Survey.publish({id, organizationId: props.organizationId})
      .then(() => {
        setSaving(false)
        props.onClose({success: true, surveyName: survey.name})
      })
      .catch(err => {
        setSaving(false)
        setError({message: 'Failed to publish survey, please try again. If the issue persists, please contact an administrator.'})
        console.log('PublishSurvey.onConfirm.failed', err)
      })
  }

  const onClose = function () {
    props.onClose({})
  }

  if (loading) {
    return null
  }

  return (
    <Dialog open={props.open} aria-labelledby="Edit User">
      <DialogTitle>Are you sure you want to publish the survey, {survey.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Publishing is an irreversible action. Once published, you cannot delete or make any further changes to the survey.
        </DialogContentText>

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading || saving}>Cancel</Button>
        <Button onClick={onConfirm} color="primary" disabled={loading || saving}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}