import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import ProgramForm from './ProgramForm'
import Notify from './Notify'
import {useHistory, useLocation} from 'react-router-dom'
import useAppState from '../services/useAppState'
import Auth from '../services/auth'
export default function MakeProgram () {
  const [notify, setNotify] = React.useState({show: false})
  const history = useHistory()
  const {search} = useLocation()
  const {organization} = useAppState()
  const query = new URLSearchParams(search)
  const communication_method = query.get('communication_method') || null

  const onCreate = function (res) {
    setNotify({show: true, variant: 'success', message: `Successfully created Program.`})
    onClose()
  }

  const onClose = function () {
    const append = Auth.isRole('owner') ? organization.id : ''
    history.replace(`/dashboard/${append}`)
  }

  const onNotifyClose = function () {

  }

  if (!organization) {
    return null
  }

  const countryCode = organization.countryCode || 'CA'

  return (
    <div>
      <Grid spacing={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>Create a Program</Typography>
            <ProgramForm onCreate={onCreate} onClose={onClose} organizationId={organization.id} countryCode={countryCode} communicationMethod={communication_method} communicationMethods={organization.communication_methods} />
          </Grid>
        </Grid>
      </Grid>
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
    </div>
  )
}