import Auth from './auth'
import axios from 'axios'
import firebase from './firebase'

const API_URL = process.env.REACT_APP_API
const EVENTS_LIST = ['initial', 'month1', 'month3', 'month6', 'month9', 'month12']

let self = {}

self.create = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/createSurvey`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.update = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/updateSurvey`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.get = function (params) {
  const {organizationId, unlinked, id} = params

  console.log('Surveys.get', organizationId, id, unlinked)

  let ref = firebase
    .database()
    .ref(`/surveys/${organizationId}`)

  if (id) {
    ref = firebase
      .database()
      .ref(`/surveys/${organizationId}/${id}`)
  }

  // if only to show unlinked surveys, then this flag should be true and will filter out surveys that already have a programId assigned
  if (unlinked) {
    ref = ref.orderByChild('programId').equalTo(null)
  }
  
  return ref
    .once('value')
    .then(snap => {
      
      const res = id ? snap.val() : (Object.values(snap.val() || {}))

      console.log('survey.get.result', res)

      return res
    })
}

self.publish = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/publishSurvey`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.delete = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/deleteSurvey`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.participateInSurvey = function (id) {
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/participateInSurvey`, {id})
      .then(res => resolve(res.data))
      .catch(e => reject(e.response.data))
  })
}

self.submitAnswers = function (params) {
  const {answers, token} = params
  // should contain the token and the answers
  return new Promise((resolve, reject) => {
    axios.post(`${API_URL}/submitAnswersToSurvey`, {token, answers})
      .then(res => resolve(res.data))
      .catch(e => reject(e.response.data))
  })
}
// specifically to deconstruct surveyAnswers nodeKey because it is stored as `{event}_{participantId}`
const deconstructNodeKey = (nodeKey) => {
	const parts = nodeKey.split('_')
  // eventId is always at the start
  const eventId = parts[0]
  // remove the eventId
  parts.shift()
  // rejoin the rest with underscores - because participantId's can contain _'s and so we need to rejoin it (e.x. initial_-N4nk_2IdAKRVWB4dDDa)
  const participantId = [...parts].join('_')
  return {eventId, participantId}
}

const makeHeadersByEvents = function (survey, prepends) {
  // TODO: these events should be stored in the survey - incase in future we introduce events customizations
	const events = EVENTS_LIST
  const headerRows = []
  const questionsByEvents = {
    initial: {},
    month1: {}, 
    month3: {},
    month6: {},
    month9: {},
    month12: {}
  }
  const headersByEvents = {
    initial: [],
    month1: [], 
    month3: [],
    month6: [],
    month9: [],
    month12: []
  }

  for (const event of events) {
    // prepend each item for each event headers
    if (prepends) {
      prepends.forEach(header => {
        headersByEvents[event].push(`"${header}"`.replace(',', ''))
        headerRows.push(`"${header}"`.replace(',', ''))
      })
    }

  	survey.questions.en.forEach(sectionArr => {
    	const section = sectionArr[0].section
    	
      if (section === 'demographic' && event !== 'initial') {
      	return
      }
      
      if (section === 'satisfaction' && event === 'initial') {
      	return
      }
      
      questionsByEvents[event][section] = sectionArr.reduce((acc, quest) => {
        headersByEvents[event].push(`"${quest.question}"`.replace(',', ''))
        headerRows.push(`"${quest.question}"`.replace(',', ''))
	      acc[quest.id] = quest.question
        return acc
      }, {})
    })
  }
  
  return {questionsByEvents, headerRows, headersByEvents}
}


self.generateAllAnswersReport = function (survey, participantAnswers) {
  console.log('generateAllAnswersReport', survey, participantAnswers)
  const sections = []

  // show demographic answers first - if they are part of the survey
  if (survey.demographic) {
    sections.push('demographic')
  }

  // push code for each assessment to sections
  survey.assessment.forEach(item => {
    if (item.active) {
      sections.push(item.code)
    }
  })

  // if survey has satisfaction questions then include those last
  if (survey.satisfaction) {
    sections.push('satisfaction')
  }

  // all answers are stored in keys as {event}_{participantId}
  const keys = Object.keys(participantAnswers)
  let headers = ['participant_id', 'event', 'timestamp']
  let rows = []
  let groupedByParticipantRows = {}

  for (const nodeKey of keys) {
    const {eventId, participantId} = deconstructNodeKey(nodeKey)
    const answers = participantAnswers[nodeKey]
    const formattedDate = new Intl.DateTimeFormat('en-US', {dateStyle: 'full', timeStyle: 'long'}).format(new Date(answers.timestamp))
    let row = [participantId, eventId, `"${formattedDate}"`]

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i]

      // only initial survey have demographic questions
      if (section === 'demographic' && eventId !== 'initial') {
        continue;
      }

      // initial survey does not have satisfaction questions
      if (section === 'satisfaction' && eventId === 'initial') {
        continue;
      }

      survey.questions.en[i]
        .forEach(question => {
          let answer = answers.answers[section].answers[question.id]

          if (!answer) {
            row.push('')
            return
          }
          
          // if it has options, then must be mapped because its the answerId that is stored
          if (question.options) {
            // multianswers allow for multiple comma separated answerId's - if more than one answer exists we must map all values
            if (answer.split(',').length > 1) {
              const answerValues = answer.split(',').map(ansId => {
                if (!ansId) {
                  return null
                }
                const ans = question.options.find(opt => opt.id === ansId)
                return ans.value
              }).join(',')

              answer = `"${answerValues}"`
            } else {
              const answerOption = question.options.find(opt => opt.id === answer)
              answer = isNaN(answerOption.score) ? `"${answerOption.value.replace(',', '')}"` : answerOption.score
            }
          } else {
            answer = `"${answer}"`
          }

          headers.push(`"${question.question}"`.replace(',', ''))

          row.push(answer)
        })
    }

    if (!groupedByParticipantRows[participantId]) {
      groupedByParticipantRows[participantId] = {}
    }

    groupedByParticipantRows[participantId][eventId] = row.join(',')
  }

  // add headers as the first element in array
  // and duplicate for max length of grouped results
  const {headersByEvents, headerRows} = makeHeadersByEvents(survey, ['Participant ID', 'Event', 'Timestamp'])

  for (const participantId in groupedByParticipantRows) {
    const pRow = groupedByParticipantRows[participantId]

    for (const eventId of EVENTS_LIST) {
      const _headers = headersByEvents[eventId]

      // if values for an event are missing, fill with empties
      if (!pRow[eventId]) {
        pRow[eventId] = _headers.map(h => {
          if (h === '"Participant ID"') {
            return `"${participantId}"`
          } else if (h === '"Event"') {
            return `"${eventId}"`
          }
          return '""'
        })
      }
    }

    rows = [
      ...rows,
      // merge the grouped results into a single row - so that it is just a list of repeating surveys on a single row per participant
      EVENTS_LIST.map(evt => pRow[evt]).join(',')
    ]
  }
  
  rows.unshift(headerRows)

  // join each row with new line
  rows = rows.join('\n')

  return rows
}

self.stats = function (params) {
  const {startDate: startDateStr, endDate: endDateStr, surveyId, organizationId} = params
  // const {surveyId, organizationId} = params

  let startDate = new Date(startDateStr)
  startDate = startDate.getTime()
  let endDate = (new Date(endDateStr)).getTime()

  console.log('stats.get.organizationId', organizationId)
  console.log('stats.get.surveyId', surveyId)
  console.log('stats.get.dates', startDate, endDate)
  
  return firebase
    .database()
    .ref(`/surveyAnswers/${organizationId}/${surveyId}`)
    .orderByChild('timestamp')
    .startAt(startDate)
    .endAt(endDate)
    .once('value')
    .then(snap => {
      const result = snap.val()

      console.log('the result', result)
      return result
    })
}

export default self