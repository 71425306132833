import 'date-fns'
import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogContentText ,DialogTitle, Button, Typography} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import ParticipantsList from './ParticipantsList'
import {useDropzone} from 'react-dropzone'
import importer from '../services/importer'
import Participant from '../services/participant'

// Email address matcher.
var matcher = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/**
 * Loosely validate an email address.
 *
 * @param {string} string
 * @return {boolean}
 */
function isEmail(string) {
  return matcher.test(string);
}

export default function ImportParticipants (props) {
  const [state, setState] = React.useState({participants: []})
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [warning, setWarning] = React.useState(null)

  React.useEffect(() => {
    
  }, [])

  const exists = function (participant) {
    const participants = state.participants

    return participants.filter(findPart => participant.email === findPart.email).length > 0 || props.participants.filter(findPart => participant.email === findPart.email).length > 0
  }

  const onImport = function (data) {
    console.log('ImportParticipants.onImport', data)
    importer.participants({type: 'csv', content: data.blob})
      .then(res => {
        let hasInvalidCount = 0
        let newParticipants = res.data
          .filter(sub => sub.email && !exists(sub))
          .map(participant => {
            const isValid = isEmail(participant.email) //phoneParser.parse(sub.phone)
            
            if (!isValid) {
              hasInvalidCount++
            }
            
            return {valid: isValid, email: participant.email}
          })

        // console.log('invalids', hasInvalidCount, newParticipants)

        if (!newParticipants.length) {
          return setError({message: 'No participants found with email addresses.'})
        }

        if (newParticipants.length && newParticipants.length === hasInvalidCount) {
          return setError({message: 'All participants in the file are already registered or have invalid email addresses.'})
        }

        if (hasInvalidCount) {
          setWarning({message: 'Some of the participants in this file have invalid email address, only valid participants will be imported.'})
        }

        const participants = [].concat(state.participants, newParticipants)
        // console.log('ImportSubscribers.onImport', newParticipants)

        setState({...state, participants})
      })
  }

  const onDrop = function (files) {
    // console.log('file dropped', files)
    
    if (files && files.length) {
      let file = files[0]

      const reader = new FileReader()

      reader.onload = function (e) {
        return onImport({
          name: file.name,
          blob: e.target.result,
          type: file.type,
          size: file.size
        })
      }

      reader.readAsBinaryString(files[0])
    }
  }

  const onRemove = function (i) {
    setError(null)
    let participants = [...state.participants]

    participants.splice(i, 1)

    setState({...state, participants})
  }

  // const onAdd = function () {
  //   const {participants} = state

  //   props.onClose({success: true, participants: participants.filter(sub => sub.valid)})
  // }
  
  const isInvalidForm = function () {
    return !(state.participants.length)
  }

  const onClose = function () {
    props.onClose({})
  }

  const invite = function () {
    const {participants} = state
    let loading = {}

    console.log('invite.participants', participants)

    const reqs = participants
      .filter(participant => !participant.id && participant.valid)
      .map(participant => {
        loading[participant.email] = true
        participant.status = 'import'
        return Participant.invite({participants: [participant], organizationId: props.organizationId})
          .then(res => {
            console.log('InviteParticipants.invite.then', res)
            setLoading({...loading, [participant.email]: false})
            const participantIndex = state.participants.findIndex(p => p.email === participant.email)
            participants.splice(participantIndex, 1, {...participant, id: res.participants[0].id, status: 'done'})
            setState({participants})
          })
          .catch(err => {
            console.log('InviteParticipants.invite.fail', err)
            setLoading({...loading, [participant.email]: false})
            const participantIndex = state.participants.findIndex(p => p.email === participant.email)
            participants.splice(participantIndex, 1, {...participant, status: 'fail'})
            setState({participants})
          })
      })

    setLoading(loading)

    Promise.all(reqs)
      .finally(() => {
        setLoading({})
      })
  }

  const isLoading = function () {
    console.log('isLoading', loading, 'test', !!Object.keys(loading || {}).filter(k => !!k).length)
    return !!Object.keys(loading || {}).filter(k => !!k).length
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Dialog open={props.open} aria-labelledby="Invite Participant(s)">
      <DialogTitle>Invite Participants(s)</DialogTitle>
      <DialogContent>
        <DialogContentText>
            Drag and drop a csv file with the participants you wish to invite. Once submitted, we will send an invite email to complete their registration.
        </DialogContentText>

        <br />
        <br />

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </div>

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }

        {
          warning && (
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              {warning.message}
            </Alert>
          )
        }

        <br />

        <Typography variant={'h5'}>Participants ({state.participants.length})</Typography>
        <ParticipantsList participants={state.participants} onRemove={onRemove} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={isLoading()}>Close</Button>
        <Button onClick={invite} color="primary" disabled={isInvalidForm() || loading}>Import</Button>
      </DialogActions>
    </Dialog>
  )
}