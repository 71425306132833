import React, { useEffect } from 'react'
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core'
import {CheckCircle} from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'
import {useParams} from 'react-router-dom'
import Subscriber from '../../services/subscriber'
import logo from '../../logo.png'

const useStyles = makeStyles({
  root: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  selected: {
    backgroundColor: 'rgb(56, 52, 150)'
  }
})

export default function PublicProgramSubscribe () {
  const classes = useStyles()
  const {token} = useParams()
  const [loading, setLoading] = React.useState(true)
  const [failed, setFailed] = React.useState(false)
  const [completed, setCompleted] = React.useState(false)
  const [errorCode, setErrorCode] = React.useState(null)

  const errorCodeMap = {
    'error_unexpected_error': {
      'heading': 'Something went wrong!',
      'body': 'Please try clicking the link again, if the issue persists please contact an administrator.'
    },
    'error_invalid_missing_verification_token': {
      'heading': 'Expired or invalid request',
      'body': 'Please try subscribing again, the request has expired or no longer available.'
    }
  }

  const getErrorHeading = (code = 'error_unexpected_error') => {
    const err = errorCodeMap[code] ? errorCodeMap[code] : errorCodeMap['error_unexpected_error']
    
    return err.heading
  }

  const getErrorBody = (code = 'error_unexpected_error') => {
    const err = errorCodeMap[code] ? errorCodeMap[code] : errorCodeMap['error_unexpected_error']
    
    return err.body
  }

  useEffect(() => {
    if (loading && !completed && !failed) {
      Subscriber.verifyEmailAddress({token})
        .then(res => {
          console.log('verifyEmail.then', res)
          setCompleted(true)
          setLoading(false)
        })
        .catch(err => {
          console.log('verifyEmail.failed', err)
          if (err && err.errors && err.errors.length) {
            setErrorCode(err.errors[0].code)
          } else {
            // some unknown error
            setErrorCode('error_unexpected_error')
          }
          setFailed(true)
          setLoading(false)
        })
    }
  }, [token, completed, failed, loading])

  if (loading) {
    return (
      <Dialog open={true} aria-labelledby="Completing verification">
        <DialogTitle>Completing Verification</DialogTitle>
        <DialogContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (failed) {
    return <div>
      <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication" fullWidth>
        <DialogTitle>Verification failed</DialogTitle>
        <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} />
        <DialogContent style={{textAlign: 'center'}}>
          <br />
          <Typography variant={'h5'}>{getErrorHeading(errorCode)}</Typography>
          <br />
          <Typography>{getErrorBody(errorCode)}</Typography>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  }

  if (completed) {
    return <div>
      <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication" fullWidth>
        <DialogTitle></DialogTitle>
        <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} />
        <DialogContent style={{textAlign: 'center'}}>
          <CheckCircle color={'primary'} />
          <br />
          <Typography variant={'h6'}>Email Verification Completed!</Typography>
          <Typography>You have been verified and subscribed to the requested Program.</Typography>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  }
}