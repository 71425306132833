import {useEffect, useState} from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Button,
} from '@material-ui/core'
import RichText from '../RichText'

export default function MakeReminderEmail (props) {
  const [emailBody, setEmailBody] = useState('')
  const [smsBody, setSmsBody] = useState('')

  const onClose = () => {
    props.onClose()
  }
  const onAdd = () => {
    console.log('MakeReminderEmail.onAdd', {success: true, emailBody, smsBody})
    props.onClose({success: true, emailBody, smsBody})
  }

  useEffect(() => {
    if (props.emailBody) {
      setEmailBody(props.emailBody)
    }

    if (props.smsBody) {
      setSmsBody(props.smsBody)
    }
  }, [])

  return (
    <Dialog open={props.open} maxWidth={'xl'} aria-labelledby="Custom Reminder message">
      <DialogTitle>Customize your reminder message for surveys</DialogTitle>
      <DialogContent style={{minHeight: 300}}>
        <DialogContentText>
            {'Create a custom reminder message for Participants, these are scheduled to go out when Participants do not complete their surveys on time.'}
        </DialogContentText>

        <TextField
            autoFocus
            margin="dense"
            label="SMS Body"
            type="text"
            onChange={e => setSmsBody(e.target.value)}
            value={smsBody}
            fullWidth
          />

        <Typography>Email Body</Typography>
        <RichText className="richSurveyEmailEditor" body={emailBody} onChange={(newBody) => setEmailBody(newBody)} />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Close</Button>
        <Button onClick={onAdd} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}