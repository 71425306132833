import ReactQuill from 'react-quill'

export default function RichText (props) {
  let toolbar = []

  if (!props.disabled) {
    toolbar = [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }]
    ]
  }

  const onChange = (content, delta, source, editor) => {
    // because content is wrapped in HTML, emptying the field will still result in content with empty tags
    // so we check the text content using the editor's getText handler
    // if no text exists return empty string, else return the content
    
    // trim because getText returns space when empty
    const rawText = editor.getText()
    const noText = rawText.trim().length <= 0

    const result = noText ? '' : content
    
    if (props.onChange) {
      props.onChange(result, rawText)
    }
  }

  return <ReactQuill
    className={props.className ? props.className : 'richTextEditor'}
    theme="snow"
    value={props.body}
    modules={{toolbar}}
    onChange={onChange}
    readOnly={props.disabled}
    required={props.required}
  />

}