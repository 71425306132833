import React from 'react'
import {IconButton, Table, TableFooter, TablePagination, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core'
import User from '../services/user'
import Features from './Features'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import useAppState from '../services/useAppState'

export default function Users (props) {
  const {organization} = useAppState()
  const [count, setCount] = React.useState(0)
  const [query, setQuery] = React.useState({organizationId: null, limit: 10, page: 0, name: ''})
  const [users, setUsers] = React.useState([])
  const [updating, setUpdating] = React.useState(false)

  const onChangeQuery = function (newQuery) {
    setQuery(newQuery)
  }

  const onEditUser = function (id) {
    if (props.onEditUser) {
      props.onEditUser(id)
    }
  }

  const onDeleteUser = function (id) {
    if (props.onDeleteUser) {
      props.onDeleteUser(id)
    }
  }

  if (props.updateTable) {
    if (!updating) {
      setUpdating(true)
      props.onUpdating()
      User.get({organizationId: organization.id})
        .then(users => {
          setUsers(users)
          setCount(users.length)
          setUpdating(false)
        })
        .catch(err => {
          setUsers([])
          setCount([].length)
          setUpdating(false)
        })
    }
  }

  React.useEffect(() => {
    // console.log('Users.init', organization)
    if (!organization || !organization.id) {return}

    setQuery(q => ({...q, organizationId: organization.id}))

    User.get({organizationId: organization.id})
      .then(users => {
        setUsers(users)
        setCount(users.length)
      })
  }, [organization])

  const headers = [
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'Status',
      key: 'status'
    },
    {
      label: 'Role',
      key: 'role'
    },
    {
      label: 'Features',
      key: 'features'
    }
  ]

  return (
    <Table aria-label="Table of Users">
      <TableHead>
        <TableRow>
          <TableCell>
            <TextField label="Search by name" value={query.name} onChange={(e) => onChangeQuery({...query, name: e.target.value})} fullWidth />
          </TableCell>
        </TableRow>
        <TableRow>
          {headers.map(header => <TableCell key={header.key}>{header.label}</TableCell>)}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {
          users
            .filter(u => {
              if (query.name) {
                return u.name.toLowerCase().includes(query.name.toLowerCase())
              }
              return u
            })
            .slice(query.page * query.limit, ((query.page + 1) * query.limit))
            .map(row => (
              <TableRow key={row.id}>
                {
                  headers.map(header => {
                    switch (header.key) {
                      case 'features':
                        return (<TableCell key={`${row.id}_${header.key}`}><Features owner={row.role === 'owner'} features={row[header.key]} readOnly={true} /></TableCell>)
                      default:
                        return (<TableCell key={`${row.id}_${header.key}`}>{row[header.key]}</TableCell>)
                    }
                  })
                }
                <TableCell>
                  <IconButton onClick={() => onEditUser(row.id)} aria-label="Edit User">
                    <EditIcon />
                  </IconButton>

                  <IconButton onClick={() => onDeleteUser(row.id)} aria-label="Edit User">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10]}
            colSpan={4}
            count={count}
            rowsPerPage={query.limit}
            page={query.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={(e, page) => onChangeQuery({...query, page})}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}