import React from 'react'
import OrganizationsTable from './OrganizationsTable'
import { Grid } from '@material-ui/core'
import useAppState from '../services/useAppState'
import {useHistory} from 'react-router-dom'

export default function Organization (props) {
  const {setOrganization} = useAppState()
  const history = useHistory()

  const onSelectOrg = function (id) {
    setOrganization(id)
      .then(() => {
        setTimeout(function () {
          history.push(`/dashboard/${id}`)
        })
      })
  }

  return (
    <div>
      <Grid spacing={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <OrganizationsTable onSelect={onSelectOrg} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}