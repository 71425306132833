import React from 'react'
import { Typography, Paper, TableContainer, makeStyles, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Button } from "@material-ui/core";
import useAppState from '../../services/useAppState'
import {useParams} from 'react-router-dom'
import Survey from '../../services/survey'
import {DatePicker} from '@material-ui/pickers'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  optionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem'
  }
});

const SimpleCard = function (props) {
  const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.header}
        </Typography>
        <Typography variant="h5" component="h2">
          {/* be{bull}nev{bull}o{bull}lent */}
          {props.body}
        </Typography>
        <Typography variant="body2" component="p" color="textSecondary">
          {props.footer}
        </Typography>
        {/* <Typography variant="body2" component="p">
          {props.footer}
        </Typography> */}
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}

const SurveyResultsTable = function (props) {
  console.log('SurveyResultsTable', props, props.headers)
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Table of participation in survey">
        <TableHead>
          <TableRow>
            {
              props.headers.map(header => <TableCell>{prettifyHeadings(header)}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.keys(props.stats || {})
              .map((k, i) => {
                const data = props.stats[k]
                console.log('stats.each', k, i, props.stats[k])
                return <TableRow key={`${k}_${i}`}>
                  {
                    props.headers.map(key => {
                      switch (key) {
                        case 'event':
                          return <TableCell>{data[key]}</TableCell>
                        case 'date':
                          const date = new Date(data.timestamp)
                          return <TableCell>{date.toLocaleDateString('en-US', {month: 'long', year: 'numeric', day: 'numeric'})}</TableCell>
                        case 'satisfaction':
                        case 'demographic':
                          return <TableCell>{data.answers[key] ? 'true' : 'false'}</TableCell>
                        default:
                          return <TableCell>{data.answers[key].score}</TableCell>
                      }
                    })
                  }
                </TableRow>
              })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const prettifyHeadings = function (h) {
  switch (h) {
    case 'event':
      return 'Event'
    case 'demographic':
      return 'Demographic'
    case 'brs':
      return 'Resilience scale'
    case 'gad7':
      return 'Anxiety scale'
    case 'phq9':
      return 'Depression scale'
    case 'whofwbi98':
      return 'Wellness scale'
    case 'mbies':
      return 'Burnout scale'
    case 'edbd':
      return 'ED-BD scale'
    case 'pss':
      return 'Stress scale'
    case 'date':
      return 'Date'
    case 'subscribed':
      return 'New Subscribers'
    case 'unsubscribed':
      return 'Unsubscribers'
    case 'completed':
      return 'Completed Program'
    default:
      return h
  }
}

function generateOctetStream (data) {
  return `data:application/octet-stream,${encodeURIComponent(data)}`
}

function triggerDownload (filename, url, newWindow) {
  return new Promise((resolve) => {
    const anchor = document.createElement('a');

    anchor.setAttribute('href', url);
    anchor.setAttribute('download', filename);
    anchor.style.display = 'none';
    anchor.textContent = filename;

    if (newWindow) {
      anchor.setAttribute('target', '_blank');
    }

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    resolve();

  });
}

// function mapColumnLabels (key) {
//   const labels = {
    
//   }
  
//   return labels[key] || key
// }

export default function SurveyReport () {
  const classes = useStyles();
  const {organization} = useAppState()
  const {id} = useParams()
  const [program, setProgram] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [startDate, setStartDate] = React.useState(new Date())
  const [endDate, setEndDate] = React.useState(new Date())
  const [allTimeStatsLoading, setAllTimeStatsLoading] = React.useState(false)
  const [headers, setHeaders] = React.useState([])
  
  React.useEffect(() => {
    Survey.get({id, organizationId: organization.id})
      .then(data => {
        Survey.stats({organizationId: organization.id, surveyId: id, startDate: startDate.toISOString(), endDate: endDate.toISOString()})
          .then(stats => {
            console.log('stats', stats)
            data.stats = stats || {}
            const keys = Object.keys(data.stats)
            
            if (keys.length) {
              const row = data.stats[keys[0]]

              setHeaders(['event', 'date', ...Object.keys(row.answers)])
            } else {
              setHeaders([])
            }

            setProgram(data)
            setLoading(false)
          })
      })
  }, [id, organization.id, startDate, endDate])

  const onDateChange = (key, d) => {
    const params = {organizationId: organization.id, surveyId: id}
    
    switch (key) {
      case 'startDate':
        setStartDate(d)
        params.startDate = d.toISOString()
        params.endDate = endDate.toISOString()
        break
      case 'endDate':
        setEndDate(d)
        params.endDate = d.toISOString()
        params.startDate = startDate.toISOString()
        break
      default:
        return null
    }
    
    setLoading(true)
    
    Survey.stats(params)
      .then(stats => {
        let data = program
        data.stats = stats || {}
        setProgram(data)
        setLoading(false)
      })
  }

  const simplifiedDate = (dateObj) => {
    const d = new Date(dateObj)

    return `${d.getMonth() + 1}_${d.getDate()}_${d.getFullYear()}`
  }

  const downloadAllTimeReport = () => {
    setAllTimeStatsLoading(true)

    let report = Survey.generateAllAnswersReport(program, program.stats)

    triggerDownload(`${program.name}_${simplifiedDate(startDate)}_${simplifiedDate(endDate)}_report.csv`, generateOctetStream(report))

    setAllTimeStatsLoading(false)
  }

  if (!organization) {
    return null
  }

  if (!program) {
    return null
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {program.name} Report
      </Typography>

      <div className={classes.optionsWrapper}>
        <Typography component="h6" variant="h6" gutterBottom>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              openTo="year"
              views={['year', 'month', 'date']}
              label="Start Date"
              helperText="Start from year selection"
              value={startDate}
              maxDate={endDate}
              onChange={(d) => onDateChange('startDate', d)}
            />
          </MuiPickersUtilsProvider>
        </Typography>

        <Typography component="h6" variant="h6" gutterBottom>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              openTo="year"
              views={['year', 'month', 'date']}
              label="End Date"
              helperText="Start from year selection"
              minDate={startDate}
              value={endDate}
              onChange={(d) => onDateChange('endDate', d)}
            />
          </MuiPickersUtilsProvider>
        </Typography>

        <Button onClick={downloadAllTimeReport} color="primary" disabled={allTimeStatsLoading}>
          Download Report
        </Button>
      </div>

      <br />

      {
        loading ? <div>Loading report...</div> : null
      }

      {
        program && Object.keys(program.stats || {}).length ?
          <SurveyResultsTable headers={headers} stats={program.stats} /> : 
          <div>No data found for the date range specified for the selected Survey. Try another date range or a different survey.</div>
      }
    </React.Fragment>
  )
}