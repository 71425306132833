import React, { useEffect } from 'react'
import {
  Grid,
  Stepper,
  RadioGroup,
  Step,
  StepLabel,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
  FormHelperText
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useParams} from 'react-router-dom'
import Survey from '../../services/survey'
import logo from '../../logo.png'
import AssessmentScore from '../Surveys/AssessmentScore'
import AskQuestion from '../Surveys/AskQuestion'

const useStyles = makeStyles({
  root: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  selected: {
    backgroundColor: 'rgb(56, 52, 150)'
  },
  formControlRoot: {
    display: 'flex',
    '@media (max-width:600px)': {
      margin: '0',
    },
    margin: '2em',
    padding: '2em'
  },
  assessmentWrapper: {
    '@media (max-width:600px)': {
      margin: '0',
    },
    margin: '4em'
  },
  slider: {
    marginTop: '2em'
  },
  completedGridRoot: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    padding: '4rem',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default function TakeSurvey () {
  const {id} = useParams()
  const classes = useStyles()
  const [loading, setLoading] = React.useState(true)
  const [loadError, setLoadError] = React.useState(null)
  const [questions, setQuestions] = React.useState([])
  const [steps, setSteps] = React.useState([])
  const [step, setStep] = React.useState(0)
  const [answers, setAnswers] = React.useState({0: {}})
  const [completed, setCompleted] = React.useState(false)
  const [intro, setIntro] = React.useState(true)
  const [survey, setSurvey] = React.useState(null)
  const [shouldPromptScore, setShouldPromptScore] = React.useState(false)
  const [promptScore, setPromptScore] = React.useState(false)
  const [showScore, setShowScore] = React.useState(false)
  const [form, setForm] = React.useState({})
  const [submitting, setSubmitting] = React.useState(false)
  const [globalError, setGlobalError] = React.useState(null)

  const headingsByCode = {
    'edbd': 'Ectodermal Dysplasias-Burden of Disease',
    'pss': 'Perceived Stress',
    'aceq': 'Adverse Childhood Experience',
    'whofwbi98': 'Assessment for Wellness',
    'brs': 'Assessment for Resilience',
    'dsm5': 'Assessment for PTSD',
    'gad7': 'Assessment for Anxiety',
    'phqa': 'Assessment for Depression for Adolescents',
    'phq9': 'Assessment for Depression',
    'mhl': 'Mental Health Literacy',
    'scared': 'Screen for Child Anxiety Related Disorders',
    'mbihss': 'Assessment for Burnout in Healthcare Professionals',
    'mbihssmp': 'Assessment for Burnout in Medical Professionals',
    'mbies': 'Assessment for Burnout in Educators',
    'mbigs': 'Assessment for Burnout in General Population',
    'mbigss': 'Assessment for Burnout in Students'
  }

  const instructionsByCode = {
    'pss': 'The questions in this scale ask about your feelings and thoughts during the last month. In each case, you will be asked to indicate how often you felt or thought a certain way. Although some of the questions are similar, there are differences between them and you should treat each one as a separate question. The best approach is to answer fairly quickly. That is, don’t try to count up the number of times you felt a particular way; rather indicate the alternative that seems like a reasonable estimate.',
    'edbd': 'For each question, check the answer which best applies to you/your family.',
    'whofwbi98': 'Please indicate for each of the five statements which are closest to how you have been feeling over the last two weeks. Notice that higher numbers mean better well-being.',
    'brs': 'For each of the following statements, please indicate your level of agreement',
    'dsm5': 'Below are a list of problems and complaints people sometimes have in response to stressful life experiences. Please read each one carefully and choose the option that best matches how much you have been bothered by that problem in the last month.',
    'gad7': 'Over the last 2 weeks, how often have you been bothered by the following problems?',
    'phq9': 'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
    'phqa': 'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
    'mhl': 'The purpose of these questions is to gain an understanding of your knowledge of various aspects to do with mental health. When responding, we are interested in your degree of knowledge',
    'scared': 'Below is a list of sentences that describe how people feel. Read each phrase and decide if it is “Not True or Hardly Ever True” or “Somewhat True or Sometimes True” or “Very True or Often True” for you. Then for each sentence, fill in one circle that corresponds to the response that seems to describe you for the last 3 months.',
    'mbihss': 'Below are 22 statements of job-related feelings. Please read each statement carefully and decide if you ever feel this way about your job. If you have never had this feeling, select the option “never” in the space after the statement. If you have had this feeling, indicate how often you feel it by choosing the option that best describes how frequently you feel that way.',
    'mbies': 'Below are 22 statements of job-related feelings. Please read each statement carefully and decide if you ever feel this way about your job. If you have never had this feeling, select the option “never” in the space after the statement. If you have had this feeling, indicate how often you feel it by choosing the option that best describes how frequently you feel that way.',
    'mbihssmp': 'Below are 22 statements of job-related feelings. Please read each statement carefully and decide if you ever feel this way about your job. If you have never had this feeling, select the option “never” in the space after the statement. If you have had this feeling, indicate how often you feel it by choosing the option that best describes how frequently you feel that way.',
    'mbigs': 'Below are 16 statements of job-related feelings. Please read each statement carefully and decide if you ever feel this way about your job. If you have never had this feeling, select the option “never” in the space after the statement. If you have had this feeling, indicate how often you feel it by choosing the option that best describes how frequently you feel that way.',
    'mbigss': 'Below are 16 statements of university-related feelings. Please read each statement carefully and decide if you ever feel this way about your job. If you have never had this feeling, select the option “never” in the space after the statement. If you have had this feeling, indicate how often you feel it by choosing the option that best describes how frequently you feel that way.'
  }

  const stepHeadingsByCode = {
    'phq9': 'Depression scale',
    'gad7': 'Anxiety scale',
    'brs': 'Resilience scale',
    'whofwbi98': 'Wellness scale',
    'mbies': 'Burnout scale'
  }

  useEffect(() => {
    Survey.participateInSurvey(id)
      .then(result => {
        setSteps(result.survey.steps)
        setQuestions(result.survey.questions)
        setSurvey(result.survey)
        resetForm(result.survey.questions)
        setShouldPromptScore(!['demographic', 'satisfaction'].includes(result.survey.steps[0]))

        // console.log('shouldPromptScore', shouldPromptScore)

        setTimeout(() => {
          setLoading(false)
        }, 1500)
      })
      .catch(err => {
        console.log('TakeSurvey.participate.failed', err)
        // handle error for user
        let errorCode = 'error_unexpected_error'

        if (err && err.errors && err.errors.length) {
          errorCode = err.errors[0].code || 'error_unexpected_error'
        }

        console.log('errorCode', errorCode)

        setLoadError(errorCode)
        setLoading(false)
      })
  }, [id])

  const resetForm = (assessments) => {
    const newForm = {valid: false}
    
    assessments.forEach((section, index) => {
      newForm[index] = {}
      section.forEach(question => {
        newForm[index][question.id] = {invalid: false, dirty: false}
      })
    })

    setForm(newForm)
  }

  const onChangeAnswers = (questionId, newAnswer) => {
    console.log('onChangeAnswers', questionId, newAnswer)
    setGlobalError(null)
    // console.log('onChangeAnswers', questionId, newAnswer, questions)
    const newAnswers = {...answers}

    if (!newAnswers[step]) {
      newAnswers[step] = {}
    }

    const newForm = setFormFieldDirty(step, questionId)

    newAnswers[step][questionId] = newAnswer

    setAnswers(newAnswers)
    // console.log('onChangeAnswers.newAnswers', newAnswers)
    validateSectionForm(step, newForm, newAnswers)
  }

  const validateSectionForm = (section, newForm, newAnswers) => {
    let updatedForm = newForm ? {...newForm} : {...form}
    let updatedAnswers = newAnswers ? {...newAnswers} : {...answers}
    let valid = true

    questions[section].forEach(question => {
      const {id: qId, type} = question
      let sectionAnswers = updatedAnswers[section] || {}

      switch (type) {
        case 'number':
        case 'linearscale': {
          if (isNaN(sectionAnswers[qId])) {
            updatedForm[section][qId].invalid = true
            valid = false
          } else {
            updatedForm[section][qId].invalid = false
          }
          break
        }
        case 'shortanswer':
        case 'paragraph':
        case 'dropdown':
        case 'multianswer':
        case 'multichoice': {
          if (sectionAnswers[qId]) {
            updatedForm[section][qId].invalid = false
          } else {
            updatedForm[section][qId].invalid = true
            valid = false
          }
          break
        }
        default:
          break
      }
    })

    setForm(updatedForm)
    return valid
  }

  const setFormFieldDirty = (section, questionId) => {
    const newForm = {...form}

    if (!newForm[section][questionId]) {
      newForm[questionId] = {}
    }

    newForm[section][questionId].dirty = true
    setForm(newForm)
    
    return newForm
  }

  const startSurvey = () => {
    setIntro(false)
  }

  // set entire form to dirty state
  const setFormDirty = (section) => {
    const newForm = {...form}
    
    questions[section].forEach(quest => {
      if (!newForm[section][quest.id]) {
        newForm[section][quest.id] = {}
      }

      newForm[section][quest.id].dirty = true
    })

    console.log('setFormDirty', newForm)
    setForm(newForm)
    return newForm
  }

  const nextStep = () => {
    // set form to dirty
    let newForm = setFormDirty(step, answers)

    // validate form to ensure all fields are valid
    const validSection = validateSectionForm(step, newForm)

    // if invalid do not proceed
    if (!validSection) {
      setGlobalError('Some of the required fields have not been filled, please fill in the missing questions and try again')
      return
    }

    console.log('nextStep', shouldPromptScore, promptScore, showScore)

    // if its not one of these, then its assessment and should ask to show score
    if (shouldPromptScore && !promptScore) {
      setPromptScore(true)
      return
    }

    setPromptScore(false)
    setShowScore(false)

    let newStep = step + 1

    if (newStep > steps.length) {
      newStep = steps.length
    }

    let _shouldPromptScore = !['demographic', 'satisfaction'].includes(steps[newStep])

    if (!answers[newStep]) {
      const newAnswers = {...answers}

      newAnswers[newStep] = {}
      setAnswers(newAnswers)
    }

    console.log('newStep', newStep, 'shouldPromptScore', shouldPromptScore, 'promptScore', promptScore, steps[newStep])

    setStep(newStep)
    setShouldPromptScore(_shouldPromptScore)
  }

  const prevStep = () => {
    let newStep = step - 1

    if (newStep < 0) {
      newStep = 0
    }

    setStep(newStep)
  }

  const lastStep = () => {
    return step === (steps.length - 1)
  }

  const formCompleted = () => {
    let completed = true

    // we use steps as its logic is handled from API for which questions to show for this event
    for (const index in steps) {
      const sectionQuestions = questions[index]
      
      sectionQuestions.forEach(question => {
        // console.log('sectionQuestions', question, answers[index])
        if (!answers[index][question.id]) {
          // console.log('not completed', question, index)
          completed = false
        }
      })
      
      if (!completed) {
        break
      }
    }

    return completed && promptScore
  }

  const submit = () => {
    let newForm = setFormDirty(step, answers)

    // const validSection = validateSectionForm(step, newForm)
    const invalid = steps.filter((_step, stepIndex) => !validateSectionForm(stepIndex, newForm)).length > 0

    // console.log('invalid', invalid, steps.filter((_step, stepIndex) => !validateSectionForm(stepIndex, newForm)))

    if (invalid) {
      return
    }

    if (submitting) {
      return
    }

    setSubmitting(true)

    Survey.submitAnswers({token: id, answers})
      .then(res => {
        // console.log('submit.success')
        setSubmitting(false)
        setCompleted(true)
      })
      .catch(err => {
        setSubmitting(false)
        console.error('submit.answers.failed', err)
      })
  }

  if (loading) {
    return <div style={{textAlign: 'center'}}>
      <Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{position: 'fixed', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto', marginBottom: '2em'}} />

            <CircularProgress color="secondary" style={{marginBottom: '2em'}} />

            <Typography variant="h5">Please wait while we load the survey...</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  }

  if (loadError) {
    return (
      <div>
        <Grid classes={{root: classes.completedGridRoot}}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{position: 'fixed', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
              <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto', marginBottom: '2em'}} />

              {
                [ 'error_bad_token', 'error_unexpected_error' ].includes(loadError) && (
                  <Typography variant="h5">Failed to load survey</Typography>
                )
              }

              {
                loadError === 'error_unexpected_error' && (
                  <Typography variant="body1">Something went wrong, please try refreshing the page. If the issue persists, please contact an administrator.</Typography>
                )
              }

              {
                loadError === 'error_bad_token' && (
                  <Typography variant="body1">Invalid or corrupt Survey invite. If this issue persists, please contact an administrator.</Typography>
                )
              }

              {
                loadError === 'error_survey_already_completed' && (
                  <Typography variant="body1">You have already completed this survey. You may now close this window.</Typography>
                )
              }

            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  if (completed) {
    return (
      <div>
        <Grid classes={{root: classes.completedGridRoot}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto', marginBottom: '2em'}} />

              <Typography variant="h3">Thank you for completing this survey</Typography>

              <Typography variant="body">You have completed the survey and your results have been submitted, you can now safely close this window.</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  if (intro) {
    return (
      <div style={{position: 'absolute', top: '0', bottom: '0', left: 0, right: 0}}>
        <Grid>
          <Grid container>
            <Grid item xs={12} style={{margin: '1em'}}>
              <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto', marginBottom: '2em'}} />
              
              <div>
                {survey.intro && (
                  <div dangerouslySetInnerHTML={{__html: survey.intro}}></div>
                )}

                <Button variant="contained" onClick={startSurvey}>Start Survey</Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div style={{position: 'absolute', top: '0', bottom: '0', left: 0, right: 0}}>
      <Grid>
        <Grid container>
          <Grid item xs={12}>
            <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '2em'}} />
            <Stepper activeStep={step} alternativeLabel style={{background: 'none'}}>
              {
                steps.map((code) => (
                  <Step key={code}>
                    <StepLabel>{(stepHeadingsByCode[code] || code).toUpperCase()}</StepLabel>
                  </Step>
                ))
              }
            </Stepper>

            {
              promptScore ? (
                <div>
                  <FormControl className={classes.formControlRoot}>
                    <Typography variant="h6">
                      Do you want to know your results for these questions?
                    </Typography>
                    <RadioGroup name="showScore" value={showScore} onChange={(e) => setShowScore(e.target.value !== 'false')}>
                      <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                      <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                  </FormControl>

                  {
                    showScore ? (
                      <div className={classes.assessmentWrapper}>
                        <AssessmentScore code={steps[step]} answers={answers[step]} questions={questions[step]} />
                      </div>
                    ) : null
                  }

                  <div style={{margin: '2em', textAlign: 'right'}}>
                    {
                      !lastStep() && <Button variant="contained" onClick={nextStep}>Next</Button>
                    }
                    {
                      lastStep() && formCompleted() && <Button variant="contained" onClick={submit}>Submit</Button>
                    }
                  </div>
                </div>
              ) : null
            }
            
            {
              !promptScore ? (
                <Grid>
                  {headingsByCode[steps[step]] && (
                    <div style={{margin: '2em'}}>
                      <Typography variant="h4">{headingsByCode[steps[step]]}</Typography>
                    </div>
                  )}

                  {
                    instructionsByCode[steps[step]] ? (
                      <div style={{margin: '2em'}}>
                        <Typography variant="h6">{instructionsByCode[steps[step]]}</Typography>
                      </div>
                    ) : null
                  }

                  {
                    (questions[step] || [])
                      .map((question, index) => {
                        if (!question) {return null}
                        return <div key={`qWrapper_${question.id}`}>
                          <AskQuestion form={form[step][question.id] || {}} question={question} answer={answers[step][question.id]} onAnswer={(newAnswer) => onChangeAnswers(question.id, newAnswer)} />
                        </div>
                      })
                  }

                  <div style={{margin: '2em', textAlign: 'right'}}>
                    {
                      step > 0 && <Button variant="contained" onClick={prevStep}>Previous</Button>
                    }
                    {
                      (!lastStep() || lastStep() && (shouldPromptScore && !promptScore)) && <Button variant="contained" onClick={nextStep}>Next</Button>
                    }
                    {
                      lastStep() && !shouldPromptScore && <Button variant="contained" onClick={submit} disabled={submitting}>Submit</Button>
                    }
                  </div>

                  <div style={{margin: '2em'}}>
                    {
                      globalError && <FormHelperText style={{textAlign: 'right'}} error={true}>{globalError}</FormHelperText>
                    }
                  </div>
                </Grid>
              ) : null
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}