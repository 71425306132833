import React from 'react'
import { Dialog, DialogActions, DialogContent ,DialogTitle, Button, TextField, Select, FormControl, Typography } from "@material-ui/core";
import User from '../services/user'
import {Alert, AlertTitle} from '@material-ui/lab'
import Features from './Features'
export default function EditUser (props) {
  const [user, setUser] = React.useState({
    email: '', role: 'user', organizationId: null
  })
  const [featuresChanged, setFeaturesChanged] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [saving, setSaving] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    // console.log('EditUser.init', props.id)
    User.get({id: props.id})
      .then(user => {
        // console.log('got users', user)
        setUser(user)
        setLoading(false)
      })
  }, [props.id])

  const onSave = function () {
    const {id, name, role} = user

    let params = {id, name, role}

    if (featuresChanged) {
      params.features = user.features
    }

    setError(null)
    setSaving(true)

    User.update(params)
      .then(() => {
        setSaving(false)
        props.onClose({success: true, email: user.email})
      })
      .catch(err => {
        setSaving(false)
        setError({message: 'Failed to save changes, please try again. If the issue persists, please contact an administrator.'})
        // console.log('EditUser.onSave.failed', err)
      })
  }

  const onClose = function () {
    props.onClose({})
  }

  const onChangeFeatures = function (features) {
    setUser({...user, features})
    setFeaturesChanged(true)
  }

  if (loading) {
    return null
  }

  return (
    <Dialog open={props.open} aria-labelledby="Edit User" maxWidth="md">
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <TextField
            autoFocus
            name="name"
            margin="dense"
            label="Name"
            type="text"
            value={user.name}
            onChange={e => setUser({...user, [e.target.name]: e.target.value})}
            helperText="e.x. Bob"
            fullWidth
          />

        <TextField
          autoFocus
          name="email"
          margin="dense"
          label="Email"
          type="email"
          value={user.email}
          disabled
          helperText="e.x. name@domain.com"
          fullWidth
        />

        <FormControl fullWidth>
          <Select
            native
            value={user.role}
            inputProps={{
              name: 'role',
            }}
            onChange={e => setUser({...user, [e.target.name]: e.target.value})}
            fullWidth
            disabled={user.role === 'owner'}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
            {user.role === 'owner' && <option value="owner">Owner</option>}
          </Select>
        </FormControl>

        <br />
        <br />

        <Typography variant='h6'>Permissions</Typography>
        <Features features={user.features} owner={user.role === 'owner'} disabled={user.role === 'owner'} onChange={onChangeFeatures} />

      {
        error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        )
      }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading || saving}>Cancel</Button>
        <Button onClick={onSave} color="primary" disabled={loading || saving}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}