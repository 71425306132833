import {useState, useEffect} from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Typography
} from '@material-ui/core'
import RichText from '../RichText'

export default function MakeSurveyWelcomeEmail (props) {
  const [smsBody, setSmsBody] = useState('')
  const [emailBody, setEmailBody] = useState('')

  const onClose = () => {
    props.onClose()
  }
  const onAdd = () => {
    props.onClose({success: true, emailBody, smsBody})
  }

  useEffect(() => {
    console.log('MakeSurveyWelcomeEmail.useEffect', props)
    if (props.smsBody) {
      setSmsBody(props.smsBody)
    }
    
    if (props.emailBody) {
      setEmailBody(props.emailBody)
    }
  }, [])

  return (
    <Dialog open={props.open} maxWidth={'xl'} aria-labelledby="Custom Welcome message">
      <DialogTitle>Customize your welcome message</DialogTitle>
      <DialogContent style={{minHeight: 300}}>
        <DialogContentText>
            {'Create a custom welcome message for when Survey links are sent to participants. This email will be sent for each survey interval.'}
        </DialogContentText>

        <TextField
            autoFocus
            margin="dense"
            label="SMS Body"
            type="text"
            value={smsBody}
            onChange={(e) => setSmsBody(e.target.value)}
            fullWidth
          />

        <br />
        <br />

        <Typography variant="body1">Email Body</Typography>
        <RichText className="richSurveyEmailEditor" body={emailBody} onChange={(newBody) => setEmailBody(newBody)} />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Close</Button>
        <Button onClick={onAdd} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  )
}