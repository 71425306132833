import React from 'react'
import {
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  NativeSelect,
  Input,
  Slider,
  FormHelperText,
  FormGroup,
  Checkbox
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  selected: {
    backgroundColor: 'rgb(56, 52, 150)'
  },
  formControlRoot: {
    display: 'flex',
    '@media (max-width:600px)': {
      margin: '0'
    },
    margin: '2em',
    padding: '2em'
  },
  slider: {
    marginTop: '2em'
  },
  completedGridRoot: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    padding: '4rem',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default function AskQuestion (props) {
  const classes = useStyles()
  const {form = {}} = props
  const selections = {}

  const handleMultianswerChange = (option) => {
    let newAnswer = props.answer || ''
    let answersArray = newAnswer ? newAnswer.split(',') : []
    let index = answersArray.indexOf(option.id)

    if (index !== -1) {
      answersArray.splice(index, 1)
    } else {
      answersArray.push(option.id)
    }

    console.log('handleMultianswerChange', option.id, answersArray)

    newAnswer = answersArray.join(',')

    // newAnswer = `${newAnswer || ''},${option.id}`
    // const id = e.target.name
    // // have to create a string with comma separated id's that are selected - e.x. ('78cd8c95-54fb-46a1-8c4f-1a1fb6c80994,6600a03e-ce4c-4ea8-8e92-c503baf38b47')
    // let answerStr = props.answer || ''
    // let answer = answerStr ? answerStr.split(',') : []
    // let index = answer.indexOf(id)

    // console.log('index', index)
    
    // if (index !== -1) {
    //   answer.splice(index, 1)
    // } else {
    //   answer.push(id)
    // }

    console.log('answer', newAnswer)
    updateSelections(newAnswer)
    return props.onAnswer(newAnswer)
  }

  const updateSelections = (answers) => {
    if (props.question.type === 'multianswer') {
      props.question.options.forEach(opt => {
        selections[opt.id] = (answers || '').split(',').includes(opt.id)
      })
    }
  }
  
  return (
    <div>
      {
        props.question.type === 'dropdown' ?
        (
          <FormControl className={classes.formControlRoot} error={form.invalid && form.dirty}>
            <Typography variant="h6" color={form.invalid && form.dirty ? 'error' : 'inherit'}>
              {props.question.question}
            </Typography>
            <NativeSelect
              inputProps={{
                name: props.question.id,
                id: props.question.id,
                required: props.question.required
              }}
              value={props.answer}
              onChange={(e) => props.onAnswer(e.target.value)}
            >
              <option value="">Select one</option>
              {
                props.question.options.map(option => <option key={`${props.question.id}_${option.id}`} value={option.id}>{option.value}</option>)
              }
            </NativeSelect>
            {form.invalid && form.dirty && <FormHelperText error={form.invalid}>is a required field, please select one of the options.</FormHelperText>}
          </FormControl>
        ) : null
      }

      {
        props.question.type === 'number' ?
        (
          <FormControl className={classes.formControlRoot} error={form.invalid && form.dirty}>
            <Typography variant="h6" color={form.invalid && form.dirty ? 'error' : 'inherit'}>
              {props.question.question}
            </Typography>
            <Input id={props.question.id} value={props.answer} required={props.question.required} type="number" variant="standard" onChange={(e) => props.onAnswer(e.target.value)} />
            {form.invalid && <FormHelperText error={form.invalid}>is a required field, valid entry is required.</FormHelperText>}
          </FormControl>
        ) : null
      }

      {
        props.question.type === 'shortanswer' ?
        (
          <FormControl className={classes.formControlRoot} error={form.invalid && form.dirty}>
            <Typography variant="h6" color={form.invalid && form.dirty ? 'error' : 'inherit'}>
              {props.question.question}
            </Typography>
            <Input id={props.question.id} value={props.answer} required={props.question.required} variant="standard" onChange={(e) => props.onAnswer(e.target.value)} />
            {form.invalid && form.dirty && <FormHelperText error={form.invalid}>is a required field, valid entry is required.</FormHelperText>}
          </FormControl>
        ) : null
      }

      {
        props.question.type === 'paragraph' ?
        (
          <FormControl className={classes.formControlRoot} error={form.invalid && form.dirty}>
            <Typography variant="h6" color={form.invalid && form.dirty ? 'error' : 'inherit'}>
              {props.question.question}
            </Typography>
            <Input id={props.question.id} value={props.answer} required={props.question.required} variant="standard" multiline rows={4} onChange={(e) => props.onAnswer(e.target.value)} />
            {form.invalid && form.dirty && <FormHelperText error={form.invalid}>is a required field, valid entry is required.</FormHelperText>}
          </FormControl>
        ) : null
      }

      {
        props.question.type === 'multichoice' ?
        (
          <FormControl className={classes.formControlRoot} error={form.invalid && form.dirty}>
            <Typography variant="h6" color={form.invalid && form.dirty ? 'error' : 'inherit'}>
              {props.question.question}
            </Typography>
            <RadioGroup value={props.answer || ''} onChange={(e) => props.onAnswer(e.target.value)}>
              {
                props.question.options.map(option => <FormControlLabel key={`${props.question.id}_${option.id}`} value={option.id} control={<Radio required={props.question.required} color="primary" />} label={option.value} />)
              }
            </RadioGroup>
            {form.invalid && form.dirty && <FormHelperText error={form.invalid}>is a required field, please select one of the options.</FormHelperText>}
          </FormControl>
        ) : null
      }

      {
        props.question.type === 'multianswer' ?
        (
          <FormControl className={classes.formControlRoot} error={form.invalid && form.dirty} required={props.question.required}>
            <Typography variant="h6" color={form.invalid && form.dirty ? 'error' : 'inherit'}>
              {props.question.question}
            </Typography>
            <FormGroup>
              {
                props.question.options.map(option => {
                  // const isChecked = isMultiAnswerChecked(option)
                  return <FormControlLabel key={`${props.question.id}_${option.id}`} value={option.id} control={<Checkbox color="primary" name={option.id} checked={selections[option.id]} onChange={e => handleMultianswerChange(option)} />} label={option.value} />
                })
              }
            </FormGroup>
            {form.invalid && form.dirty && <FormHelperText error={form.invalid}>is a required field, please select one of the options.</FormHelperText>}
          </FormControl>
        ) : null
      }

      {
        props.question.type === 'linearscale' ?
        (
          <FormControl className={classes.formControlRoot} error={form.invalid && form.dirty}>
            <Typography variant="h6" color={form.invalid && form.dirty ? 'error' : 'inherit'}>
              {props.question.question}
            </Typography>
            <Slider
              className={classes.slider}
              value={props.answer || 0}
              step={1}
              valueLabelDisplay="on"
              onChange={(e, newAnswer) => props.onAnswer(newAnswer)}
              marks={[{value: props.question.scale[0], label: props.question.minScaleLabel}, {value: props.question.scale[1], label: props.question.maxScaleLabel}]}
              min={props.question.scale[0]}
              max={props.question.scale[1]}
            />
            {form.invalid && form.dirty && <FormHelperText error={form.invalid}>is a required field, please select using the slider.</FormHelperText>}
          </FormControl>
        ) : null
      }
    </div>
  )
}