import React from 'react'
import { Dialog, DialogContent, DialogContentText, CircularProgress, Typography } from "@material-ui/core";

export default function LoadingModal (props) {

  return (
    <Dialog open={true} aria-labelledby="Loading">
      {/* <DialogTitle>Are you sure you want to publish the survey, {survey.name}?</DialogTitle> */}
      <DialogContent>
        <DialogContentText style={{textAlign: 'center'}}>
          <CircularProgress color="secondary" style={{marginBottom: '2em'}} />
          <Typography>Please wait while we process your request.</Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}