import firebase from './firebase'
import { signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail, getAuth, multiFactor } from 'firebase/auth'
let session = null
let listeners = {}
let self = {}
let loginInProgress = false

function updateSession (snap) {
  session = snap.val()
}

self.loadSession = function (res) {
  // console.log('auth.loadSession', res, firebase.auth().currentUser.getIdToken())
  return firebase.database().ref(`/users/${res.uid}`).once('value')
    .then(snap => {
      // if no val, we have a problem
      session = snap.val()
      listeners.update = firebase.database().ref(`/users/${res.uid}`)
      listeners.update.on('child_changed', updateSession)

      // console.log('userSessionLoaded', session)
      return session
    })
}

self.currentUser = () => {
  let _auth = getAuth(firebase.getInstance())
  // console.log('auth._auth', _auth)

  return _auth.currentUser
}

self.initialize = function (callback) {
  onAuthStateChanged(getAuth(firebase.getInstance()), user => {
    // console.log('auth.initialize', user, loginInProgress)
    let isMFAEnrolled = false

    if (user) {
      const enrolledFactors = multiFactor(user).enrolledFactors

      // console.log('enrolledFactors', enrolledFactors)

      if (enrolledFactors.length) {
        isMFAEnrolled = true
      }
    }

    if (user && !loginInProgress && isMFAEnrolled) {
      if (!session) {
        return self.loadSession(user).then(() => callback(true))
      }
      return callback(true)
    } else {
      return callback(false)
    }    
  })
  // firebase.auth().onAuthStateChanged(user => {
  //   // console.log('onAuthChanged', user)
  //   if (user) {
  //     // console.log('already logged in', user)
  //     // onLoggedIn(user)
  //     if (!session) {
  //       return loadSession(user)
  //         .then(function () {
  //           // console.log('loadSession.then', user)
  //           callback(true)
  //         })
  //     }
  //     callback(true)
  //   }

  //   // console.log('not logged in', user)
  //   callback(false)
  // })
}

self.login = function (user, pass) {
  loginInProgress = true
  return signInWithEmailAndPassword(getAuth(firebase.getInstance()), user, pass)
    .then(res => {
      // console.log('auth.login.success', res)
      // return loadSession(res.user)
      return res.user
    })
    // .catch(err => {
    //   console.log('auth.login.err', err)
    //   throw err
    // })
}

self.logout = function () {
  return signOut(getAuth(firebase.getInstance()))
    .then(function (res) {
      // console.log('auth.logout.then', res)
      if (listeners.update) {
        listeners.update.off('child_changed', updateSession)
        delete listeners.update
        session = null
      }
    })
}

self.resetPassword = function (email) {
  return sendPasswordResetEmail(getAuth(firebase.getInstance()), email)
    .then(res => {
      // console.log('auth.resetPassword.then', res)
      return res
    })
}

self.getIdToken = function () {
  return getAuth(firebase.getInstance()).currentUser.getIdToken()
}

self.get = function (key) {
  return key ? (session || {})[key] : session
}

self.isRole = function (required) {
  if (!session) return false
  if (!Array.isArray(required)) {
    required = [required]
  }

  return required.includes(session.role)
}

self.hasFeature = function (required) {
  if (!session) return false
  if (!Array.isArray(required)) {
    required = [required]
  }

  if (session.role === 'owner') {
    return true
  }

  const features = Object.keys((session.features || {})).filter(f => session.features[f])

  return required.filter(feat => !features.includes(feat)).length === 0
}

export default self