import React from 'react'
import Notify from './Notify'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@material-ui/core";
import Typography from '@material-ui/core/Typography'
import Participant from '../services/participant'
import MakeIcon from '@material-ui/icons/Add'
import useAppState from '../services/useAppState'
import InviteParticipant from './InviteParticipant'
import ImportParticipants from './importParticipants';
import ImportIcon from '@material-ui/icons/ImportContacts'
import DeleteIcon from '@material-ui/icons/Delete'

export default function ParticipantsTable (props) {
  const [participants, setParticipants] = React.useState([])
  const [showInvite, setShowInvite] = React.useState(false)
  const [showImport, setShowImport] = React.useState(false)
  const {organization} = useAppState()
  const [notify, setNotify] = React.useState({show: false})

  const headers = [
    {
      label: 'Email Address',
      key: 'email'
    },
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'Status',
      key: 'registered'
    },
    {
      label: 'Program Subscription(s)',
      key: 'subscriptions'
    }
  ]

  const invite = function () {
    setShowInvite(true)
  }

  const importer = function () {
    setShowImport(true)
  }

  const onInviteClose = function () {
    setShowInvite(false)
    loadParticipants()
  }

  const onNotifyClose = function (a) {
    console.log('onNotifyClose', a)
    setNotify({message: null, show: false})
  }

  const onCloseImport = function () {
    setShowImport(false)
    loadParticipants()
  }

  const formatValue = function (key, value) {
    switch (key) {
      case 'registered':
        return value ? 'Registered' : 'Invite Sent'
      case 'subscriptions':
        return Object.values(value || {}).map(sub => sub.name).join(', ')
      default:
        return value ? value : '-'
    }
  }

  const loadParticipants = function () {
    Participant.get({organizationId: organization.id})
      .then(participants => {
        setParticipants(participants)
      })
  }

  const deleteParticipant = function (participantId) {
    Participant.deleteInvite({organizationId: organization.id, participantId})
      .then(res => {
        setNotify({variant: 'success', message: 'Successfully deleted participant invite', show: true})
        loadParticipants()
      })
      .catch(err => {
        setNotify({variant: 'error', message: 'Failed to delete participant invite - try again or contact an administrator', show: true})
      })
  }

  React.useEffect(() => {
    loadParticipants()
  }, [])
  
  return (
    <React.Fragment>
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
      {showInvite && <InviteParticipant open={showInvite} participants={participants} organizationId={organization.id} orgName={organization.name} onClose={onInviteClose} />}
      {showImport && <ImportParticipants open={showImport} participants={participants} organizationId={organization.id} onClose={onCloseImport} />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Participants
        <IconButton onClick={invite}>
          <MakeIcon />
        </IconButton>
        <IconButton onClick={importer}>
          <ImportIcon />
        </IconButton>
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Table of Participants">
          <TableHead>
            <TableRow>
              {headers.map(header => <TableCell key={header.key}>{header.label}</TableCell>)}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.map(row => (
              <TableRow key={row.id}>
                {headers.map(header => (
                  <TableCell key={`${row.id}_${header.key}`}>{formatValue(header.key, row[header.key])}</TableCell>
                ))}
                <TableCell>
                  <IconButton disabled={row.registered} onClick={() => deleteParticipant(row.id)} aria-label="Delete Participant">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}