import 'date-fns'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {Dialog, DialogActions, DialogContent, DialogContentText, Button, Typography, IconButton} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {Close as CloseIcon} from '@material-ui/icons'
import {Alert, AlertTitle} from '@material-ui/lab'
import Email from './Email'
import ParticipantsList from './ParticipantsList'
import Participant from '../services/participant'

const styles = ((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} disabled>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

export default function InviteSubscriber (props) {
  const [state, setState] = React.useState({participants: []})
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const exists = function (participant) {
    const participants = state.participants

    return participants.filter(findPart => participant.email === findPart.email).length > 0 || props.participants.filter(findPart => participant.email === findPart.email).length > 0
  }

  const add = function (res) {
    let participants = state.participants

    setError(null)

    const exist = exists(res)

    if (exist) {
      return setError({message: `A participant with this email was already invited`})
    }

    participants.push(res)

    setState({...state, participants})
  }

  const onRemove = function (i) {
    setError(null)
    let participants = [...state.participants]

    participants.splice(i, 1)

    setState({...state, participants})
  }

  const invite = function () {
    const {participants} = state
    let loading = {}

    console.log('invite.participants', participants)

    participants
      .filter(participant => !participant.id)
      .forEach(participant => {
        loading[participant.email] = true
        participant.status = 'import'
        Participant.invite({participants: [participant], organizationId: props.organizationId})
          .then(res => {
            console.log('InviteParticipants.invite.then', res)
            const loadingData = {...loading, [participant.email]: false}
            // setLoading(loadingData)
            setIsLoading(Object.values(loadingData).filter(flag => !!flag).length > 0)
            const participantIndex = state.participants.findIndex(p => p.email === participant.email)
            participants.splice(participantIndex, 1, {...participant, id: res.participants[0].id, status: 'done'})
            setState({participants})
          })
          .catch(err => {
            console.log('InviteParticipants.invite.fail', err)
            const loadingData = {...loading, [participant.email]: false}
            // setLoading(loadingData)
            setIsLoading(Object.values(loadingData).filter(flag => !!flag).length > 0)
            const participantIndex = state.participants.findIndex(p => p.email === participant.email)
            participants.splice(participantIndex, 1, {...participant, status: 'fail'})
            setState({participants})
          })
      })

    setIsLoading(true)
    // setLoading(loading)
  }
  
  const isInvalidForm = function () {
    // check that there are uninvited participants (filter out one's with id's because those are already invited)
    return !(state.participants.filter(p => !p.id).length)
  }

  const onClose = function () {
    props.onClose({})
  }

  return (
    <Dialog open={props.open} aria-labelledby="Invite Participant(s)">
      <DialogTitle>
        Invite Participant(s)
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
            Enter the details below for the participant you wish to invite.
        </DialogContentText>

        <Email onAdd={add} />

        <br />
        <br />

        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }

        <br />

        <Typography variant={'h5'}>Participants ({state.participants.length})</Typography>
        <ParticipantsList participants={state.participants} onRemove={onRemove} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={isLoading}>Close</Button>
        <Button onClick={invite} color="primary" disabled={isInvalidForm() || isLoading}>Invite</Button>
      </DialogActions>
    </Dialog>
  )
}