import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth'
import { FormControl,InputBase, InputAdornment, FormHelperText, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import {makeStyles} from '@material-ui/core/styles'
import { useState, useEffect, Fragment } from 'react'
import PhoneParser from '../../services/phoneParser'
import CountryCodePicker from '../CountryCodePicker'

const useStyles = makeStyles((theme) => ({
  view: {
    marginTop: '5rem',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  viewPublic: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0//theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  dialog: {
    minWidth: '350px'
  }
}))

const EnrollMultiFactor = ({verifier, onDone, auth}) => {
  const classes = useStyles()
  const [error, setError] = useState(null)
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
  const [step, setStep] = useState(1)
  const [verifyId, setVerifyId] = useState(null)
  const [phoneAuthProvider, setPhoneAuthProvider] = useState(null)
  const [mfaSession, setMfaSession] = useState(null)
  const [phoneFieldError, setPhoneFieldError] = useState(null)
  const [countryCode, setCountryCode] = useState({ "country": "Canada", "code": "CA", "dialingCode": "+1" })

  const cancel = () => {
    // console.log('cancel.mfa.enroll')
    onDone(false)
  }

  const verify = () => {
    setError(null)
    const cred = PhoneAuthProvider.credential(verifyId, otp);

    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    multiFactor(auth.currentUser).enroll(multiFactorAssertion, "My personal phone number")
      .then(() => {
        if (onDone) {
          onDone(true)
        }
      })
      .catch(err => {
        // console.log('multiFactor.enroll.failed', err)
        if (err.code === 'auth/invalid-verification-code') {
          setError({message: 'Invalid verification code, check the code or try again.'})
        } else {
          setError({message: 'Something went wrong, please try again. If the issue persists, cancel and try again later.'})
        }
      })
  }

  const submitPhoneNumber = () => {
    setError(null)
    // console.log('submitPhoneNumber', phone, mfaSession, verifier)
    phoneAuthProvider.verifyPhoneNumber({phoneNumber: `${countryCode.dialingCode}${phone}`, session: mfaSession}, verifier)
      .then(verificationId => {
        setVerifyId(verificationId)
        setStep(2)
      })
      .catch(err => {
        console.error('verifyPhoneNumber.catch', err)
        setError({message: 'Submission failed, check the phone number and try again'})
      })
  }

  useEffect(() => {
    multiFactor(auth.currentUser)
      .getSession()
      .then((multiFactorSession) => {
        setMfaSession(multiFactorSession)
        setPhoneAuthProvider(new PhoneAuthProvider(auth))
        // console.log('mutltiFactor.getSession.then', multiFactorSession, phoneAuthProvider)
        // console.log('mutltiFactor.getSession.then', mfaSession, phoneAuthProvider)
      })
      .catch(err => {
        console.error('enrollMfa.getSession.failed', err)
        // failed, exit
        onDone(false)
      })
  }, [])

  const onPhoneChange = (e) => {
    let value = e.target.value
    setPhone(value)

    const res = PhoneParser.parse(value)

    // if that fails, then return error
    if (!res.valid) {
      return setPhoneFieldError({message: 'Invalid number, phone number must follow E.164 standard'})
    } else {
      setPhoneFieldError(null)
    }
  }

  const onChangeCountryCode = (cc) => {
    // console.log('enrollMultiFactor.onChangeCountryCode', cc)
    setCountryCode(cc)
  }

  return (
    <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication">
      <DialogTitle>Multi Factor Authentication Required</DialogTitle>
      {/* <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} /> */}
      <DialogContent>
        <p>As part of new security measures, multi-factor authentication is now required to access the ResilienceNHope portal.</p>

        {
          step === 1 ? (
            <span>
              <p>Please enter the phone number that you wish to use as part of your multi-factor authentication. A one-time verification code will be sent via SMS. <small>(Charges may apply.)</small></p>

              <Fragment>
                <FormControl error={!!phoneFieldError}>
                  <InputBase
                    className={classes.input}
                    placeholder="Phone Number"
                    // startAdornment={<InputAdornment position="start">+1</InputAdornment>}
                    startAdornment={
                      <InputAdornment>
                        <CountryCodePicker onChange={onChangeCountryCode} selected={countryCode} />
                      </InputAdornment>
                    }
                    inputProps={{ 'aria-label': 'Phone Number'}}
                    value={phone}
                    onChange={onPhoneChange}
                  />
                  <FormHelperText>[area code][phone number] e.x. (4031231234) - E.164 Format</FormHelperText>
                  {phoneFieldError && <FormHelperText>{phoneFieldError.message}</FormHelperText>}
                </FormControl>
              </Fragment>
            </span>
          ) : (
            <span>
              <p>To complete the enrollment, You will receive a one-time passcode on your phone. Please enter the passcode below.</p>

              <TextField
                autoFocus
                name="passcode"
                margin="dense"
                label="Passcode"
                type="text"
                value={otp}
                onChange={e => setOtp(e.target.value)}
                helperText="You will receive a 6 digit code on your phone."
                fullWidth
                required
              />
            </span>
          )
        }
        
        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )
        }
      </DialogContent>
      <DialogActions>
        {
          step === 1 ? (
            <span>
              <Button onClick={cancel} color="secondary">Cancel</Button>
              <Button onClick={submitPhoneNumber} color="primary" >Enroll</Button>
            </span>
          ) : (
            <span>
              <Button onClick={cancel} color="secondary">Cancel</Button>
              <Button onClick={verify} color="primary" >Enroll</Button>
            </span>
          )
        }
      </DialogActions>
    </Dialog>
  )
}

export default EnrollMultiFactor