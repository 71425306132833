import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Typography,
  Slider,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add'
import EditableDropdownOption from './EditableDropdownOption'
import {v4 as uuidv4} from 'uuid'

const OptionsInput = function (props) {
  const {options} = props

  const onAdd = () => {
    let newOptions = [...options]

    newOptions.push({index: options.length, value: '', id: uuidv4()})

    props.onChange(newOptions)
  }

  const onDelete = (index) => {
    let currentOptions = [...options]

    currentOptions.splice(index, 1)

    // update option and indexes
    const newOptions = []
    currentOptions.forEach(option => {
      newOptions.push({value: option.value, index: newOptions.length})
    })

    props.onChange(newOptions)
  }

  const onChangeOption = (value, index) => {
    let newOptions = [...options]

    newOptions[index].value = value

    props.onChange(newOptions)
  }

  return (
    <div>
      <Typography>Available options
        <IconButton aria-label="Add an option" onClick={onAdd}>
          <AddIcon />
        </IconButton>
      </Typography>
      
      {
        options.map((option, index) => (
          <EditableDropdownOption
            key={index}
            placeholder={`Option ${index}`}
            value={option.value}
            index={option.index}
            onChange={onChangeOption}
            onDelete={onDelete}
          />
        ) )
      }
    </div>
  )
}

export default function AddField (props) {
  const [field, setField] = React.useState({
    id: uuidv4(),
    type: 'dropdown',
    question: '',
    section: props.section,
    options: [],
    required: false
  })
  const [form, setForm] = React.useState({
    valid: false,
    question: {dirty: false},
    options: {dirty: false},
    scale: {dirty: false}
  })
  const errorMap = {
    question: {
      required: 'A Question is required and must be at least 2 characters in length'
    },
    options: {
      required: 'At least one option is required'
    },
    scale: {
      required: 'Scale is required with min and max'
    }
  }

  const onConfirm = function () {
    const formUpdate = validateForm(field)
    setAllDirty()

    console.log('onConfirm', form, field)

    if (formUpdate.valid) {
      props.onClose({success: true, field})
    }
  }

  const onClose = function () {
    props.onClose({})
  }

  const onOptionsChange = function (newOptions) {
    const newField = {...field}

    newField.options = newOptions
    setField(newField)
    setFormDirty('options', true)
    validateForm(newField)
  }

  const onQuestionChange = function (e) {
    const newField = {...field, [e.target.name]: e.target.value}
    setField(newField)
    setFormDirty('question', true)
    validateForm(newField)
  }

  const onTypeChange = function (e) {
    const newField = {
      id: field.id || uuidv4(),
      type: e.target.value,
      section: props.section,
      question: field.question || '',
      required: field.required || false
    }


    switch (newField.type) {
      case 'multianswer':
      case 'dropdown':
      case 'multichoice':
        newField.options = []
        break
      case 'linearscale':
        newField.scale = [0, 5]
        newField.minScaleLabel = ''
        newField.maxScaleLabel = ''
        break
      default:
        break
    }

    setField(newField)
  }

  const setFormDirty = function (key, state) {
    const newForm = {...form}

    newForm[key].dirty = state

    setForm(newForm)
  }

  const setAllDirty = function () {
    const newForm = {...form}
    
    Object.keys(newForm)
      .forEach(key => {
        if (typeof newForm[key] === 'object') {
          newForm[key].dirty = true
        }
      })

    setForm(newForm)
  }

  const validateForm = function (updatedField) {
    const newForm = {...form}
    let fieldsToValidate = ['question']

    newForm.question.error = null
    newForm.options.error = null
    newForm.scale.error = null

    if (!updatedField.question || updatedField.question.length < 2) {
      newForm.question.error = errorMap.question.required
    }

    if (['dropdown', 'multichoice'].includes(updatedField.type)) {
      fieldsToValidate.push('options')
      if (!updatedField.options.length) {
        newForm.options.error = errorMap.options.required
      }
    }

    if (updatedField.type === 'linearscale') {
      fieldsToValidate.push('scale')
      if (!updatedField.scale) {
        newForm.scale.error = errorMap.scale.required
      }
    }

    newForm.valid = fieldsToValidate.filter(key => {
      return newForm[key].error
    }).length === 0

    console.log('newForm', newForm, updatedField)

    setForm(newForm)
    
    return newForm
  }

  return (
    <Dialog open={props.open} aria-labelledby="Add a field">
      <DialogTitle>Add a field</DialogTitle>
      <DialogContent>
        <FormControl margin='dense'>
          <InputLabel>Field Type</InputLabel>
          <Select
            name='type'
            value={field.type}
            onChange={onTypeChange}
            required
          >
            <MenuItem value="dropdown">Drop Down</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="shortanswer">Short Answer</MenuItem>
            <MenuItem value="paragraph">Paragraph</MenuItem>
            <MenuItem value="multichoice">Multiple Choice</MenuItem>
            <MenuItem value="multianswer">Multiple Choice and Multiple Selection</MenuItem>
            <MenuItem value="linearscale">Linear Scale</MenuItem>
          </Select>
          <FormHelperText>Select the delivery method of this message.</FormHelperText>
        </FormControl>

        <TextField
          autoFocus
          name='question'
          margin='dense'
          label='Question'
          type='text'
          value={field.question}
          onChange={onQuestionChange}
          helperText={(form.question.dirty && form.question.error) || "Ensure your question is straightforward and can be answered with the options you provide"}
          error={Boolean(form.question.dirty && form.question.error)}
          required
        />

        {
          ['dropdown', 'multichoice', 'multianswer'].includes(field.type) && (
            <div>
              <br />
              <br />
              <OptionsInput options={field.options} onChange={onOptionsChange} />
              {form.options.error ? (
                <FormHelperText error>{form.options.error}</FormHelperText>
              ) : null}
            </div>
          )
        }

        {
          field.type === 'linearscale' && (
            <div>
              <br />

              <TextField
                fullWidth
                label={`Display Label for minimum value`}
                value={field.minScaleLabel}
                onChange={(e) => setField({...field, minScaleLabel: e.target.value})}
              />

              <br /><br />

              <TextField
                fullWidth
                label={`Display Label for maximum value`}
                value={field.maxScaleLabel}
                onChange={(e) => setField({...field, maxScaleLabel: e.target.value})}
              />

              <br /><br />
              <Typography id="discrete-slider" gutterBottom>
                Scale Range
              </Typography>
              
              <div style={{marginLeft: '10%', marginRight: '10%'}}>
                <Slider
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  value={field.scale}
                  onChange={(e, newValue) => setField({...field, scale: newValue})}
                  step={1}
                  marks={[{value: field.scale[0], label: field.minScaleLabel}, {value: field.scale[1], label: field.maxScaleLabel}]}
                  min={0}
                  max={10}
                />
              </div>
            </div>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={onConfirm} color="primary">Add</Button>
      </DialogActions>
    </Dialog>
  )
}