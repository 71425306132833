import React, { useEffect } from 'react'
import {
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from '@material-ui/core'
import SurveyUtils, {labelsByCode} from '../../utils/survey'

export default function AssessmentScore (props) {
  const {code, answers = [], questions = []} = props
  const [score, setScore] = React.useState(0)
  
  useEffect(() => {
    let result = SurveyUtils.score(code, answers, questions)
    let newScore = 0

    console.log('AssessmentScore.useEffect.result', code, result)
    const mbiCodes = ['mbihss','mbihssmp','mbies','mbigs','mbigss']

    if (mbiCodes.includes(code)) {
      newScore = result
    } else if (code === 'scared') {
      // scared has multiple scores, so it is written as an object for each type
      newScore = result
    } else {
      newScore = result.score
    }
    
    console.log('AssessmentScore.score', newScore)
    setScore(newScore)
  }, [code, answers])

  const getBurnoutLevel = (type, score) => {
    switch (type) {
      case 'ee_total': {
        if (score <= 18) {
          return 'low'
        }
        if (score >= 19 && score <= 26) {
          return 'moderate'
        }
        if (score > 26) {
          return 'high'
        }
        break
      }
      case 'dp_total': {
        if (score <= 5) {
          return 'low'
        }
        if (score >= 6 && score <= 9) {
          return 'moderate'
        }
        if (score >= 10) {
          return 'high'
        }
        break
      }
      case 'pa_total': {
        if (score >= 40) {
          return 'low'
        }
        if (score >= 34 && score <= 39) {
          return 'moderate'
        }
        if (score <= 33) {
          return 'high'
        }
        break
      }
      default:
        return ''
    }
  }

  const getBurnoutLabel = (level) => {
    switch(level) {
      case 'low':
        return 'Low'
      case 'moderate':
        return 'Moderate'
      case 'high':
        return 'High'
      default:
        return ''
    }
  }
  
  const getInterpretation = (type, score) => {
    if (['ee_total', 'dp_total', 'pa_total'].includes(type)) {
      const burnoutLevel = getBurnoutLevel(type, score)
      const burnoutLabel = getBurnoutLabel(burnoutLevel)

      return burnoutLabel
    }

    if (['ex_total', 'cy_total'].includes(type)) {
      return 'The higher your score the more likely it is that you are experiencing burnout'
      // return 'If you feel you are experiencing burnout and are scoring high on this sub-scale, follow up with your family doctor or healthcare provider to discuss ways to reduce your stress levels'
    }

    if (type === 'pe_total') {
      return 'The lower your score the more likely it is that you are experiencing burnout'
      // return 'If you feel you are experiencing burnout and are scoring low on this sub-scale, follow up with your family doctor or healthcare provider to discuss ways to reduce your stress levels'
    }
  }

  const getRecommendation = (type, score) => {
    const burnoutRecommendations = {
      ee_total: {
        'low': 'No actions are needed',
        'moderate': 'Follow up with your family doctor or healthcare provider to discuss ways to improve your resilience',
        'high': 'Follow up urgently with your family doctor or healthcare provider to discuss ways to improve your resilience'
      },
      // ee_average: 'Your average score for Emotional Exhaustion',
      dp_total: {
        'low': 'No actions are needed',
        'moderate': 'Follow up with your family doctor or healthcare provider to discuss ways to improve your resilience',
        'high': 'Follow up urgently with your family doctor or healthcare provider to discuss ways to improve your resilience'
      },
      // dp_average: 'Your average score for Depersonalization',
      pa_total: {
        'low': 'No actions are needed',
        'moderate': 'Follow up with your family doctor or healthcare provider to discuss ways to improve your resilience',
        'high': 'Follow up urgently with your family doctor or healthcare provider to discuss ways to improve your resilience'
      }
    }
    if (['ee_total', 'dp_total', 'pa_total'].includes(type)) {
      const burnoutLevel = getBurnoutLevel(type, score)
      

      return burnoutRecommendations[type][burnoutLevel]
    }

    if (['ex_total', 'cy_total'].includes(type)) {
      return 'If you feel you are experiencing burnout and are scoring high on this sub-scale, follow up with your family doctor or healthcare provider to discuss ways to reduce your stress levels'
    }

    if (type === 'pe_total') {
      return 'If you feel you are experiencing burnout and are scoring low on this sub-scale, follow up with your family doctor or healthcare provider to discuss ways to reduce your stress levels'
    }
  }
  switch (code) {
    case 'pss':
      return (
        <div>
          <Typography variant="h5">Your score is {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Assessment for PSS-10
                </TableCell>
                <TableCell>
                  Level of perceived stress
                </TableCell>
                <TableCell>
                  Suggested actions
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>0 - 13</TableCell>
                <TableCell>Low</TableCell>
                <TableCell>
                  This score suggests that you are handling
                  stressors well and maintaining a balanced state of
                  mind. Continue practicing your current stress
                  management techniques and maintaining a
                  healthy lifestyle. Regular exercise, adequate
                  sleep, and mindfulness practices can help sustain
                  your low-stress levels. Stay aware of any new
                  stressors and address them early to prevent
                  escalation.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>14 - 26</TableCell>
                <TableCell>Moderate</TableCell>
                <TableCell>
                  This score suggests that while you are coping,
                  you may be experiencing some challenges that
                  are affecting your stress levels. Consider
                  integrating stress-relief techniques into your
                  routine, such as deep breathing exercises,
                  mindfulness, or physical activity. Assess specific
                  areas of your life that are contributing to your
                  stress and explore ways to manage them more
                  effectively. Talking to a counsellor or joining a
                  support group may also help you develop
                  additional coping strategies.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>27 - 40</TableCell>
                <TableCell>High</TableCell>
                <TableCell>
                  This score suggests that stress is significantly
                  impacting your well-being and daily life. It's
                  important to take immediate steps to manage your
                  stress. Consider seeking support from a mental
                  health professional, who can work with you to
                  develop personalized strategies for stress
                  reduction. Incorporating relaxation techniques,
                  such as meditation or yoga, into your daily routine
                  can also help manage stress. Make self-care a
                  priority and reach out to friends or family for
                  additional support.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'edbd':
      return (
        <div>
          <Typography variant="h5">Your score is {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Assessment for ED family burden score
                </TableCell>
                <TableCell>
                  Family burden level associated with your child's ectodermal dysplasia diagnosis
                </TableCell>
                <TableCell>
                  Suggested actions
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>0 - 25</TableCell>
                <TableCell>Low</TableCell>
                <TableCell>
                  This score suggests that your family is managing well
                  despite the challenges. Continue to maintain the coping
                  strategies and support systems that are working for your
                  family. If you feel that additional support or resources
                  could be beneficial, consider reaching out to a
                  healthcare professional or support group to further
                  strengthen your family's well-being and resilience.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>26 - 50</TableCell>
                <TableCell>Moderate</TableCell>
                <TableCell>
                  This score suggests that while your family is managing,
                  there are noticeable challenges that may be impacting
                  your daily life. Try to focus on things you can change. It
                  may be helpful to explore additional support options,
                  such as connecting with other families experiencing
                  similar challenges and asking for help from family and
                  friends. Addressing specific stressors and finding ways
                  to reduce them could improve your family's overall
                  well-being.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>51-75</TableCell>
                <TableCell>High</TableCell>
                <TableCell>
                  This suggests that your family is experiencing significant
                  challenges that may be affecting your well-being. It's
                  important to seek support as soon as possible. Consider
                  consulting with healthcare providers, mental health
                  professionals, or support groups who specialize in
                  chronic and rare conditions like ectodermal dysplasia.
                  Developing a plan to manage stress and improve your
                  family's coping strategies could greatly benefit your
                  family's well-being.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'mhl':
      return (
        <div>
          <Typography variant="h5">Mental Health Score {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Mental Health Literacy Scale Score
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  A score of 35 represents the worst mental health literacy and a score of 160 represents the best mental health literacy. The closer your score is to 35, the worse your mental health literacy and the closer your score is to 160, the better your mental health literacy.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'whofwbi98':
      return (
        <div>
          <Typography variant="h5">WHO (five) score {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  WHO-5 Wellness Index
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  0 represents the worst imaginable well-being and 25 represents the best imaginable well-being. The closer your score is to zero, the worse your well-being and the closer your score is to 25, the better your well-being.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'brs':
      return (
        <div>
          <Typography variant="h5">Your score is {(score / questions.length).toFixed(2)}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Assessment for Resilience Score
                </TableCell>
                <TableCell>
                  Resilience Level
                </TableCell>
                <TableCell>
                  Suggested Treatment Actions
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1.00 - 2.99</TableCell>
                <TableCell>Low resilience</TableCell>
                <TableCell>Follow up with your family doctor or healthcare provider to discuss ways to improve your resilience</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3.00 - 4.30</TableCell>
                <TableCell>Normal resilience</TableCell>
                <TableCell>No actions are needed to improve resilience</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4.31 - 5.00</TableCell>
                <TableCell>High resilience</TableCell>
                <TableCell>No actions are needed to improve resilience</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'dsm5':
      return (
        <div>
          <Typography variant="h5">Your score is {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  PTSD Score
                </TableCell>
                <TableCell>
                  Post Traumatic Stress Disorder (PTSD)
                </TableCell>
                <TableCell>
                  Suggested Treatment Actions
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>0 - 30</TableCell>
                <TableCell>You are unlikely to have PTSD</TableCell>
                <TableCell>No treatment or action is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>31 - 80</TableCell>
                <TableCell>It is possible you have PTSD</TableCell>
                <TableCell>Follow up with your family doctor or mental healthcare provider. Further assessment is needed to confirm if you have PTSD. If it is confirmed that you have, your doctor or healthcare provider may initiate treatment or refer you to another mental health professionals who can support you.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'gad7':
      return (
        <div>
          <Typography variant="h5">Your score is {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  GAD-7 Score
                </TableCell>
                <TableCell>
                  Anxiety Severity
                </TableCell>
                <TableCell>
                  Suggested Treatment Actions
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>0 - 4</TableCell>
                <TableCell>None - minimal</TableCell>
                <TableCell>No treatment is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5 - 9</TableCell>
                <TableCell>Mild</TableCell>
                <TableCell>Monitor your anxiety levels and follow-up with your family doctor or a health care provider if your anxiety is getting worse.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>10 - 14</TableCell>
                <TableCell>Moderate</TableCell>
                <TableCell>Follow up with your family doctor or mental healthcare provider. Further assessment of your mental health and treatment is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>15 - 21</TableCell>
                <TableCell>Severe</TableCell>
                <TableCell>Follow up urgently with your family doctor or healthcare provider. Immediate initiation of treatment is needed.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'phq9':
      return (
        <div>
          <Typography variant="h5">Your score is {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  PHQ-9 Score
                </TableCell>
                <TableCell>
                  Depression Severity
                </TableCell>
                <TableCell>
                  Suggested Treatment Actions
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>0 - 4</TableCell>
                <TableCell>None - minimal</TableCell>
                <TableCell>No treatment is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5 - 9</TableCell>
                <TableCell>Mild</TableCell>
                <TableCell>Monitor your mood and follow-up with your doctor or a health care provider if your mood is getting worse.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>10 - 14</TableCell>
                <TableCell>Moderate</TableCell>
                <TableCell>Follow up with your family doctor or mental healthcare provider. Further assessment of your mental health and treatment is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>15 - 19</TableCell>
                <TableCell>Moderately severe</TableCell>
                <TableCell>Follow up with your family doctor or mental healthcare provider. Further assessment of your mental health and treatment is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>20 - 27</TableCell>
                <TableCell>Severe</TableCell>
                <TableCell>Follow up urgently with your family doctor or healthcare provider. Immediate initiation of treatment is needed.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'phqa':
      return (
        <div>
          <Typography variant="h5">Your score is {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  PHQ-A Score
                </TableCell>
                <TableCell>
                  Depression Severity
                </TableCell>
                <TableCell>
                  Suggested Treatment Actions
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>0 - 4</TableCell>
                <TableCell>None - minimal</TableCell>
                <TableCell>No treatment is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5 - 9</TableCell>
                <TableCell>Mild</TableCell>
                <TableCell>Monitor your mood and follow-up with your doctor or a health care provider if your mood is getting worse.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>10 - 14</TableCell>
                <TableCell>Moderate</TableCell>
                <TableCell>Follow up with your family doctor or mental healthcare provider. Further assessment of your mental health and treatment is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>15 - 19</TableCell>
                <TableCell>Moderately severe</TableCell>
                <TableCell>Follow up with your family doctor or mental healthcare provider. Further assessment of your mental health and treatment is needed.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>20 - 27</TableCell>
                <TableCell>Severe</TableCell>
                <TableCell>Follow up urgently with your family doctor or healthcare provider. Immediate initiation of treatment is needed.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
      case 'scared': {
        const mapTitles = {
          total: 'Anxiety Disorder',
          panic_disorder: 'Panic Disorder',
          generalized_anxiety_disorder: 'Generalized Anxiety Disorder',
          separation_anxiety_disorder: 'Separation Anxiety Disorder',
          social_anxiety_disorder: 'Social Anxiety Disorder',
          significant_school_avoidance: 'Significant School Avoidance'
        }
        const mapActions = {
          total: 'A total score of 25 or greater may indicate the presence of Anxiety Disorder. Scores higher than 30 are more specific.',
          panic_disorder: 'A score of 7 may indicate Panic disorder or Significant Somatic Symptoms',
          generalized_anxiety_disorder: 'A score of 9 may indicate Generalized Anxiety Disorder',
          separation_anxiety_disorder: 'A score of 5 may indicate Separation Anxiety Disorder',
          social_anxiety_disorder: 'A score of 8 may indicate Social Anxiety Disorder',
          significant_school_avoidance: 'A score of 3 may indicate Significant School Avoidance'
        }
        return (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Anxiety Disorder
                  </TableCell>
                  <TableCell>
                    Score
                  </TableCell>
                  <TableCell>
                    Suggested Treatment Actions
                  </TableCell>
                </TableRow> 
              </TableHead>
              <TableBody>
                {
                  Object.keys(score).map(type => {
                    return (
                      <TableRow>
                        <TableCell>{mapTitles[type]}</TableCell>
                        <TableCell>{score[type]}</TableCell>
                        <TableCell>{mapActions[type]}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </div>
        )
      }
    case 'aceq':
      return (
        <div>
          <Typography variant="h5">ACE Score: {score}</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Adverse Childhood Experiences Questionnaire
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  A score of zero means you have had no adverse childhood experience. A score of 1-10 means you have had some adverse childhood experience. Higher your score suggest greater number of adverse childhood experiences.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )
    case 'mbihss':
    case 'mbihssmp':
    case 'mbies':
    case 'mbigs':
    case 'mbigss': {
      const mapTitles = {
        ee_total: 'Emotional Exhaustion (EE)',
        // ee_average: 'Average Emotional Exhaustion',
        dp_total: 'Depersonalization (DP)',
        // dp_average: 'Average Depersonalization',
        pa_total: 'Personal Accomplishment (PA)',
        // pa_average: 'Average Personal Accomplishment',
        ex_total: 'Exhaustion (EX)',
        // ex_average: 'Average Exhaustion',
        cy_total: 'Cynicism (CY)',
        // cy_average: 'Average Cynicism',
        pe_total: 'Professional / Academic Efficacy (PE)',
        // pe_average: 'Average Professional Efficacy'
      }

      const mapActions = {
        ee_total: {
          'low': 'No actions are needed',
          'moderate': 'Follow up with your family doctor or healthcare provider to discuss ways to improve your resilience',
          'high': 'Follow up urgently with your family doctor or healthcare provider to discuss ways to improve your resilience'
        },
        // ee_average: 'Your average score for Emotional Exhaustion',
        dp_total: {
          'low': 'No actions are needed',
          'moderate': 'Follow up with your family doctor or healthcare provider to discuss ways to improve your resilience',
          'high': 'Follow up urgently with your family doctor or healthcare provider to discuss ways to improve your resilience'
        },
        // dp_average: 'Your average score for Depersonalization',
        pa_total: {
          'low': 'No actions are needed',
          'moderate': 'Follow up with your family doctor or healthcare provider to discuss ways to improve your resilience',
          'high': 'Follow up urgently with your family doctor or healthcare provider to discuss ways to improve your resilience'
        },
        // pa_average: 'Your average score for Personal Accomplishment',
        ex_total: 'If you feel you are experiencing burnout and are scoring high on this sub-scale, follow up with your family doctor or healthcare provider to discuss ways to reduce your stress levels',
        // ex_average: 'Your average score for Exhaustion',
        cy_total: 'If you feel you are experiencing burnout and are scoring high on this sub-scale, follow up with your family doctor or healthcare provider to discuss ways to reduce your stress levels',
        // cy_average: 'Your average score for Cynicism',
        pe_total: 'If you feel you are experiencing burnout and are scoring low on this sub-scale, follow up with your family doctor or healthcare provider to discuss ways to reduce your stress levels',
        // pe_average: 'Your average score for Professional Efficacy'
      }
      return (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {labelsByCode[code]}
                </TableCell>
                <TableCell>
                  Total Score
                </TableCell>
                <TableCell>
                  Interpretation
                </TableCell>
                <TableCell>
                  Suggested Treatment Actions
                </TableCell>
              </TableRow> 
            </TableHead>
            <TableBody>
              {
                Object.keys(score)
                  .filter(type => type.includes('total'))
                  .map(type => {
                    return (
                      <TableRow key={`${type}_${score[type]}`}>
                        <TableCell>{mapTitles[type]}</TableCell>
                        <TableCell>{score[type]}</TableCell>
                        <TableCell>{getInterpretation(type, score[type])}</TableCell>
                        <TableCell>{getRecommendation(type, score[type])}</TableCell>
                        {/* <TableCell>{burnoutLevel ? mapActions[type][burnoutLevel] : mapActions[type]}</TableCell> */}
                      </TableRow>
                    )
                  })
              }
            </TableBody>
          </Table>
        </div>
      )
    }
    default:
      return null
  }
}