import firebase from './firebase'
import axios from 'axios'
import Auth from './auth'

const API_URL = process.env.REACT_APP_API

let self = {}

self.register = function (params) {
  return axios.post(`${API_URL}/acceptInvite`, params)
    // .then(res => {
    //   console.log('user.register.then', res)
    //   return res
    // })
    // .catch(err => {
    //   console.log('user.register.failed', err)
    //   return err
    // })
}

// TODO: move this to API?
self.invite = function (params) {
  const {email, role, organizationId, orgName} = params
  const invite = firebase.database().ref(`/invites/`).push()

  // console.log('user.invite', {email, role, organizationId, id: invite.key, status: 'pending', orgName}, invite)

  return invite.set({email, role, organizationId, orgName, id: invite.key, status: 'pending'})
}

self.getInvite = function (params) {
  const {id} = params
  const invite = firebase.database().ref(`/invites/${id}`).once('value')

  return invite.then(snap => snap.val())
}

self.get = function (params) {
  const {id, organizationId} = params
  let users = firebase.database().ref(`/users/${id ? id : ''}`)
  // const skip = page * limit || 0

  // console.log('Users.get', id, organizationId, limit, skip, name, page)

  if (organizationId) {
    // console.log('get by orgId')
    users = users.orderByChild('organizationId').equalTo(organizationId)
  }

  // if (name) {
  //   users = users.orderByChild('name').startAt(name, lastKey)
  // }

  // if (id) {
  //   users = users.orderByChild('id').equalTo(id)
  // }

  // users = users.limitToFirst(limit)

  // if (skip) {
  //   users = users.startAt(skip)
  // }

  return users.once('value').then(snap => id ? snap.val() : Object.values(snap.val() || {}))
}

self.update = function (params) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/updateUser`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

self.delete = function (id) {
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/deleteUser`, {id}, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
  })
}

export default self