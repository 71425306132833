import React from 'react'
import ProgramsTable from './ProgramsTable'
import { Grid, Tabs, Tab, AppBar } from '@material-ui/core'
import Auth from '../services/auth'
import useAppState from '../services/useAppState'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  selected: {
    backgroundColor: 'rgb(56, 52, 150)'
  }
})

export default function Dashboard () {
  const classes = useStyles()
  const {organization} = useAppState()

  const getMethods = function () {
    const commMethods = ['sms']

    if (organization.communication_methods && organization.communication_methods.includes('email')) {
      commMethods.push('email')
    }
    
    return commMethods
  }

  const [programType, setProgramType] = React.useState(getMethods()[0])
  const [tabIndex, setTabIndex] = React.useState(0)

  const onTabChange = function (e, index) {
    const tabs = ['sms', 'email']
    setProgramType(tabs[index])
    setTabIndex(index)
  }

  if (!organization || !organization.id) {
    return null
  }

  return (
    <div>
      <AppBar position="static" className={classes.root}>
        <Tabs classes={{indicator: classes.selected}} value={tabIndex} onChange={onTabChange}>
          {
            getMethods()
              .map(key => {
                switch (key) {
                  case 'sms':
                    return <Tab key={'a'} label="Text Programs" />
                  case 'email':
                    return <Tab key={'b'} label="Email Programs" />
                  default:
                    return null
                }
              })
          }
        </Tabs>
      </AppBar>
      <Grid spacing={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProgramsTable type={programType} organizationId={organization.id} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}