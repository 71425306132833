import React, { useEffect } from 'react'
import {
  TextField,
  Typography,
  CircularProgress,
  FormHelperText
} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab'
import Program from '../../services/program'
const QUERY_LIMIT = 200

const RecipientAutoComplete = (props) => {
  const {programId, searchKey} = props
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0
  const searchKeyLabels = {
    'email': 'Email Address',
    'phone': 'Phone Number'
  }

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    (async () => {
      const res = await Program.searchSubscribers({programId, searchKey, query: '', limit: QUERY_LIMIT})

      if (!res.length) {
        res.push({[searchKey]: 'No Recipients Found', id: 'none'})
      }

      if (active) {
        setOptions(res)
      }
    })()

    return () => {
      active = false
    }
  }, [programId, loading])

  useEffect(() => {
    if (!open) {
      // setOptions([])
    }
  }, [open])
  return (
    <div>
      <Autocomplete
        multiple
        filterSelectedOptions
        fullWidth
        style={{ maxWidth: 800 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(e, selection) => {
          console.log('AutoComplete.onChange', selection)
          // setSelected(selection)
          if (props.onSelect) {
            props.onSelect(selection)
          }
        }}
        getOptionSelected={(option, value) => option[searchKey] === value[searchKey]}
        getOptionLabel={(option) => option[searchKey]}
        options={options}
        loading={loading}
        getOptionDisabled={(option) => option.id === 'none'}
        renderOption={(option, state) => {
          if (option.id === 'none') {
            return (
              <React.Fragment>
                <div>
                  <Typography>{option[searchKey]}</Typography>
                  <Typography variant="caption">Try modifying your search query. If there are still no results, there may be no recipients in this program.</Typography>
                </div>
              </React.Fragment>
            )
          }
          
          return (
            <React.Fragment>
              <div>
                <Typography>{option[searchKey]}</Typography>
                <Typography variant="caption">{option.completed && !option.subscribed ? 'Completed' : option.subscribed ? 'Active' : 'Unsubscribed'}</Typography>
              </div>
            </React.Fragment>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Find by ${searchKeyLabels[searchKey]}`}
            variant="outlined"
            error={(props.selected || []).length === 0}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <FormHelperText error={(props.selected || []).length === 0}>{(props.selected || []).length === 0 ? 'At least one recipient selection is required' : 'Search and select at least one recipient'}</FormHelperText>
    </div>
  )
}

export default function RecipientSelector (props) {
  return (
    <div style={{marginTop: 10, marginBottom: 10}}>
      <Typography style={{marginBottom: 10}}>Recipients</Typography>
      <div>
        <RecipientAutoComplete programId={props.programId} searchKey={props.searchKey} selected={props.selected} onSelect={props.onSelect} />
      </div>
    </div>
  )
}