import { Fragment, useState } from "react"
import { InputLabel, MenuItem, Select, BootstrapInput, FormControl, FormHelperText } from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  customSelect: {
    width: 80,
    "& .code": {
      borderRight: "none",
      paddingRight: 0,
      marginRight: 0
    },
    "& .country": {
      display: "none"
    }
  }
})

const CountryCodePicker = (props) => {
  const classes = useStyles()
  const {selected, filters, onChange, inputLabel = null, helperText = null} = props
  // console.log('CountryCodePicker.render', selected, onChange)
  // ISO 3166-1 alpha-2
  const countryCodes = [
    { "country": "Andorra", "code": "AD", "dialingCode": "+376" },
    { "country": "United Arab Emirates", "code": "AE", "dialingCode": "+971" },
    { "country": "Afghanistan", "code": "AF", "dialingCode": "+93" },
    { "country": "Antigua and Barbuda", "code": "AG", "dialingCode": "+1" },
    { "country": "Albania", "code": "AL", "dialingCode": "+355" },
    { "country": "Armenia", "code": "AM", "dialingCode": "+374" },
    { "country": "Angola", "code": "AO", "dialingCode": "+244" },
    { "country": "Argentina", "code": "AR", "dialingCode": "+54" },
    { "country": "American Samoa", "code": "AS", "dialingCode": "+1" },
    { "country": "Austria", "code": "AT", "dialingCode": "+43" },
    { "country": "Australia", "code": "AU", "dialingCode": "+61" },
    { "country": "Aruba", "code": "AW", "dialingCode": "+297" },
    { "country": "Azerbaijan", "code": "AZ", "dialingCode": "+994" },
    { "country": "Bosnia and Herzegovina", "code": "BA", "dialingCode": "+387" },
    { "country": "Barbados", "code": "BB", "dialingCode": "+1" },
    { "country": "Bangladesh", "code": "BD", "dialingCode": "+880" },
    { "country": "Belgium", "code": "BE", "dialingCode": "+32" },
    { "country": "Burkina Faso", "code": "BF", "dialingCode": "+226" },
    { "country": "Bulgaria", "code": "BG", "dialingCode": "+359" },
    { "country": "Bahrain", "code": "BH", "dialingCode": "+973" },  // Note: I realized Bahrain was missing from the initial list
    { "country": "Benin", "code": "BJ", "dialingCode": "+229" },
    { "country": "Bermuda", "code": "BM", "dialingCode": "+1" },
    { "country": "Brunei Darussalam", "code": "BN", "dialingCode": "+673" },
    { "country": "Bolivia", "code": "BO", "dialingCode": "+591" },
    { "country": "Brazil", "code": "BR", "dialingCode": "+55" },
    { "country": "Bahamas", "code": "BS", "dialingCode": "+1" },
    { "country": "Bhutan", "code": "BT", "dialingCode": "+975" },
    { "country": "Botswana", "code": "BW", "dialingCode": "+267" },
    { "country": "Belarus", "code": "BY", "dialingCode": "+375" },
    { "country": "Belize", "code": "BZ", "dialingCode": "+501" },
    { "country": "Canada", "code": "CA", "dialingCode": "+1" },
    { "country": "Congo, (Kinshasa)", "code": "CD", "dialingCode": "+243" },
    { "country": "Central African Republic", "code": "CF", "dialingCode": "+236" },
    { "country": "Congo (Brazzaville)", "code": "CG", "dialingCode": "+242" },
    { "country": "Switzerland", "code": "CH", "dialingCode": "+41" },
    { "country": "Côte d'Ivoire", "code": "CI", "dialingCode": "+225" },
    { "country": "Cook Islands", "code": "CK", "dialingCode": "+682" },
    { "country": "Chile", "code": "CL", "dialingCode": "+56" },
    { "country": "Cameroon", "code": "CM", "dialingCode": "+237" },
    { "country": "China", "code": "CN", "dialingCode": "+86" },  // Note: I added China as it was missing from the initial list
    { "country": "Colombia", "code": "CO", "dialingCode": "+57" },
    { "country": "Costa Rica", "code": "CR", "dialingCode": "+506" },
    { "country": "Cuba", "code": "CU", "dialingCode": "+53" },  // Note: I added Cuba as it was missing from the initial list
    { "country": "Cape Verde", "code": "CV", "dialingCode": "+238" },
    { "country": "Curaçao", "code": "CW", "dialingCode": "+599" },
    { "country": "Cyprus", "code": "CY", "dialingCode": "+357" },
    { "country": "Czech Republic", "code": "CZ", "dialingCode": "+420" },
    { "country": "Germany", "code": "DE", "dialingCode": "+49" },
    { "country": "Djibouti", "code": "DJ", "dialingCode": "+253" },
    { "country": "Denmark", "code": "DK", "dialingCode": "+45" },
    { "country": "Dominica", "code": "DM", "dialingCode": "+1" },
    { "country": "Dominican Republic", "code": "DO", "dialingCode": "+1" },
    { "country": "Ecuador", "code": "EC", "dialingCode": "+593" },
    { "country": "Egypt", "code": "EG", "dialingCode": "+20" },
    { "country": "Spain", "code": "ES", "dialingCode": "+34" },
    { "country": "Ethiopia", "code": "ET", "dialingCode": "+251" },
    { "country": "Finland", "code": "FI", "dialingCode": "+358" },
    { "country": "Fiji", "code": "FJ", "dialingCode": "+679" },
    { "country": "Falkland Islands (Malvinas)", "code": "FK", "dialingCode": "+500" },
    { "country": "Micronesia, Federated States of", "code": "FM", "dialingCode": "+691" },
    { "country": "Faroe Islands", "code": "FO", "dialingCode": "+298" },
    { "country": "France", "code": "FR", "dialingCode": "+33" },
    { "country": "Gabon", "code": "GA", "dialingCode": "+241" },
    { "country": "United Kingdom", "code": "GB", "dialingCode": "+44" },
    { "country": "Grenada", "code": "GD", "dialingCode": "+1" },
    { "country": "Georgia", "code": "GE", "dialingCode": "+995" },
    { "country": "French Guiana", "code": "GF", "dialingCode": "+594" },
    { "country": "Guernsey", "code": "GG", "dialingCode": "+44" }, // Channel Islands often use UK's code
    { "country": "Ghana", "code": "GH", "dialingCode": "+233" },
    { "country": "Gibraltar", "code": "GI", "dialingCode": "+350" },
    { "country": "Greenland", "code": "GL", "dialingCode": "+299" },
    { "country": "Gambia", "code": "GM", "dialingCode": "+220" },
    { "country": "Guadeloupe", "code": "GP", "dialingCode": "+590" },
    { "country": "Equatorial Guinea", "code": "GQ", "dialingCode": "+240" },
    { "country": "Greece", "code": "GR", "dialingCode": "+30" },
    { "country": "Guatemala", "code": "GT", "dialingCode": "+502" },
    { "country": "Guyana", "code": "GY", "dialingCode": "+592" },
    { "country": "Hong Kong, SAR China", "code": "HK", "dialingCode": "+852" },
    { "country": "Honduras", "code": "HN", "dialingCode": "+504" },
    { "country": "Croatia", "code": "HR", "dialingCode": "+385" },
    { "country": "Haiti", "code": "HT", "dialingCode": "+509" },
    { "country": "Hungary", "code": "HU", "dialingCode": "+36" },
    { "country": "Indonesia", "code": "ID", "dialingCode": "+62" },
    { "country": "Ireland", "code": "IE", "dialingCode": "+353" },
    { "country": "Israel", "code": "IL", "dialingCode": "+972" },
    { "country": "Isle of Man", "code": "IM", "dialingCode": "+44" },
    { "country": "India", "code": "IN", "dialingCode": "+91" },
    { "country": "Iraq", "code": "IQ", "dialingCode": "+964" },
    { "country": "Italy", "code": "IT", "dialingCode": "+39" },
    { "country": "Jersey", "code": "JE", "dialingCode": "+44" },
    { "country": "Jamaica", "code": "JM", "dialingCode": "+1" },
    { "country": "Jordan", "code": "JO", "dialingCode": "+962" },
    { "country": "Japan", "code": "JP", "dialingCode": "+81" },
    { "country": "Kenya", "code": "KE", "dialingCode": "+254" },
    { "country": "Kyrgyzstan", "code": "KG", "dialingCode": "+996" },
    { "country": "Cambodia", "code": "KH", "dialingCode": "+855" },
    { "country": "Comoros", "code": "KM", "dialingCode": "+269" },
    { "country": "Saint Kitts and Nevis", "code": "KN", "dialingCode": "+1" },
    { "country": "Korea (South)", "code": "KR", "dialingCode": "+82" },
    { "country": "Kuwait", "code": "KW", "dialingCode": "+965" },
    { "country": "Cayman Islands", "code": "KY", "dialingCode": "+1" },
    { "country": "Kazakhstan", "code": "KZ", "dialingCode": "+7" },
    { "country": "Lao PDR", "code": "LA", "dialingCode": "+856" },
    { "country": "Lebanon", "code": "LB", "dialingCode": "+961" },
    { "country": "Saint Lucia", "code": "LC", "dialingCode": "+1" },
    { "country": "Liechtenstein", "code": "LI", "dialingCode": "+423" },
    { "country": "Sri Lanka", "code": "LK", "dialingCode": "+94" },
    { "country": "Lesotho", "code": "LS", "dialingCode": "+266" },
    { "country": "Lithuania", "code": "LT", "dialingCode": "+370" },
    { "country": "Luxembourg", "code": "LU", "dialingCode": "+352" },
    { "country": "Latvia", "code": "LV", "dialingCode": "+371" },
    { "country": "Libya", "code": "LY", "dialingCode": "+218" },
    { "country": "Morocco", "code": "MA", "dialingCode": "+212" },
    { "country": "Moldova", "code": "MD", "dialingCode": "+373" },
    { "country": "Montenegro", "code": "ME", "dialingCode": "+382" },
    { "country": "Saint-Martin (French part)", "code": "MF", "dialingCode": "+590" },
    { "country": "Madagascar", "code": "MG", "dialingCode": "+261" },
    { "country": "Macedonia, Republic of", "code": "MK", "dialingCode": "+389" },
    { "country": "Myanmar", "code": "MM", "dialingCode": "+95" },
    { "country": "Mongolia", "code": "MN", "dialingCode": "+976" },
    { "country": "Macao, SAR China", "code": "MO", "dialingCode": "+853" },
    { "country": "Montserrat", "code": "MS", "dialingCode": "+1" },
    { "country": "Malta", "code": "MT", "dialingCode": "+356" },
    { "country": "Mauritius", "code": "MU", "dialingCode": "+230" },
    { "country": "Malawi", "code": "MW", "dialingCode": "+265" },
    { "country": "Mexico", "code": "MX", "dialingCode": "+52" },
    { "country": "Malaysia", "code": "MY", "dialingCode": "+60" },
    { "country": "Mozambique", "code": "MZ", "dialingCode": "+258" },
    { "country": "Namibia", "code": "NA", "dialingCode": "+264" },
    { "country": "New Caledonia", "code": "NC", "dialingCode": "+687" },
    { "country": "Niger", "code": "NE", "dialingCode": "+227" },
    { "country": "Norfolk Island", "code": "NF", "dialingCode": "+672" },
    { "country": "Nigeria", "code": "NG", "dialingCode": "+234" },
    { "country": "Nicaragua", "code": "NI", "dialingCode": "+505" },
    { "country": "Netherlands", "code": "NL", "dialingCode": "+31" },
    { "country": "Norway", "code": "NO", "dialingCode": "+47" },
    { "country": "Nepal", "code": "NP", "dialingCode": "+977" },
    { "country": "New Zealand", "code": "NZ", "dialingCode": "+64" },
    { "country": "Oman", "code": "OM", "dialingCode": "+968" },
    { "country": "Panama", "code": "PA", "dialingCode": "+507" },
    { "country": "Peru", "code": "PE", "dialingCode": "+51" },
    { "country": "Papua New Guinea", "code": "PG", "dialingCode": "+675" },
    { "country": "Philippines", "code": "PH", "dialingCode": "+63" },
    { "country": "Pakistan", "code": "PK", "dialingCode": "+92" },
    { "country": "Poland", "code": "PL", "dialingCode": "+48" },
    { "country": "Saint Pierre and Miquelon", "code": "PM", "dialingCode": "+508" },
    { "country": "Puerto Rico", "code": "PR", "dialingCode": "+1" },
    { "country": "Palestinian Territory", "code": "PS", "dialingCode": "+970" },
    { "country": "Portugal", "code": "PT", "dialingCode": "+351" },
    { "country": "Paraguay", "code": "PY", "dialingCode": "+595" },
    { "country": "Qatar", "code": "QA", "dialingCode": "+974" },
    { "country": "Réunion", "code": "RE", "dialingCode": "+262" },
    { "country": "Romania", "code": "RO", "dialingCode": "+40" },
    { "country": "Serbia", "code": "RS", "dialingCode": "+381" },
    { "country": "Russian Federation", "code": "RU", "dialingCode": "+7" },
    { "country": "Rwanda", "code": "RW", "dialingCode": "+250" },
    { "country": "Saudi Arabia", "code": "SA", "dialingCode": "+966" },
    { "country": "Seychelles", "code": "SC", "dialingCode": "+248" },
    { "country": "Sweden", "code": "SE", "dialingCode": "+46" },
    { "country": "Singapore", "code": "SG", "dialingCode": "+65" },
    { "country": "Slovenia", "code": "SI", "dialingCode": "+386" },
    { "country": "Slovakia", "code": "SK", "dialingCode": "+421" },
    { "country": "Sierra Leone", "code": "SL", "dialingCode": "+232" },
    { "country": "San Marino", "code": "SM", "dialingCode": "+378" },
    { "country": "Senegal", "code": "SN", "dialingCode": "+221" },
    { "country": "Somalia", "code": "SO", "dialingCode": "+252" },
    { "country": "Suriname", "code": "SR", "dialingCode": "+597" },
    { "country": "South Sudan", "code": "SS", "dialingCode": "+211" },
    { "country": "Sao Tome and Principe", "code": "ST", "dialingCode": "+239" },
    { "country": "El Salvador", "code": "SV", "dialingCode": "+503" },
    { "country": "Sint Maarten (Dutch part)", "code": "SX", "dialingCode": "+1" },
    { "country": "Syrian Arab Republic (Syria)", "code": "SY", "dialingCode": "+963" },
    { "country": "Swaziland", "code": "SZ", "dialingCode": "+268" },
    { "country": "Turks and Caicos Islands", "code": "TC", "dialingCode": "+1" },
    { "country": "Chad", "code": "TD", "dialingCode": "+235" },
    { "country": "Togo", "code": "TG", "dialingCode": "+228" },
    { "country": "Thailand", "code": "TH", "dialingCode": "+66" },
    { "country": "Tajikistan", "code": "TJ", "dialingCode": "+992" },
    { "country": "Tokelau", "code": "TK", "dialingCode": "+690" },
    { "country": "Turkmenistan", "code": "TM", "dialingCode": "+993" },
    { "country": "Tunisia", "code": "TN", "dialingCode": "+216" },
    { "country": "Turkey", "code": "TR", "dialingCode": "+90" },
    { "country": "Trinidad and Tobago", "code": "TT", "dialingCode": "+1" },
    { "country": "Taiwan, Republic of China", "code": "TW", "dialingCode": "+886" },
    { "country": "Tanzania, United Republic of", "code": "TZ", "dialingCode": "+255" },
    { "country": "Ukraine", "code": "UA", "dialingCode": "+380" },
    { "country": "Uganda", "code": "UG", "dialingCode": "+256" },
    { "country": "US Minor Outlying Islands", "code": "UM", "dialingCode": "" },
    { "country": "United States of America", "code": "US", "dialingCode": "+1" },
    { "country": "Uruguay", "code": "UY", "dialingCode": "+598" },
    { "country": "Uzbekistan", "code": "UZ", "dialingCode": "+998" },
    { "country": "Holy See (Vatican City State)", "code": "VA", "dialingCode": "+39" },
    { "country": "Venezuela (Bolivarian Republic)", "code": "VE", "dialingCode": "+58" },
    { "country": "British Virgin Islands", "code": "VG", "dialingCode": "+1" },
    { "country": "Virgin Islands, US", "code": "VI", "dialingCode": "+1" },
    { "country": "Vietnam", "code": "VN", "dialingCode": "+84" },
    { "country": "Vanuatu", "code": "VU", "dialingCode": "+678" },
    { "country": "Wallis and Futuna Islands", "code": "WF", "dialingCode": "+681" },
    { "country": "Samoa", "code": "WS", "dialingCode": "+685" },
    { "country": "Kosovo", "code": "XK", "dialingCode": "+383" },
    { "country": "Yemen", "code": "YE", "dialingCode": "+967" },
    { "country": "Mayotte", "code": "YT", "dialingCode": "+262" },
    { "country": "South Africa", "code": "ZA", "dialingCode": "+27" },
    { "country": "Zambia", "code": "ZM", "dialingCode": "+260" },
    { "country": "Zimbabwe", "code": "ZW", "dialingCode": "+263" }
  ].filter(cc => !filters || filters.includes(cc.code))
  // const [code, setCode] = useState('+1')

  const handleChange = (e) => {
    // setCode(e.target.value)
    if (onChange) {
      // console.log('handleChange', e.target.value, countryCodes.find(cc => cc.code === e.target.value))
      onChange(countryCodes.find(cc => cc.code === e.target.value))
    }
  }

  return inputLabel ? (
    <FormControl fullWidth>
      <InputLabel fullWidth>{inputLabel}</InputLabel>
      <Select
        fullWidth
        value={selected.code}
        onChange={handleChange}
        // input={<BootstrapInput />}
      >
        {
          countryCodes
            .map(cc => (
                <MenuItem value={cc.code}>
                  <span class="code" style={{width: '80px'}}>{cc.dialingCode}</span>
                  <span class="country" style={{marginLeft: '20px', textAlign: 'left'}}>{cc.country}</span>
                </MenuItem>
              )
            )
        }
      </Select>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  ) : (
    <Fragment>
      {/* <InputLabel id="countryCode">Country Code</InputLabel> */}
      <Select
        labelId="countryCode-label"
        id="demo-customized-select"
        value={selected.code}
        onChange={handleChange}
        className={classes.customSelect}
        // input={<BootstrapInput />}
      >
        {
          countryCodes
            .map(cc => (
                <MenuItem value={cc.code}>
                  <span class="code" style={{width: '80px'}}>{cc.dialingCode}</span>
                  <span class="country" style={{marginLeft: '20px', textAlign: 'left'}}>{cc.country}</span>
                </MenuItem>
              )
            )
        }
      </Select>
    </Fragment>
  ) 
}

export default CountryCodePicker