import { useEffect, useState, Fragment } from 'react'
import { sendEmailVerification } from 'firebase/auth'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import {makeStyles} from '@material-ui/core/styles'
import LoadingIcon from '@material-ui/icons/RotateRightOutlined'

const useStyles = makeStyles((theme) => ({
  view: {
    marginTop: '5rem',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  viewPublic: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0//theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  dialog: {
    minWidth: '350px'
  }
}))

const VerifyEmail = ({onDone, user}) => {
  const classes = useStyles()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sentEmail, setSentEmail] = useState(false)

  // console.log('VerifyEmail', user)

  const verify = () => {
    setLoading(true)
    sendEmailVerification(user)
      .then(() => {
        setLoading(false)
        setSentEmail(true)
        // if (onDone) {
        //   // user will have to verify email then re-login
        //   onDone(false)
        // }
      })
      .catch(err => {
        setLoading(false)
        console.error('sendEmailVerification.failed', err)
        // if (onDone) {
        //   onDone(false)
        // }
        setSentEmail(false)
        setError(err)
      })
  }

  const cancel = () => {
    if (onDone) {
      // user wants to cancel
      onDone(false)
    }
  }

  useEffect(() => {
    // on init, check if user already verified, if so then just call done
    if (user.emailVerified) {
      if (onDone) {
        // they're already verified, continue
        onDone(true)
      }
    }
  }, [user, onDone])

  return (
    <Dialog classes={{paper: classes.dialog}} open={true} aria-labelledby="Authentication">
      <DialogTitle>{sentEmail ? 'Email Verification Sent!' : 'Email Verification Required'}</DialogTitle>
      {/* <img alt="ResilienceNHope Logo" src={logo} style={{maxWidth: '227px', margin: '0 auto'}} /> */}
      <DialogContent>
        {
          sentEmail ? (
            <Fragment>
              {/* <Check color={'primary'} /> */}
              <p><strong>Verification email successfully sent.</strong></p>
              <p>Please check your email and click the link to complete the verification process.</p>
            </Fragment>
            
          ) : (
            <Fragment>
              <p>As part of new security measures, email verification and multi-factor authentication are now required to access the ResilienceNHope portal. Click the button below to send a verification email.</p>
              <p>If you don't receive an email in your inbox, please check your Spam folder. The sender of the email will be <strong>info@resiliencenhope.org</strong>.</p>
              <p>Once your email is verified, you'll need to return to the ResilienceNHope login page.</p>
            </Fragment>
          )
        }
        {
          error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Failed to send verification email, please try again later. If the issue persists, please contact an administrator.
            </Alert>
          )
        }
      </DialogContent>
      <DialogActions>
        {
          sentEmail ? (
            <Fragment>
              <Button onClick={cancel} color="primary">Close</Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button onClick={cancel} color="secondary">Cancel</Button>
              <Button onClick={verify} color="primary" disabled={loading}>
                {loading ? <LoadingIcon fontSize="small" /> : 'Send Verification Email'}
              </Button>
            </Fragment>
          )
        }
      </DialogActions>
    </Dialog>
  )
}

export default VerifyEmail