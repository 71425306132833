import React from 'react'
import { Paper } from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import DraggableList from '../DraggableList/DraggableList'
import {labelsByCode} from '../../utils/survey'

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 15,
    minWidth: 350,
    listStyleType: 'none'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 550
  }
})

const reorder = function (list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)

  return result
}

export default function AssessmentList (props) {
  const classes = useStyles()

  const labelMap = {...labelsByCode}
  
  const onDragEnd = ({destination, source}) => {
    if (!destination || props.disabled) {return}

    const newItems = reorder(props.assessments, source.index, destination.index)

    props.onChange(newItems)
  }

  const generateSecondaryLabel = (fieldType, requiredField) => {
    const typeMap = {
      'paragraph': 'Text area input',
      'shortanswer': 'Text input',
      'dropdown': 'Drop Down',
      'multichoice': 'Multiple Choice',
      'linearscale': 'Linear Scale'
    }

    const required = requiredField ? 'Mandatory' : 'Optional'
    const label = typeMap[fieldType]

    return `${required} ${label}`
  }

  const onToggleActivation = (code) => {
    const newAssessments = [...props.assessments]
    const index = newAssessments.findIndex(assessment => assessment.code === code)
    const item = newAssessments[index]

    item.active = !item.active
    
    newAssessments[index] = item
    
    props.onChange(newAssessments)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.flexPaper}>
        <DraggableList
          disabled={props.disabled}
          activation={true}
          onToggleActivation={(item) => onToggleActivation(item.code)}
          items={(props.assessments || []).map(({code, active}) => ({id: code, code, primary: labelMap[code], active}))}
          onDragEnd={onDragEnd}
        />
      </Paper>
    </div>
  )
}