import React from 'react'
import { Typography, Paper, TableContainer, IconButton} from "@material-ui/core";
import Notify from './Notify'
import MakeIcon from '@material-ui/icons/Add'
import InviteUser from './InviteUser'
import UsersTable from './UsersTable'
import EditUser from './EditUser'
import DeleteUserModal from './DeleteUserModal'
import useAppState from '../services/useAppState'

export default function Users () {
  const {organization} = useAppState()
  const [showInviteUser, setShowInviteUser] = React.useState(false)
  const [showEditUser, setShowEditUser] = React.useState(false)
  const [showDeleteUser, setShowDeleteUser] = React.useState(false)
  const [selected, setSelected] = React.useState(null)
  const [notify, setNotify] = React.useState({show: false})
  const [updateTable, setUpdateTable] = React.useState(false)

  const inviteUser = function () {
    setShowInviteUser(true)
  }

  const onInviteUserClose = function (res) {
    if (res.success) {
      setNotify({show: true, variant: 'success', message: `Successfully invited User, ${res.invite.email} to ${res.invite.orgName}.`})
    }
    setShowInviteUser(false)
  }

  const onNotifyClose = function () {
    setNotify({show: false})
  }

  const onEditUser = function (id) {
    setSelected(id)
    setShowEditUser(true)
  }

  const onDeleteUser = function (id) {
    setSelected(id)
    setShowDeleteUser(true)
  }

  const onDeleteUserClose = function (res) {
    if (res.success) {
      setNotify({show: true, variant: 'success', message: `Successfully deleted user, ${res.email}.`})
      setShowDeleteUser(false)
      setUpdateTable(true)
    } else {
      setShowDeleteUser(false)
      setSelected(null)
    }
  }

  const onEditUserClose = function (res) {
    if (res.success) {
      setNotify({show: true, variant: 'success', message: `Successfully update User, ${res.email}.`})
      setUpdateTable(true)
    }
    setShowEditUser(false)
    setSelected(null)
  }

  const onTableUpdating = function () {
    setUpdateTable(false)
  }

  return (
    <React.Fragment>
      {notify.show && <Notify variant={notify.variant} onClose={onNotifyClose} message={notify.message} />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Admins
        <IconButton onClick={inviteUser}>
          <MakeIcon />
        </IconButton>
      </Typography>
      <TableContainer component={Paper}>
        {organization && <UsersTable onEditUser={onEditUser} onDeleteUser={onDeleteUser} updateTable={updateTable} onUpdating={onTableUpdating} />}
      </TableContainer>
      {showInviteUser && <InviteUser open={showInviteUser} organizationId={organization.id} orgName={organization.name} onClose={onInviteUserClose} />}
      {showEditUser && <EditUser id={selected} open={showEditUser} onClose={onEditUserClose} />}
      {showDeleteUser && <DeleteUserModal id={selected} open={showDeleteUser} onClose={onDeleteUserClose} />}
    </React.Fragment>
  )
}