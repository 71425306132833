import React from 'react'
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core'
import {useParams} from 'react-router-dom'
import Subscriber from '../services/subscriber'
import {Alert, AlertTitle} from '@material-ui/lab'

export default function Subscribe (props) {
  const {id} = useParams()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [state, setState] = React.useState({success: false})

  const submit = function () {
    setError(null)
    Subscriber.subscribe({id})
      .then(res => {
        setState({...state, success: true, programName: res.programName})
        setLoading(false)
      })
      .catch(err => {
        console.error('submit.err', err)
        setError({message: 'Failed to re-subscribe, please try again. If the issue persists, please contact an administrator.'})
        setLoading(false)
      })
  }

  if (loading) {
    return (
      <Dialog open={true} aria-labelledby="Subscribe">
        <DialogTitle>Loading</DialogTitle>
        <DialogContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CircularProgress color="secondary" />
        </DialogContent>
      </Dialog>
    )
  }

  if (state.success) {
    return (
      <Dialog open={true} aria-labelledby="Subscribe">
        <DialogTitle>You've been subscribed</DialogTitle>
        <DialogContent>
          You have been re-subscribed to {state.programName}. You can now close this window.
        </DialogContent>
      </Dialog>
    )
  }

  if (!id) {
    return (
      <Dialog open={true} aria-labelledby="Subscribe">
        <DialogTitle>Corrupted Request</DialogTitle>
        <DialogContent>
          This request is not valid, please report to an administrator.
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <div>
      <Dialog open={true} aria-labelledby="Subscribe">
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <Typography variant="body1">You have requested to re-subscribe. Please click the Subscribe button below to complete your request.</Typography>

          {
            error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error.message}
              </Alert>
            )
          }
        </DialogContent>
        <DialogActions>
          {!state.success && <Button onClick={submit} disabled={loading} color="primary">Subscribe</Button>}
        </DialogActions>
      </Dialog>
    </div>
  )
}