import React, { useEffect } from 'react'
import MessagesTable from '../AdhocMessages/MessagesTable'
import { Grid } from '@material-ui/core'
import Auth from '../../services/auth'
import {makeStyles} from '@material-ui/core/styles'
import useAppState from '../../services/useAppState'
import {useParams, useHistory} from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  selected: {
    backgroundColor: 'rgb(56, 52, 150)'
  }
})

export default function ProgramAdhocMessages () {
  const classes = useStyles()
  const {organization} = useAppState()
  const {id} = useParams()

  console.log('ProgramAdhocMessages', organization, id)

  useEffect(() => {
  }, [organization.id])

  return (
    <div>
      <Grid spacing={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MessagesTable organizationId={Auth.isRole('owner') ? organization.id : ''} programId={id} />
            {/* <SurveysTable organizationId={Auth.isRole('owner') ? organization.id : ''} /> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}