import React from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TextField,
  FormHelperText,
  FormLabel,
  Typography,
} from '@material-ui/core'
import RichText from '../RichText'
import AddIcon from '@material-ui/icons/Add'
import AddField from './AddField'
import QuestionsList from './QuestionsList'
import AssessmentList from './AssessmentList'
import Survey from '../../services/survey'
import Language from '../../services/language'
import MakeSurveyWelcomeEmail from './MakeSurveyWelcomeEmail'
import MakeReminderEmail from './MakeReminderEmail'
import SurveyUtil from '../../utils/survey'
import LoadingModal from '../LoadingModal'

export default function SurveyForm (props) {
  const [state, setState] = React.useState({
    name: '',
    language: 'en',
    intro: '<h3>Thank you for volunteering to complete the TestSurvey</h3><p><br></p><p>This survey will take <strong>10 - 15 minutes </strong>to complete.</p><p><br></p><h4><strong>Important Information:</strong></h4><ul><li>Your participation is voluntary.</li><li>Responses are confidential</li><li>At the end of the survey, you will be able to see your scores on some of the mental health scales. We will offer you an interpretation of what your score means and suggestions about what you could do to improve your mental health.</li><li>Responses are anonymous - results will be reported as a group.</li></ul><p><br></p><p>Thank you for your participation in this survey!</p>',
    welcomeEmailBody: SurveyUtil.defaultWelcomeEmailBody,
    reminderEmailBody: SurveyUtil.defaultReminderEmailBody,
    welcomeSmsBody: SurveyUtil.defaultWelcomeSmsBody,
    reminderSmsBody: SurveyUtil.defaultReminderSmsBody,
    demographic: [],
    satisfaction: [],
    assessment: [
      {
        code: 'phq9',
        active: true
      },
      {
        code: 'gad7',
        active: false
      },
      {
        code: 'dsm5',
        active: false
      },
      {
        code: 'brs',
        active: false
      },
      {
        code: 'whofwbi98',
        active: false
      },
      {
        code: 'aceq',
        active: false
      },
      {
        code: 'mhl',
        active: false
      },
      {
        code: 'scared',
        active: false
      },
      {
        code: 'phqa',
        active: false
      },
      {
        code: 'mbihss',
        active: false
      },
      {
        code: 'mbihssmp',
        active: false
      },
      {
        code: 'mbies',
        active: false
      },
      {
        code: 'mbigs',
        active: false
      },
      {
        code: 'mbigss',
        active: false
      },
      {
        code: 'edbd',
        active: false
      },
      {
        code: 'pss',
        active: false
      }
    ]
  })
  const [addFieldDialog, setAddFieldDialog] = React.useState({
    show: false,
    section: null
  })
  const [welcomeEmailDialog, setWelcomeEmailDialog] = React.useState({show: false})
  const [reminderEmailDialog, setReminderEmailDialog] = React.useState({show: false})
  const [loading, setLoading] = React.useState(false)
  const [validForm, setValidForm] = React.useState(false)
  const [processingRequest, setProcessingRequest] = React.useState(false)

  React.useEffect(() => {
    if (props.id && state.id !== props.id && !loading) {
      setLoading(true)
      Survey.get({id: props.id, organizationId: props.organizationId})
        .then(surveyResult => {
          console.log('surveyResult', surveyResult)

          if (!surveyResult.demographic) {
            surveyResult.demographic = []
          }

          if (!surveyResult.satisfaction) {
            surveyResult.satisfaction = []
          }

          setState(surveyResult)
          setLoading(false)
        })
        .catch(err => {
          console.error('SurveyForm.getSurvey.failed', err)
          setLoading(false)
        })
    }
  }, [props.id])

  const onNameChange = (e) => {
    const newState = {...state, name: e.target.value}
    setState(newState)
    validateForm(newState)
  }

  const onIntroChange = (newIntro) => {
    const newState = {...state, intro: newIntro}
    setState(newState)
    validateForm(newState)
  }

  const onLanguageChange = (e) => {
    const newState = {...state, language: e.target.value}
    setState(newState)
    validateForm(newState)
  }

  const showAddFieldDialog = (section) => {
    console.log('showAddFieldDialog', section)
    setAddFieldDialog({show: true, section})
  }

  const onCloseAddFieldDialog = (result) => {
    console.log('onCloseAddFieldDialog', result)
    if (result && result.field) {
      const newState = {...state}
      newState[addFieldDialog.section].push(result.field)
      setState(newState)
      validateForm(newState)
    }
    setAddFieldDialog({show: false, section: null})
  }

  const onQuestionsChange = (questions, section) => {
    const newState = {...state}
    newState[section] = questions
    setState(newState)
    validateForm(newState)
  }

  const onAssessmentsChange = (assessments) => {
    const newState = {...state}

    newState.assessment = assessments

    setState(newState)
    validateForm(newState)
  }

  const validateForm = (newState) => {
    if (!newState.name || newState.name.length < 2) {
      // console.log('SurveyForm.formIsValid.no.name', newState.name)
      return setValidForm(false)
    }

    console.log('validateForm', newState.intro)
    if (!newState.intro || newState.intro.length < 2) {
      return setValidForm(false)
    }

    const hasAssessmentsSelected = newState.assessment.filter(item => item.active).length > 0

    if (!hasAssessmentsSelected) {
      // console.log('SurveyForm.formIsValid.no.asseessments', newState.assessment)
      return setValidForm(false)
    }

    setValidForm(true)
  }

  const onCloseForm = () => {
    props.onDone()
  }

  const onCreate = () => {
    if (processingRequest) {
      return
    }
    console.log('onCreate', state)
    setProcessingRequest(true)
    Survey.create({...state, organizationId: props.organizationId})
      .then(() => {
        setProcessingRequest(false)
        props.onDone()
      })
      .catch(err => {
        setProcessingRequest(false)
      })
  }

  const onUpdate = () => {
    if (processingRequest) {
      return
    }
    console.log('onUpdate', state)
    setProcessingRequest(true)
    Survey.update({...state, organizationId: props.organizationId})
      .then(() => {
        if (props.onUpdate) {
          setProcessingRequest(false)
          props.onUpdate()
        }
      })
      .catch(err => {
        setProcessingRequest(false)
      })
  }

  const onCloseWelcomeEmailDialog = (res) => {
    if (res && res.success) {
      setState({...state, welcomeEmailBody: res.emailBody, welcomeSmsBody: res.smsBody})
    }

    setWelcomeEmailDialog({show: false})
  }

  const onCloseReminderEmailDialog = (res) => {
    if (res && res.success) {
      setState({...state, reminderEmailBody: res.emailBody, reminderSmsBody: res.smsBody})
    }

    setReminderEmailDialog({show: false})
  }

  // ISO 639-1 Code
  const supportedLangs = Language.get()

  if (loading) {
    return null
  }

  return (
    <form>
      { processingRequest ? ( <LoadingModal /> ) : null }
      {addFieldDialog.show ? <AddField open={addFieldDialog.show} section={addFieldDialog.section} onClose={onCloseAddFieldDialog} /> : null}
      {welcomeEmailDialog.show ? <MakeSurveyWelcomeEmail open={welcomeEmailDialog.show} emailBody={state.welcomeEmailBody} smsBody={state.welcomeSmsBody} onClose={onCloseWelcomeEmailDialog} /> : null}
      {reminderEmailDialog.show ? <MakeReminderEmail open={reminderEmailDialog.show} emailBody={state.reminderEmailBody} smsBody={state.reminderSmsBody} onClose={onCloseReminderEmailDialog} /> : null}
      <TextField
        autoFocus
        name='name'
        margin='dense'
        label='Survey Name'
        type='text'
        value={state.name}
        disabled={!!state.id}
        onChange={onNameChange}
        helperText="Must be at least 2 characters long"
        required
      />

      <br />

      <FormControl margin='dense'>
        <InputLabel>Language</InputLabel>
        <Select
          name='language'
          value={state.language}
          onChange={onLanguageChange}
          disabled={state.id}
          required
        >
          {
            supportedLangs.map(lang => <MenuItem key={lang.code} value={lang.code}>{lang.label}</MenuItem>)
          }
        </Select>
        <FormHelperText>Select the language in which the participants must complete the Survey</FormHelperText>
      </FormControl>

      <br />

      <FormLabel required={true}>Introduction</FormLabel>

      <RichText body={state.intro} onChange={onIntroChange} required />

      <Typography variant="subtitle2" style={{color: 'rgba(0, 0, 0, 0.54)'}}>
        The introduction will be shown before the start of the Survey.
      </Typography>

      <br />

      <Button color="primary" onClick={() => setWelcomeEmailDialog({show: true})}>Customize Welcome Email</Button>

      <br />

      <Button color="primary" onClick={() => setReminderEmailDialog({show: true})}>Customize Reminder Email</Button>

      <br />

      <Typography>
        Demographics
        <IconButton aria-label="Add a question field" onClick={(e) => showAddFieldDialog('demographic')}>
          <AddIcon />
        </IconButton>
      </Typography>
      <Typography variant="subtitle2" style={{color: 'rgba(0, 0, 0, 0.54)'}}>
        The demographic section will be the first section that appears in your survey, add the criteria you would like to include.
      </Typography>

      <QuestionsList questions={state.demographic} onChange={(questions) => onQuestionsChange(questions, 'demographic')} />

      <br />
      <br />

      <Typography>Assessments</Typography>
      <Typography variant="subtitle2" style={{color: 'rgba(0, 0, 0, 0.54)'}}>
        Select the assessments you would like to include in your survey.
      </Typography>

      <AssessmentList
        disabled={state.id && state.published}
        assessments={state.assessment}
        onChange={onAssessmentsChange} 
      />

      <br />
      <br />

      <Typography>
        Survey Satisfaction
        <IconButton aria-label="Add a question field" onClick={(e) => showAddFieldDialog('satisfaction')}>
          <AddIcon />
        </IconButton>
      </Typography>
      <Typography variant="subtitle2" style={{color: 'rgba(0, 0, 0, 0.54)'}}>
        These will follow the assessment portion of the survey, add the criteria you would like to include.
      </Typography>

      <QuestionsList questions={state.satisfaction} onChange={(questions) => onQuestionsChange(questions, 'satisfaction')} />

      <div style={{marginTop: '2rem'}}>
        <Button onClick={onCloseForm} color="secondary" disabled={loading || processingRequest}>Cancel</Button>
        {state.id && <Button onClick={onUpdate} color="primary" disabled={!validForm || loading || processingRequest}>Update</Button>}
        {!state.id && <Button onClick={onCreate} color="primary" disabled={!validForm || loading || processingRequest}>Create</Button>}
      </div>
    </form>
  )
}