import Auth from './auth'
import firebase from './firebase'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API

let self = {}

self.get = async function (programId) {
  const snap = await firebase.database().ref(`/adhocMessages/${programId}`).once('value')

  const result = snap.val() || {}

  return Object.values(result)
}

self.send = async function (params) {
  // for now, message is always published immediately, no scheduling
  if (!params.publishDate) {
    params.publishDate = (new Date()).getTime()
  }
  
  console.log('AdhocMessenger.send', params)
  return new Promise((resolve, reject) => {
    Auth.getIdToken()
      .then(token => {
        axios.post(`${API_URL}/createAdhocMessage`, params, {headers: { Authorization: `Bearer ${token}`}})
          .then(res => resolve(res.data))
          .catch(e => reject(e.response.data))
      })
      .catch(reject)
  })
}

export default self